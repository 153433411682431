import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import isEqual from 'lodash/isEqual'
import { errorInjector } from 'modules/EventManagement/helpers'
import { IDLE } from 'constants.js'

const initialState = {
	loading: false,
	activeEvent: {},
	activeEventInitialData: {},
	eventsList: {},
	masters: {},
	eventNotification: {},
	buttonLoading: false,
	synopsisLoading: false,
	showSaveButton: false,
	ticketTypeDropDown: { master: {}, filtered: null, error: null },
	partialFailure: null,
	deletedDraftData: null,
	getReportsStatus: IDLE,
	eventLevelReports: {},
	sessionLevelReports: {},
	fileDownloadStatus: IDLE,
	emailSalesDataStatus: IDLE,
	multiSessionFLag: { multisession: true },
}

export default new Duck({
	namespace: 'manager',
	store: 'eventManager',
	types: [
		'SET_EVENT_DETAIL',
		'GET_EVENTS_LISTING',
		'GET_EVENTS_LISTING_LOADING',
		'GET_EVENTS_SUCCESS',
		'RESET_LOADING',
		'GET_EVENT_CATEGORIES',
		'SET_EVENT_CATEGORIES',
		'SET_EVENT_SUB_CATEGORIES_OPTIONS',
		'RESET_EVENT_SUB_CATEGORY',
		'GET_EVENT_GENRES',
		'SET_EVENT_GENRES',
		'RESET_EVENT_GENRES',
		'FILTER_RELEVANT_CATEGORIES',
		'SET_VENUES',
		'GET_SYNOPSIS',
		'GET_SYNOPSIS_SUCCESS',
		'UPDATE_SYNOPSIS',
		'GET_MASTERS_DATA',
		'GET_MASTERS_DATA_SUCCESS',
		'GET_APPROVED_TICKET_TYPES',
		'GET_APPROVED_TICKET_TYPES_SUCCESS',
		'EMPTY_FILTERED_TICKET_TYPES',
		'CREATE_NEW_TICKET_TYPE',
		'SET_SELECTED_VENUES',
		'CREATE_EVENT',
		'EVENT_CREATION_SUCCESS',
		'UPDATE_EVENT_DATA',
		'UPDATE_PUBLISHED_SESSIONS',
		'PUBLISH_INVENTORY_CHANGE',
		'UPDATE_EVENT_STATUS',
		'SET_EVENT_NOTIFICATION',
		'SET_CURRENT_VENUE',
		'UPDATE_SESSION_INFO',
		'GET_BANNER_IMAGE_URL',
		'UPDATE_EVENT_IMAGES',
		'UPDATE_RECURRING_INFO',
		'UPDATE_ALL_EVENT_SECTIONS',
		'TOGGLE_LOADER',
		'TOGGLE_BUTTON_LOADING',
		'UPDATE_REJECTION_REASONS',
		'UPDATE_SHOW_SAVE_BUTTON',
		'UPDATE_CREATE_TICKET_TYPE_ERROR',
		'SET_PARTIAL_FAILURE',
		'CLEAR_PARTIAL_FAILURE',
		'DELETE_DRAFT_SUCCESS',
		'DELETE_DRAFT',
		'UPDATE_DELETE_DRAFT_DATA',
		'GET_EVENT_LEVEL_REPORTS',
		'GET_EVENT_LEVEL_REPORTS_SUCCESS',
		'RESET_EVENT_LEVEL_REPORTS',
		'GET_SESSION_LEVEL_REPORTS',
		'GET_SESSION_LEVEL_REPORTS_SUCCESS',
		'RESET_SESSION_LEVEL_REPORTS',
		'UPDATE_GET_REPORTS_STATUS',
		'GET_BOOKING_ID',
		'GET_BOOKING_ID_SUCCESS',
		'DOWNLOAD_SALES_DATA',
		'UPDATE_SALES_DATA_DOWNLOAD_STATUS',
		'SEND_SALES_DATA_EMAIL',
		'UPDATE_EMAIL_SALES_DATA_STATUS',
		'GET_MULTI_SESSION_FLAG',
		'GET_MULTI_SESSION_FLAG_SUCCESS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_EVENT_DETAIL: {
				const { detailType, value } = action

				return setIn(
					state,
					['activeEvent', 'eventInfo', detailType],
					value
				)
			}
			case duck.types.GET_EVENT_CATEGORIES: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.GET_EVENTS_LISTING_LOADING: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.GET_EVENTS_SUCCESS: {
				return { ...state, loading: false, eventsList: action.data }
			}
			case duck.types.RESET_LOADING: {
				return setIn(state, ['loading'], false)
			}
			case duck.types.SET_EVENT_CATEGORIES: {
				const { list } = action.payload
				return setIn(
					setIn(state, ['loading'], false),
					['masters', 'categories'],
					list
				)
			}
			case duck.types.SET_EVENT_SUB_CATEGORIES_OPTIONS: {
				return setIn(
					state,
					['masters', 'subCategoriesOptions'],
					action.payload
				)
			}
			case duck.types.RESET_EVENT_SUB_CATEGORY: {
				return setIn(
					setIn(state, ['masters', 'subCategoriesOptions'], []),
					['activeEvent', 'eventInfo', 'subCategory'],
					undefined
				)
			}
			case duck.types.SET_EVENT_GENRES: {
				const { list } = action.payload
				return setIn(state, ['masters', 'genreOptions'], list)
			}
			case duck.types.RESET_EVENT_GENRES: {
				return setIn(
					setIn(state, ['masters', 'genreOptions'], []),
					['activeEvent', 'eventInfo', 'genre'],
					undefined
				)
			}
			case duck.types.FILTER_RELEVANT_CATEGORIES: {
				const { category } = action
				const { categories = [] } = state.masters
				const {
					parent: { code: parentCode },
				} = categories.find(({ name }) => name === category)

				const updatedCategories = categories.filter(
					({ parent }) => parent.code === parentCode
				)
				return setIn(
					state,
					['masters', 'categories'],
					updatedCategories
				)
			}
			case duck.types.SET_VENUES: {
				const { payload } = action
				return setIn(
					setIn(state, ['loading'], false),
					['masters', 'venues'],
					payload
				)
			}
			case duck.types.GET_SYNOPSIS: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.GET_SYNOPSIS_SUCCESS: {
				const { data } = action
				const { rejectionReasons = [] } = data
				let parsedRejectedReasons = []
				let updatedActiveEventData = data

				if (rejectionReasons && rejectionReasons.length > 0) {
					parsedRejectedReasons = errorInjector(
						rejectionReasons,
						data
					)
				}

				updatedActiveEventData = setIn(
					updatedActiveEventData,
					['formattedRejectionReasons'],
					parsedRejectedReasons
				)
				return {
					...state,
					loading: false,
					activeEvent: updatedActiveEventData,
					activeEventInitialData: updatedActiveEventData,
				}
			}
			case duck.types.UPDATE_SYNOPSIS: {
				const { key, data } = action
				let tempState = { ...state }
				tempState = setIn(tempState, ['activeEvent'].concat(key), data)

				const isEventEqual = isEqual(
					getIn(tempState, ['activeEvent']),
					getIn(tempState, ['activeEventInitialData'])
				)

				tempState.showSaveButton = !isEventEqual

				return tempState
			}
			case duck.types.GET_MASTERS_DATA: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.GET_MASTERS_DATA_SUCCESS: {
				return {
					...state,
					loading: false,
					masters: { ...state.masters, [action.key]: action.data },
				}
			}
			case duck.types.GET_APPROVED_TICKET_TYPES: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.GET_APPROVED_TICKET_TYPES_SUCCESS: {
				let data = {}
				if (action.search) {
					data = { filtered: action.data }
				} else {
					data = { master: action.data, filtered: null }
				}

				return {
					...state,
					loading: false,
					ticketTypeDropDown: {
						...state.ticketTypeDropDown,
						...data,
					},
				}
			}
			case duck.types.EMPTY_FILTERED_TICKET_TYPES: {
				return {
					...state,
					loading: false,
					ticketTypeDropDown: {
						...state.ticketTypeDropDown,
						filtered: null,
					},
				}
			}
			case duck.types.SET_SELECTED_VENUES: {
				const { venues } = action
				const { region } = venues?.[0] || ''
				return setIn(
					setIn(state, ['activeEvent', 'city'], region),
					['activeEvent', 'venueInfo'],
					venues
				)
			}
			case duck.types.EVENT_CREATION_SUCCESS: {
				const { data } = action
				const { draftId, status } = data
				return setIn(
					setIn(state, ['activeEvent', 'status'], status),
					['activeEvent', 'draftId'],
					draftId
				)
			}
			case duck.types.SET_EVENT_NOTIFICATION: {
				const { params } = action
				const { eventNotification } = state
				if (params.isNewEvent) {
					return setIn(state, ['eventNotification'], action.params)
				} else {
					if (
						!params.type === 'createEvent' ||
						!eventNotification.type === 'createEvent'
					) {
						return setIn(
							state,
							['eventNotification'],
							action.params
						)
					}
				}
				return state
			}
			case duck.types.SET_CURRENT_VENUE: {
				const { venue } = action
				return setIn(state, ['activeEvent', 'currentVenue'], venue)
			}
			case duck.types.UPDATE_SESSION_INFO: {
				const { venue, selectedVenues } = action
				let tempState = { ...state }
				tempState = setIn(
					setIn(tempState, ['activeEvent', 'currentVenue'], venue[0]),
					['activeEvent', 'venueInfo'],
					selectedVenues
				)

				const isEventEqual = isEqual(
					getIn(tempState, ['activeEvent']),
					getIn(tempState, ['activeEventInitialData'])
				)

				tempState.showSaveButton = !isEventEqual

				return tempState
			}
			case duck.types.UPDATE_RECURRING_INFO:
				return {
					...state,
					activeEvent: {
						...state?.activeEvent,
						recurringInfo: action?.payload?.data,
					},
				}
			case duck.types.TOGGLE_LOADER:
				return {
					...state,
					synopsisLoading: !state?.synopsisLoading,
				}
			case duck.types.TOGGLE_BUTTON_LOADING:
				return {
					...state,
					buttonLoading: action?.payload?.buttonStatus || false,
				}
			case duck.types.UPDATE_REJECTION_REASONS:
				return {
					...state,
					activeEvent: {
						...state.activeEvent,
						rejectionReasons: action?.payload?.data,
						formattedRejectionReasons: errorInjector(
							action?.payload?.data,
							state?.activeEvent
						),
					},
				}
			case duck.types.UPDATE_SHOW_SAVE_BUTTON:
				return {
					...state,
					showSaveButton: action.payload,
				}
			case duck.types.UPDATE_CREATE_TICKET_TYPE_ERROR:
				return {
					...state,
					ticketTypeDropDown: {
						...state.ticketTypeDropDown,
						error: action.payload,
					},
				}
			case duck.types.SET_PARTIAL_FAILURE:
				return {
					...state,
					partialFailure: action.payload,
				}
			case duck.types.CLEAR_PARTIAL_FAILURE:
				return {
					...state,
					partialFailure: null,
				}
			case duck.types.DELETE_DRAFT:
				return {
					...state,
					deletedDraftData: null,
				}

			case duck.types.UPDATE_DELETE_DRAFT_DATA: {
				const { payload } = action

				return setIn(state, ['deletedDraftData'], payload)
			}
			case duck.types.GET_EVENT_LEVEL_REPORTS_SUCCESS: {
				return {
					...state,
					eventLevelReports: action.data,
				}
			}
			case duck.types.RESET_EVENT_LEVEL_REPORTS: {
				return {
					...state,
					getReportsStatus: IDLE,
					eventLevelReports: {},
				}
			}
			case duck.types.GET_SESSION_LEVEL_REPORTS_SUCCESS: {
				return {
					...state,
					sessionLevelReports: action.data,
				}
			}
			case duck.types.RESET_SESSION_LEVEL_REPORTS: {
				return {
					...state,
					getReportsStatus: IDLE,
					sessionLevelReports: action.data,
				}
			}
			case duck.types.UPDATE_GET_REPORTS_STATUS: {
				return {
					...state,
					getReportsStatus: action.data,
				}
			}
			case duck.types.GET_BOOKING_ID_SUCCESS: {
				return {
					...state,
					sessionLevelReports: {
						...state.sessionLevelReports,
						bookingSummary: action.data.bookingSummary,
					},
				}
			}
			case duck.types.UPDATE_SALES_DATA_DOWNLOAD_STATUS: {
				return {
					...state,
					fileDownloadStatus: action.data,
				}
			}
			case duck.types.UPDATE_EMAIL_SALES_DATA_STATUS: {
				return {
					...state,
					emailSalesDataStatus: action.data,
				}
			}
			case duck.types.GET_MULTI_SESSION_FLAG_SUCCESS: {
				return {
					...state,
					multiSessionFLag: action.data,
				}
			}
			default:
				return state
		}
	},
	selectors: {
		location: (state) => state.location,
		activeEvent: (state) => state.eventManager.activeEvent || {},
		recurringInfo: (state) =>
			state?.eventManager?.activeEvent?.recurringInfo || [],
		synopsisLoading: (state) =>
			state?.eventManager?.synopsisLoading || false,
		buttonLoading: (state) => state?.eventManager?.buttonLoading || false,
		showSaveButton: (state) => state?.eventManager?.showSaveButton || false,
		eventsList: (state) => state?.eventManager?.eventsList || {},
		emailIds: (state) => state?.eventManager?.emailIds || ['', '', ''],
		tncRefreshedOn: (state) => state?.eventManager?.tncRefreshedOn || '',
		partialFailure: (state) => state?.eventManager?.partialFailure || {},
		deletedDraftData: (state) =>
			state?.eventManager?.deletedDraftData || {},
		eventLevelReports: (state) =>
			state?.eventManager?.eventLevelReports || {},
		sessionLevelReports: (state) =>
			state?.eventManager?.sessionLevelReports || {},
		getReportsStatus: (state) =>
			state?.eventManager?.getReportsStatus || IDLE,
		fileDownloadStatus: (state) => state.fileDownloadStatus,
		emailSalesDataStatus: (state) => state.emailSalesDataStatus,
		multiSessionFLag: (state) => state.multiSessionFLag,
	},

	creators: (duck) => ({
		getEventsList: (params, onPaginationChange) => ({
			type: duck.types.GET_EVENTS_LISTING,
			params,
			onPaginationChange,
		}),
		getEventsListProgress: () => ({
			type: duck.types.GET_EVENTS_LISTING_LOADING,
		}),
		getEventsListSuccess: (data) => ({
			type: duck.types.GET_EVENTS_SUCCESS,
			data,
		}),
		setEventDetail: (detailType, value) => ({
			type: duck.types.SET_EVENT_DETAIL,
			detailType,
			value,
		}),
		getEventCategories: (searchParam) => ({
			type: duck.types.GET_EVENT_CATEGORIES,
			searchParam,
		}),
		setEventCategories: (payload) => ({
			type: duck.types.SET_EVENT_CATEGORIES,
			payload,
		}),
		setEventSubCategoryOptions: (payload) => ({
			type: duck.types.SET_EVENT_SUB_CATEGORIES_OPTIONS,
			payload,
		}),
		getEventGenres: (eventType, category, subCategories) => ({
			type: duck.types.GET_EVENT_GENRES,
			eventType,
			category,
			subCategories,
		}),
		setEventGenres: (payload) => ({
			type: duck.types.SET_EVENT_GENRES,
			payload,
		}),
		resetEventGenres: () => ({
			type: duck.types.RESET_EVENT_GENRES,
		}),
		resetEventSubCategory: () => ({
			type: duck.types.RESET_EVENT_SUB_CATEGORY,
		}),
		filterRelevantCategories: (category) => ({
			type: duck.types.FILTER_RELEVANT_CATEGORIES,
			category,
		}),
		getEventSynopsis: (params, showSaveButton) => ({
			type: duck.types.GET_SYNOPSIS,
			params,
			showSaveButton,
		}),
		getSynopsisSuccess: (data) => ({
			type: duck.types.GET_SYNOPSIS_SUCCESS,
			data,
		}),
		updateSynopsis: (key, data) => ({
			type: duck.types.UPDATE_SYNOPSIS,
			key,
			data,
		}),
		getMastersData: (query) => ({
			type: duck.types.GET_MASTERS_DATA,
			query,
		}),
		getMastersDataSuccess: (key, data) => ({
			type: duck.types.GET_MASTERS_DATA_SUCCESS,
			key,
			data,
		}),
		getApprovedTicketTypes: (search) => ({
			type: duck.types.GET_APPROVED_TICKET_TYPES,
			search,
		}),
		getApprovedTicketTypesSuccess: (search, data) => ({
			type: duck.types.GET_APPROVED_TICKET_TYPES_SUCCESS,
			search,
			data,
		}),
		emptyFilteredTicketTypes: () => ({
			type: duck.types.EMPTY_FILTERED_TICKET_TYPES,
		}),
		createNewTicketType: (ticketTypeName) => ({
			type: duck.types.CREATE_NEW_TICKET_TYPE,
			ticketTypeName,
		}),
		resetLoader: () => ({
			type: duck.types.RESET_LOADING,
		}),
		setVenues: (payload) => ({
			type: duck.types.SET_VENUES,
			payload,
		}),
		setSelectedVenues: (venues) => ({
			type: duck.types.SET_SELECTED_VENUES,
			venues,
		}),
		createEvent: (eventInfo) => ({
			type: duck.types.CREATE_EVENT,
			eventInfo,
		}),
		eventCreationSuccess: (data) => ({
			type: duck.types.EVENT_CREATION_SUCCESS,
			data,
		}),
		updateEventData: (
			draftId,
			eventCode,
			section,
			sectionInfo,
			redirectTo
		) => ({
			type: duck.types.UPDATE_EVENT_DATA,
			draftId,
			eventCode,
			section,
			sectionInfo,
			redirectTo,
		}),
		updatePublishedSessions: (
			draftId,
			eventCode,
			section,
			sectionInfo
		) => ({
			type: duck.types.UPDATE_PUBLISHED_SESSIONS,
			draftId,
			eventCode,
			section,
			sectionInfo,
		}),
		publishInventoryChange: (
			etCode,
			vnuCode,
			sessions,
			ticketTypeName,
			totalInventory,
			callType
		) => ({
			type: duck.types.PUBLISH_INVENTORY_CHANGE,
			etCode,
			vnuCode,
			sessions,
			ticketTypeName,
			totalInventory,
			callType,
		}),
		updateEventStatus: (eventId, status) => ({
			type: duck.types.UPDATE_EVENT_STATUS,
			eventId,
			status,
		}),
		setEventNotification: (
			show = false,
			isNewEvent,
			title,
			content,
			type
		) => ({
			type: duck.types.SET_EVENT_NOTIFICATION,
			params: {
				show,
				isNewEvent,
				title,
				content,
				type,
			},
		}),
		setCurrentVenue: (venue) => ({
			type: duck.types.SET_CURRENT_VENUE,
			venue,
		}),
		updateSessionInfo: (venue, selectedVenues) => ({
			type: duck.types.UPDATE_SESSION_INFO,
			venue,
			selectedVenues,
		}),
		getBannerImageUrl: (bannerType, image) => ({
			type: duck.types.GET_BANNER_IMAGE_URL,
			bannerType,
			image,
		}),
		updateEventImages: () => ({
			type: duck.types.UPDATE_EVENT_IMAGES,
		}),
		updateRecurringInfo: (data) => ({
			type: duck.types.UPDATE_RECURRING_INFO,
			payload: {
				data,
			},
		}),
		updateAllEventSections: (slug) => ({
			type: duck.types.UPDATE_ALL_EVENT_SECTIONS,
			slug,
		}),
		toggleLoader: () => ({
			type: duck.types.TOGGLE_LOADER,
		}),
		toggleButtonLoading: (buttonStatus) => ({
			type: duck.types.TOGGLE_BUTTON_LOADING,
			payload: {
				buttonStatus,
			},
		}),
		updateRejectionReasons: (data) => ({
			type: duck.types.UPDATE_REJECTION_REASONS,
			payload: {
				data,
			},
		}),
		updateShowSaveButton: (payload) => ({
			type: duck.types.UPDATE_SHOW_SAVE_BUTTON,
			payload,
		}),
		updateCreateTicketTypeError: (payload) => ({
			type: duck.types.UPDATE_CREATE_TICKET_TYPE_ERROR,
			payload,
		}),
		setPartialFailure: (payload) => ({
			type: duck.types.SET_PARTIAL_FAILURE,
			payload,
		}),
		clearPartialFailure: () => ({
			type: duck.types.CLEAR_PARTIAL_FAILURE,
		}),
		removeDraftEvent: (payload, getEventsParams) => ({
			type: duck.types.DELETE_DRAFT_SUCCESS,
			payload,
			getEventsParams,
		}),
		deleteDraft: (payload) => ({
			type: duck.types.DELETE_DRAFT,
			payload,
		}),
		updateDeleteDraftData: (payload) => ({
			type: duck.types.UPDATE_DELETE_DRAFT_DATA,
			payload,
		}),
		getEventLevelReports: (params) => ({
			type: duck.types.GET_EVENT_LEVEL_REPORTS,
			params,
		}),
		getEventLevelReportsSuccess: (data) => ({
			type: duck.types.GET_EVENT_LEVEL_REPORTS_SUCCESS,
			data,
		}),
		resetEventLevelReports: (params) => ({
			type: duck.types.RESET_EVENT_LEVEL_REPORTS,
			params,
		}),
		getSessionLevelReports: (params) => ({
			type: duck.types.GET_SESSION_LEVEL_REPORTS,
			params,
		}),
		getSessionLevelReportsSuccess: (data) => ({
			type: duck.types.GET_SESSION_LEVEL_REPORTS_SUCCESS,
			data,
		}),
		resetSessionLevelReports: (params) => ({
			type: duck.types.RESET_SESSION_LEVEL_REPORTS,
			params,
		}),
		updateGetReportsStatus: (data) => ({
			type: duck.types.UPDATE_GET_REPORTS_STATUS,
			data,
		}),
		getBookingId: (params) => ({
			type: duck.types.GET_BOOKING_ID,
			params,
		}),
		getBookingIdSuccess: (data) => ({
			type: duck.types.GET_BOOKING_ID_SUCCESS,
			data,
		}),
		downloadSalesData: (data) => ({
			type: duck.types.DOWNLOAD_SALES_DATA,
			data,
		}),
		updateSalesDataDownloadStatus: (data) => ({
			type: duck.types.UPDATE_SALES_DATA_DOWNLOAD_STATUS,
			data,
		}),
		sendSalesDataEmail: (data) => ({
			type: duck.types.SEND_SALES_DATA_EMAIL,
			data,
		}),
		updateEmailSalesDataStatus: (data) => ({
			type: duck.types.UPDATE_EMAIL_SALES_DATA_STATUS,
			data,
		}),
		getMultiSessionFlag: (data) => ({
			type: duck.types.GET_MULTI_SESSION_FLAG,
			data,
		}),
		getMultiSessionFlagSuccess: (data) => ({
			type: duck.types.GET_MULTI_SESSION_FLAG_SUCCESS,
			data,
		}),
	}),
})
