import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const initialState = {
	loading: false,
	events: [],
	sessions: [],
	selectedEvent: null,
	selectedSession: null,
	headerTitle: null,
	scanDetails: null,
	bookingId: null,
	isBookingIdValid: false,
	showModal: false,
	modalData: {},
	tickets: [],
	selectedTickets: [],
	scanType: null,
	paginationParam: { start: 0, limit: 25 },
	totalEvents: 0,
	geoLocationData: { latitude: 'na', longitude: 'na', accuracy: 'na' },
	scanCategory: 'Entry',
	eventSearch: '',
	isNextSetOfEventsAvailable: true,
}

export default new Duck({
	namespace: 'my-app',
	store: 'ScanningApp',
	types: [
		'GET_ALL_EVENTS',
		'GET_ALL_EVENTS_SUCCESS',
		'GET_ALL_EVENTS_SEARCH_SUCCESS',
		'GET_ALL_SESSIONS',
		'GET_ALL_SESSIONS_SUCCESS',
		'SET_HEADER_TITLE',
		'SELECT_EVENT',
		'SELECT_SESSION',
		'TOGGLE_CTA',
		'FLUSH_EVENTS_DATA',
		'FLUSH_SESSIONS_DATA',
		'SET_SCAN_TYPE',
		'CONSUME',
		'TOGGLE_MODAL',
		'GET_TICKETS',
		'SET_BOOKING_ID',
		'GET_TICKETS_SUCCESS',
		'GET_TICKETS_ERROR',
		'SELECT_TICKETS',
		'SET_GEOLOCATION_DATA',
		'REMOVE_TICKETS',
		'SET_SCAN_CATEGORY',
		'REDIRECT',
		'HANDLE_ERROR',
		'FLUSH_MODAL_DATA',
		'UPDATE_EVENT_LIST_SEARCH',
	],
	initialState,

	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.GET_ALL_EVENTS: {
				return {
					...state,
					loading: state.paginationParam.start < 0,
				}
			}
			case duck.types.GET_ALL_EVENTS_SEARCH_SUCCESS:
				return {
					...state,
					loading: false,
					isNextSetOfEventsAvailable: action.eventList.length === 25,
					paginationParam: {
						start: 0,
						limit: 25,
					},
					events: action.eventList,
					totalEvents: action.totalEventsUserHave,
				}
			case duck.types.UPDATE_EVENT_LIST_SEARCH:
				return {
					...state,
					eventSearch: action.value,
				}
			case duck.types.GET_ALL_EVENTS_SUCCESS: {
				return {
					...state,
					loading: false,
					isNextSetOfEventsAvailable: action.eventList.length === 25,
					events: [...state.events, ...action.eventList],
					totalEvents: action.totalEventsUserHave,
					paginationParam: {
						...state.paginationParam,
						start: state?.paginationParam?.start + 25,
					},
				}
			}
			case duck.types.GET_ALL_SESSIONS: {
				return {
					...state,
					loading: true,
					paginationParam: { start: -25, limit: 0 },
				}
			}
			case duck.types.GET_ALL_SESSIONS_SUCCESS: {
				return {
					...state,
					loading: false,
					sessions: action.sessionList,
				}
			}
			case duck.types.SELECT_EVENT: {
				return {
					...state,
					selectedEvent: action.event,
				}
			}
			case duck.types.SELECT_SESSION: {
				return {
					...state,
					selectedSession: action.session,
				}
			}
			case duck.types.TOGGLE_CTA: {
				return {
					...state,
					isBookingIdValid: action.flag,
				}
			}
			case duck.types.SET_HEADER_TITLE: {
				return {
					...state,
					headerTitle: action.title,
				}
			}
			case duck.types.FLUSH_EVENTS_DATA: {
				return {
					...state,
					events: [],
					selectedEvent: null,
					paginationParam: { start: 0, limit: 25 },
				}
			}
			case duck.types.FLUSH_SESSIONS_DATA: {
				return {
					...state,
					sessions: [],
					selectedSession: null,
				}
			}
			case duck.types.SET_SCAN_TYPE: {
				return { ...state, scanType: action.consumedWith }
			}
			case duck.types.CONSUME: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.SET_GEOLOCATION_DATA: {
				return { ...state, geoLocationData: action.geoLocationData }
			}
			case duck.types.TOGGLE_MODAL: {
				let { showModal, modalData } = action

				if (showModal === undefined) {
					showModal = !state.showModal
				}
				return {
					...state,
					showModal,
					selectedTickets: [],
					modalData: modalData,
					loading: false,
				}
			}
			case duck.types.SET_BOOKING_ID: {
				return { ...state, bookingId: action.bookingId }
			}
			case duck.types.GET_TICKETS: {
				return { ...state, loading: true }
			}
			case duck.types.GET_TICKETS_SUCCESS: {
				return {
					...state,
					tickets: action.tickets,
					loading: false,
					selectedTickets: [],
				}
			}
			case duck.types.GET_TICKETS_ERROR: {
				return { ...state, loading: false }
			}
			case duck.types.SELECT_TICKETS: {
				return {
					...state,
					selectedTickets: [
						...state.selectedTickets,
						...action.tickets,
					],
				}
			}
			case duck.types.REMOVE_TICKETS: {
				const filteredTickets = state.selectedTickets.filter(
					tickets => !action.tickets.includes(tickets)
				)
				return {
					...state,
					selectedTickets: filteredTickets,
				}
			}

			case duck.types.FLUSH_MODAL_DATA: {
				return { ...state, modalData: {} }
			}

			case duck.types.SET_SCAN_CATEGORY: {
				return { ...state, scanCategory: action.category }
			}

			default:
				return state
		}
	},
	selectors: {
		paramsForPagination: ({ ScanningApp }) => ScanningApp.paginationParam,
		selectedEvent: ({ ScanningApp }) =>
			ScanningApp.selectedEvent || { eventCode: null },
		scanType: ({ ScanningApp }) => ScanningApp.scanType,
		selectedTickets: ({ ScanningApp }) => ScanningApp.selectedTickets,
		sessionDetails: ({ ScanningApp }) => ScanningApp.selectedSession || {},
		bookingId: ({ ScanningApp }) => ScanningApp.bookingId,
		geoLocationData: ({ ScanningApp }) => ScanningApp.geoLocationData,
		searchValue: ({ ScanningApp }) => ScanningApp.eventSearch,
		exitScan: ({ ScanningApp }) =>
			ScanningApp.scanCategory === 'Entry' ? false : true,
		payload: new Duck.Selector(selectors =>
			createSelector(
				selectors.selectedEvent,
				selectors.scanType,
				selectors.selectedTickets,
				selectors.sessionDetails,
				selectors.exitScan,

				(
					{ eventCode },
					scanType,
					barcodes,
					{ venueCode, sessionId },
					exitScan
				) => ({
					scanType,
					eventCode,
					venueCode,
					sessionId,
					barcodes,
					exitScan,
				})
			)
		),
		paramsForSearchingTickets: new Duck.Selector(selectors =>
			createSelector(
				selectors.selectedEvent,
				selectors.sessionDetails,
				selectors.bookingId,
				selectors.exitScan,
				(
					{ eventCode },
					{ venueCode, sessionId },
					bookingId,
					exitScan
				) => ({
					eventCode,
					venueCode,
					sessionId,
					bookingID: bookingId,
					exitScan,
				})
			)
		),
	},
	creators: duck => ({
		getAllEventsAction: (search, onPaginationChange) => ({
			type: duck.types.GET_ALL_EVENTS,
			search,
			onPaginationChange,
		}),
		getAllEventsSuccessAction: (eventList, totalEventsUserHave) => ({
			type: duck.types.GET_ALL_EVENTS_SUCCESS,
			eventList,
			totalEventsUserHave,
		}),
		getAllEventsSearchSuccess: (eventList, totalEventsUserHave) => ({
			type: duck.types.GET_ALL_EVENTS_SEARCH_SUCCESS,
			eventList,
			totalEventsUserHave,
		}),
		updateEventListSearch: value => ({
			type: duck.types.UPDATE_EVENT_LIST_SEARCH,
			value,
		}),
		getAllSessionsAction: params => ({
			type: duck.types.GET_ALL_SESSIONS,
			params,
		}),
		getAllSessionsSuccessAction: sessionList => ({
			type: duck.types.GET_ALL_SESSIONS_SUCCESS,
			sessionList,
		}),
		selectEventAction: event => ({
			type: duck.types.SELECT_EVENT,
			event,
		}),
		selectSessionAction: session => ({
			type: duck.types.SELECT_SESSION,
			session,
		}),
		toggleCTA: flag => ({
			type: duck.types.TOGGLE_CTA,
			flag,
		}),
		setHeaderTitleAction: title => ({
			type: duck.types.SET_HEADER_TITLE,
			title,
		}),
		flushEventsDataAction: () => ({
			type: duck.types.FLUSH_EVENTS_DATA,
		}),
		flushSessionsDataAction: () => ({
			type: duck.types.FLUSH_SESSIONS_DATA,
		}),
		consumeBarcodeAction: () => ({
			type: duck.types.CONSUME,
		}),
		setConsumeTypeAction: consumedWith => ({
			type: duck.types.SET_SCAN_TYPE,
			consumedWith,
		}),
		toggleModalStateAction: (modalData, showModal) => ({
			type: duck.types.TOGGLE_MODAL,
			modalData,
			showModal,
		}),
		setBookingIdAction: bookingId => ({
			type: duck.types.SET_BOOKING_ID,
			bookingId,
		}),
		getTicketsAction: () => ({ type: duck.types.GET_TICKETS }),
		getTicketsSuccessAction: tickets => ({
			type: duck.types.GET_TICKETS_SUCCESS,
			tickets,
		}),
		getTicketsErrAction: () => ({ type: duck.types.GET_TICKETS_ERROR }),
		selectTicketAction: tickets => ({
			type: duck.types.SELECT_TICKETS,
			tickets,
		}),
		setGeoLocationAction: geoLocationData => ({
			type: duck.types.SET_GEOLOCATION_DATA,
			geoLocationData,
		}),
		removeTicketsAction: tickets => ({
			type: duck.types.REMOVE_TICKETS,
			tickets,
		}),
		setScanCategoryAction: category => ({
			type: duck.types.SET_SCAN_CATEGORY,
			category,
		}),
		redirectToUrl: url => ({
			type: duck.types.REDIRECT,
			url,
		}),
		handleError: (err, url) => ({
			type: duck.types.HANDLE_ERROR,
			err,
			url,
		}),
		triggerAnalyticsForQrConsume: (payload, success) => ({
			type: duck.types.TRIGGER_ANALYTICS_QR_SUCCESS,
			payload,
			success,
		}),
		flushModalDataAction: () => ({
			type: duck.types.FLUSH_MODAL_DATA,
		}),
	}),
})
