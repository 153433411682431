import { createComponent } from 'react-fela'

const __mainWrapper = ({ theme, show, type, isDesktop, spaceBottom }) => ({
	display: show ? 'flex' : 'none',
	flexWrap: 'wrap',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	padding: isDesktop
		? `${theme.spaces[14]}  ${theme.spaces[16]}`
		: theme.spaces[14],
	marginBottom: spaceBottom ? theme.spaces[16] : 0,
	extend: [
		{
			condition: type === 'success',
			style: { backgroundColor: '#E8FCED' },
		},
		{
			condition: type === 'info',
			style: { backgroundColor: '#FFF9EB' },
		},
		{
			condition: type === 'error',
			style: { backgroundColor: '#FBEBEB' },
		},
		{
			condition: type === 'default',
			style: { backgroundColor: '#E5E5E5' },
		},
	],
})

const __iconWrapper = ({ theme, isDesktop }) => ({
	width: 15,
	height: 15,
	'> svg': { verticalAlign: 'top' },
	marginRight: isDesktop ? theme.spaces[8] : theme.spaces[12],
})

const __contentWrapper = () => ({
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'baseline',
	width: '100%',
})

const __detailWrapper = () => ({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	justifyContent: 'space-between',
})

const __title = ({ theme }) => ({
	fontSize: theme.font.size16,
	fontWeight: theme.font.weight500,
	padding: 2,
	letterSpacing: 0.2,
})

const __infoWrapper = ({ theme }) => ({
	fontSize: theme.font.size14,
	padding: 2,
	lineHeight: '18px',
})

const __leftContentWrapper = () => ({
	display: 'flex',
	marginRight: 'auto',
})

const __closeButton = () => ({
	fontWeight: 'bold',
	cursor: 'pointer',
})

const __childrenWrapper = ({ theme, isDesktop }) => ({
	marginTop: theme.spaces[12],
	marginLeft: theme.spaces[36],
	extend: [
		{
			condition: isDesktop,
			style: {
				maxWidth: 331,
				marginLeft: theme.spaces[32],
			},
		},
	],
	'> div': {
		margin: `${theme.spaces[8]} 0`,
	},
})

export const MainWrapper = createComponent(__mainWrapper)
export const IconWrapper = createComponent(__iconWrapper)
export const DetailWrapper = createComponent(__detailWrapper)
export const ContentWrapper = createComponent(__contentWrapper)
export const Title = createComponent(__title)
export const InfoWrapper = createComponent(__infoWrapper)
export const ChildrenWrapper = createComponent(__childrenWrapper)
export const LeftContentWrapper = createComponent(__leftContentWrapper)
export const CloseButton = createComponent(__closeButton, 'div', ['onClick'])
