import React from 'react'
import { array, func } from 'prop-types'
import { createComponent } from 'react-fela'
import Artists from 'bms-le-components/components/Artists'
import { __wrapper, __cardWrapper } from './__style'

const Wrapper = createComponent(__wrapper)
const CardWrapper = createComponent(__cardWrapper, 'div', ['onClick'])

const SelectedArtists = ({ artistList, onClick }) => {
	return (
		<Wrapper hasData={artistList.length}>
			{artistList.map(artist => {
				const { id, name, url, roles } = artist
				const artistType =
					roles[0] +
					(roles.length > 1 ? ` + ${roles.length - 1} Role` : '')

				return (
					<CardWrapper key={id} onClick={() => onClick(artist)}>
						<Artists
							isDesktop
							pullRight="12"
							imageWidth={36}
							marginBottom={12}
							artistName={name}
							artistImage={url}
							artistType={artistType}
							showEditIcon
						/>
					</CardWrapper>
				)
			})}
		</Wrapper>
	)
}

SelectedArtists.propTypes = {
	artistList: array.isRequired,
	onClick: func.isRequired,
}

SelectedArtists.defaultProps = {
	artistList: [],
	onClick: () => {},
}

export default SelectedArtists
