import React from 'react'
import { InputBar, TextSpace, ErrorText, Wrapper } from './__style'
import Icon from 'bms-le-components/common/ui/Icon'
import venueIcon from 'common/icons/venueIcon.svg'
import artistIcon from 'common/icons/ArtistIcon.svg'
import languageIcon from 'common/icons/LanguageIcon.svg'
import ageIcon from 'common/icons/AgeIcon.svg'
import calendarIcon from 'common/icons/Calender.svg'
import descriptionIcon from 'common/icons/description.svg'
import listIcon from 'common/icons/list.svg'
import Duration from 'common/icons/Duration.svg'

const iconMap = {
	venue: venueIcon,
	artist: artistIcon,
	age: ageIcon,
	language: languageIcon,
	calendar: calendarIcon,
	description: descriptionIcon,
	list: listIcon,
	duration: Duration,
}

const ActionBar = ({
	placeholder,
	type,
	attr,
	handleClick,
	errorText,
	isDesktop = false,
}) => {
	return (
		<Wrapper isDesktop={isDesktop}>
			<InputBar type="text" onClick={handleClick} data-attr={attr}>
				<TextSpace>{placeholder}</TextSpace>
				<div style={{ width: '15px', height: '18px' }}>
					<Icon width={100} glyph={iconMap[type]} />
				</div>
			</InputBar>
			{errorText && <ErrorText>{errorText}</ErrorText>}
		</Wrapper>
	)
}
export default ActionBar
