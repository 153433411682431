import Modal from 'common/components/Modal'
import Button from 'design-framework/atoms/Button'
import { formatDateTime } from 'modules/EventManagement/helpers'
import React from 'react'
import { createComponent } from 'react-fela'
import {
	__confirmNewShowsWrapper,
	__confirmNewShowsVenueName,
	__confirmNewShowsShowtimeWrapper,
	__confirmNewShowsShowtime,
	__confirmNewShowsButtonWrapper,
	__buttonWrapper,
} from './__style'

const ConfirmNewShowsWrapper = createComponent(__confirmNewShowsWrapper)
const ConfirmNewShowsVenueName = createComponent(__confirmNewShowsVenueName)
const ConfirmNewShowsShowtimeWrapper = createComponent(
	__confirmNewShowsShowtimeWrapper
)
const ConfirmNewShowsShowtime = createComponent(__confirmNewShowsShowtime, 'li')
const ConfirmNewShowsButtonWrapper = createComponent(
	__confirmNewShowsButtonWrapper
)
const ButtonWrapper = createComponent(__buttonWrapper)

const ConfirmNewShows = ({
	showModal,
	backToEdit,
	confirm,
	isDesktop,
	data = [],
}) => {
	let state
	let title
	let duplicates = []

	// check for duplicates
	data.forEach(venue => {
		const duplicateSessions = venue.sessionInfo.filter(
			({ errorMessage }) => !!errorMessage
		)

		if (duplicateSessions.length) {
			duplicates = [
				...duplicates,
				{ ...venue, sessionInfo: duplicateSessions },
			]
		}
	})

	if (!data.length) {
		state = 'NO_SHOWS'
		title = 'No new shows are added'
	} else if (duplicates.length) {
		state = 'DUPLICATE'
		title = 'Please remove duplicate shows'
	} else {
		state = 'CONFIRM'
		title = 'Confirm New Shows'
	}
	return (
		<Modal
			title={title}
			showModal={showModal}
			isDesktop={isDesktop}
			showDismissIcon={false}
			customStyle={{
				width: isDesktop ? '316px' : '90vw',
				maxHeight: '100vh',
				padding: '24px 0 16px',
			}}
		>
			<ConfirmNewShowsWrapper>
				{(state === 'DUPLICATE' ? duplicates : data).map(
					({ venueCode, venueName, sessionInfo }) => (
						<ConfirmNewShowsShowtimeWrapper key={venueCode}>
							<ConfirmNewShowsVenueName>
								{venueName}
							</ConfirmNewShowsVenueName>
							{sessionInfo && (
								<ul style={{ margin: 0 }}>
									{sessionInfo.map(
										({ sessionId, showTime }) => (
											<ConfirmNewShowsShowtime
												key={sessionId}
											>
												{formatDateTime(
													showTime,
													'do MMM yyyy'
												)}
												<span>
													{formatDateTime(
														showTime,
														', hh:mm a'
													)}
												</span>
											</ConfirmNewShowsShowtime>
										)
									)}
								</ul>
							)}
						</ConfirmNewShowsShowtimeWrapper>
					)
				)}
				<ButtonWrapper>
					<Button
						size="LARGE"
						type="SECONDARY"
						isFullWidth
						onClick={backToEdit}
					>
						Back To Edit
					</Button>

					{state === 'CONFIRM' && (
						<ConfirmNewShowsButtonWrapper>
							<Button
								size="LARGE"
								type="PRIMARY"
								isFullWidth
								onClick={confirm}
							>
								Confirm
							</Button>
						</ConfirmNewShowsButtonWrapper>
					)}
				</ButtonWrapper>
			</ConfirmNewShowsWrapper>
		</Modal>
	)
}

export default ConfirmNewShows
