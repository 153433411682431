import { createComponent } from 'react-fela'
import theme from 'bms-le-components/utils/base-ui-theme'

const __iconWrapper = () => ({ width: 20, height: 20 })

const __commonWrapper = ({ isToggle }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: 7.5,
	extend: [
		{
			condition: isToggle,
			style: {
				padding: '0px 7.5px',
				position: 'relative',
			},
		},
	],
})

const __toggleLabel = () => ({
	fontWeight: 500,
	fontSize: 10,
	lineHeight: '16px',
	margin: 7,
})

export const __toolTipTitleWrapper = () => ({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
})

export const __toolTipTitle = ({ theme }) => ({
	fontSize: theme.font.size14,
	fontWeight: theme.font.weight500,
	letterSpacing: 0.2,
})

export const __toolTipDescriptuion = ({ theme }) => ({
	fontSize: theme.font.size12,
	fontWeight: theme.font.weight400,
	marginTop: theme.spaces[10],
	color: theme.colors.lightBlue2,
	letterSpacing: 0.2,
})

export const getOverlayStyle = () => ({
	opacity: 1,
	zIndex: 1,
})

export const getOverlayInnerStyle = () => ({
	width: 304,
	background: theme.colors.bmsDarkBlue,
	padding: `${theme.spaces[10]} ${theme.spaces[20]}  ${theme.spaces[10]} ${theme.spaces[16]}`,
})

const IconWrapper = createComponent(__iconWrapper, 'div', ['onClick'])
const CommonWrapper = createComponent(__commonWrapper)
const ToggleLabel = createComponent(__toggleLabel)
const ToolTipTitleWrapper = createComponent(__toolTipTitleWrapper, 'div', [
	'onClick',
])
const ToolTipTitle = createComponent(__toolTipTitle)
const ToolTipDescriptuion = createComponent(__toolTipDescriptuion)

export {
	CommonWrapper,
	IconWrapper,
	ToggleLabel,
	ToolTipTitleWrapper,
	ToolTipTitle,
	ToolTipDescriptuion,
}
