import React, { memo } from 'react'
import Modal from '@material-ui/core/Modal'
import { bool, object, func, string, oneOfType, array } from 'prop-types'
import { ModalHeader, ModalTitle, CloseBtn } from './__style'

const SimpleModal = ({
	showModal,
	toggleModal,
	title,
	children,
	customStyle,
	isDesktop,
	showDismissIcon = true,
	centerAlignedTitle = false,
	headerStyle = {},
	titleStyles,
	...props
}) => {
	// getModalStyle is not a pure function, we roll the style only on the first render
	const modalStyle = {
		top: `${isDesktop ? 51 : 50}%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		maxHeight: isDesktop ? '95vh' : '100vh',
		overflow: 'scroll',
		backgroundColor: '#ffffff',
		padding: 25,
		borderRadius: '4px',
		outline: 'none',
		scrollbarWidth: 'none',
		...customStyle,
	}

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={showModal}
			onClose={toggleModal}
			style={{ zIndex: 999 }}
			{...props}
		>
			<div style={modalStyle}>
				{(title || showDismissIcon) && (
					<ModalHeader style={headerStyle}>
						<ModalTitle
							centerAligned={
								!showDismissIcon || centerAlignedTitle
							}
							style={titleStyles}
						>
							{title}
						</ModalTitle>
						{showDismissIcon && (
							<CloseBtn onClick={toggleModal}>&#10005;</CloseBtn>
						)}
					</ModalHeader>
				)}
				{children}
			</div>
		</Modal>
	)
}

SimpleModal.propTypes = {
	showModal: bool.isRequired,
	toggleModal: func.isRequired,
	title: string,
	children: oneOfType([object.isRequired, array.isRequired]),
	customStyle: object,
	isDesktop: bool.isRequired,
	showDismissIcon: bool,
	headerStyle: object,
	titleStyles: object,
	centerAlignedTitle: bool.isRequired,
}

SimpleModal.defaultProps = {
	showModal: false,
	toggleModal: () => {},
	children: null,
	customStyle: null,
	isDesktop: true,
	showDismissIcon: true,
	headerStyle: {},
	titleStyles: {},
	centerAlignedTitle: false,
}

export default memo(SimpleModal)
