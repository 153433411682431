import Duck from 'extensible-duck'
import { getIn, setIn } from 'timm'

export default new Duck({
	namespace: 'my-app',
	store: 'offers',
	types: [
		'RESET',
		'GET_AUDIENCE',
		'GET_AUDIENCE_SUCCESS',
		'GET_AUDIENCE_ERROR',
		'GET_EVENTS_LIST',
		'GET_EVENTS_LIST_SUCCESS',
		'GET_EVENTS_LIST_ERROR',
		'GET_OFFERS',
		'GET_OFFERS_SUCCESS',
		'GET_OFFERS_ERROR',
		'SET_SELECTED_EVENT',
		'SET_SELECTED_AUDIENCE',
		'SET_SELECTED_OFFER',
		'SHOW_ACTIVE_STEP',
		'CREATE_OFFER',
		'GET_EVENT_OFFER_DETAIL',
		'SHOW_OFFER_DETAIL',
		'DEACTIVATE_OFFER',
		'CREATE_OFFER_SUCCESS',
		'DEACTIVATE_OFFER_SUCCESS',
		'DEACTIVATE_OFFER_ERROR',
		'SHOW_OFFER_DETAIL_ERROR',
		'SEND_TRANSACTION_REPORTS',
		'TRANSACTION_REPORTS_SENT',
		'RESET_LOGIN_FLOW_REDIRECT_FLAG',
	],
	initialState: {
		loading: false,
		loginFlowRedirectFlag: false,
		activeComponent: 'pick-an-event',
		prevComponent: '',
		availableFlows: ['pick-an-event', 'select-users', 'choose-offer'],
		components: {},
		selection: {
			event: '',
			audience: '',
			offer: '',
		},
		fetchingReports: false,
	},
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SHOW_ACTIVE_STEP: {
				const { step } = action
				return setIn(state, ['activeComponent'], step)
			}
			case duck.types.RESET: {
				return {
					...state,
					activeComponent: 'pick-an-event',
					prevComponent: '',
					components: {},
					selection: {},
				}
			}
			case duck.types.GET_AUDIENCE: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.GET_AUDIENCE_SUCCESS: {
				return {
					...state,
					loading: false,
					components: {
						...state.components,
						audienceDetails: action.data,
					},
				}
			}
			case duck.types.GET_AUDIENCE_ERROR: {
				return {
					...state,
					error: action.error,
					loading: false,
				}
			}
			case duck.types.GET_EVENTS_LIST: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.GET_EVENTS_LIST_SUCCESS: {
				return {
					...state,
					loading: false,
					loginFlowRedirectFlag: action.loginFlowRedirectFlag,
					components: {
						...state.components,
						eventList: action.data,
					},
				}
			}
			case duck.types.GET_EVENTS_LIST_ERROR: {
				return {
					...state,
					error: action.error,
					loading: false,
				}
			}
			case duck.types.GET_OFFERS: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.GET_OFFERS_SUCCESS: {
				return {
					...state,
					loading: false,
					components: {
						...state.components,
						offersList: action.data,
					},
				}
			}
			case duck.types.GET_OFFERS_ERROR: {
				return {
					...state,
					error: action.error,
					loading: false,
				}
			}
			case duck.types.SET_SELECTED_EVENT: {
				const prevEvent = getIn(state, [
					'selection',
					'event',
					'selectedEventCode',
				])
				const { selectedEventCode, selectedValue } = action.event
				const newSelection =
					prevEvent && prevEvent !== selectedEventCode
						? getIn(setIn(state, ['selection'], {}), ['selection'])
						: getIn(state, ['selection'])
				return {
					...state,
					selection: {
						...newSelection,
						event: selectedValue,
						eventCode: selectedEventCode,
					},
				}
			}
			case duck.types.SET_SELECTED_AUDIENCE: {
				const prevAudience = getIn(state, ['selection', 'audience'])
				const newSelection =
					prevAudience && prevAudience !== action.audience
						? getIn(setIn(state, ['selection', 'offer'], ''), [
								'selection',
						  ])
						: getIn(state, ['selection'])
				return {
					...state,
					selection: {
						...newSelection,
						audience: action.audience,
					},
				}
			}
			case duck.types.SET_SELECTED_OFFER: {
				return {
					...state,
					selection: {
						...state.selection,
						offer: action.offer,
					},
				}
			}
			case duck.types.SHOW_OFFER_DETAIL: {
				const { offer } = action.data
				const detail = offer[0]

				const { eventCode, eventName, startTime } = action.data
				const newState = setIn(state, ['loading'], false)
				return {
					...newState,
					offerDetail: {
						...detail,
						eventCode,
						eventName,
						startTime,
					},
				}
			}
			case duck.types.CREATE_OFFER: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.CREATE_OFFER_SUCCESS: {
				return {
					...state,
					loading: false,
				}
			}
			case duck.types.DEACTIVATE_OFFER: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.DEACTIVATE_OFFER_SUCCESS: {
				return {
					...state,
					loading: false,
				}
			}
			case duck.types.DEACTIVATE_OFFER_ERROR: {
				const { message } = action.error
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: message,
				}
			}
			case duck.types.GET_EVENT_OFFER_DETAIL: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.SHOW_OFFER_DETAIL_ERROR: {
				return {
					...state,
					error: action.error,
					loading: false,
				}
			}
			case duck.types.RESET_LOGIN_FLOW_REDIRECT_FLAG: {
				return {
					...state,
					loginFlowRedirectFlag: false,
				}
			}
			case duck.types.SEND_TRANSACTION_REPORTS: {
				return { ...state, fetchingReports: true }
			}
			case duck.types.TRANSACTION_REPORTS_SENT: {
				return { ...state, fetchingReports: false }
			}
			default:
				return state
		}
	},
	selectors: {
		root: state => state,
		offers: state => (state && state.offers) || {},
		offersComponents: state => state.offers.components || {},
		selectUsers: state => state.offers.components.audienceDetails || {},
		selection: state => state.offers.selection || {},
	},
	creators: duck => ({
		showActiveStep: step => ({
			type: duck.types.SHOW_ACTIVE_STEP,
			step,
		}),
		getEventsList: (query = '') => ({
			type: duck.types.GET_EVENTS_LIST,
			query,
		}),
		getEventsListSuccess: (data, loginFlowRedirectFlag = false) => ({
			type: duck.types.GET_EVENTS_LIST_SUCCESS,
			data,
			loginFlowRedirectFlag,
		}),
		getEventsListError: error => ({
			type: duck.types.GET_EVENTS_LIST_ERROR,
			error,
		}),
		getAudience: () => ({
			type: duck.types.GET_AUDIENCE,
		}),
		getAudienceSuccess: data => ({
			type: duck.types.GET_AUDIENCE_SUCCESS,
			data,
		}),
		getAudienceError: error => ({
			type: duck.types.GET_AUDIENCE_ERROR,
			error,
		}),
		getOffers: () => ({
			type: duck.types.GET_OFFERS,
		}),
		getOffersSuccess: data => ({
			type: duck.types.GET_OFFERS_SUCCESS,
			data,
		}),
		getOffersError: error => ({
			type: duck.types.GET_OFFERS_ERROR,
			error,
		}),
		setSelectedEvent: event => ({
			type: duck.types.SET_SELECTED_EVENT,
			event,
		}),
		setSelectedAudience: audience => ({
			type: duck.types.SET_SELECTED_AUDIENCE,
			audience,
		}),
		setSelectedOffer: offer => ({
			type: duck.types.SET_SELECTED_OFFER,
			offer,
		}),
		reset: () => ({
			type: duck.types.RESET,
		}),
		createOffer: activate => ({
			type: duck.types.CREATE_OFFER,
			activate,
		}),
		createOfferSuccess: () => ({
			type: duck.types.CREATE_OFFER_SUCCESS,
		}),
		createOfferError: error => ({
			type: duck.types.CREATE_OFFER_ERROR,
			error,
		}),
		deactivateOffer: params => ({
			type: duck.types.DEACTIVATE_OFFER,
			params,
		}),
		deactivateOfferSuccess: () => ({
			type: duck.types.DEACTIVATE_OFFER_SUCCESS,
		}),
		deactivateOfferError: error => ({
			type: duck.types.DEACTIVATE_OFFER_ERROR,
			error,
		}),
		getEventOfferDetail: eventCode => ({
			type: duck.types.GET_EVENT_OFFER_DETAIL,
			eventCode,
		}),
		showEventOfferDetail: data => ({
			type: duck.types.SHOW_OFFER_DETAIL,
			data,
		}),
		showEventOfferDetailError: error => ({
			type: duck.types.SHOW_OFFER_DETAIL_ERROR,
			error,
		}),
		sendTransactionReports: eventCode => ({
			type: duck.types.SEND_TRANSACTION_REPORTS,
			eventCode,
		}),
		fetchedTransactionReports: () => ({
			type: duck.types.TRANSACTION_REPORTS_SENT,
		}),
		resetloginFlowRedirectFlag: () => ({
			type: duck.types.RESET_LOGIN_FLOW_REDIRECT_FLAG,
		}),
	}),
})
