import { createComponent } from 'react-fela'

const LoaderWrapper = createComponent(() => ({
	height: '250px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}))

export { LoaderWrapper }
