import omit from 'lodash/omit'

const { dataLayer, clevertap, bmsMQTTClient } = window

const track = {
	GA: typeof dataLayer !== 'undefined' && dataLayer,
	WR: typeof clevertap !== 'undefined' && clevertap,
	CS: typeof bmsMQTTClient !== 'undefined' && bmsMQTTClient,
	//FBP: typeof dataLayer !== 'undefined' && dataLayer,
}

export default (
	select = ({ meta }) => meta.analytics
) => () => next => action => {
	const returnValue = next(action)
	// eslint-disable-next-line no-undef
	if (!action || !action.meta || !__CLIENT__) {
		return returnValue
	}

	const event = select(action)
	if (!event) {
		return returnValue
	}
	// eslint-disable-next-line no-undef
	if (process.env.NODE_ENV === 'production') {
		event.forEach(eventObj => {
			const tracker = track[eventObj.type]
			const { name } = eventObj

			if (!tracker) return returnValue

			if (eventObj.type === 'WR' && name) {
				const clevertapObj = { ...omit(eventObj, ['type', 'name']) }
				if (tracker.event) {
					tracker.event.push(name, clevertapObj)
				}
			} else if (eventObj.type === 'CS') {
				// Clickstream library strictly follows schema, so removing the type object
				const clickstreamObj = omit(eventObj, 'type')
				if (tracker.push) {
					tracker.push(clickstreamObj)
				}
			} else if (eventObj.type === 'GA') {
				if (tracker.push) {
					tracker.push({
						...eventObj,
						type: eventObj?.venueType || 'GA',
					})
				}
			}
			// else if (eventObj.type === 'FBP') {
			//     const pixelObj = { ...omit(eventObj, 'type') }
			//     if (tracker.push) {
			//         tracker.push(pixelObj)
			//     }
			// }
		})
	}
	return returnValue
}
