/** 
const dataURLtoFile = (dataurl, filename) => {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	const croppedImage = new File([u8arr], filename, { type: mime })
	return croppedImage
}
*/

export const getCroppedImg = (imgRef, crop, aspectRatio) => {
	const scaleX = imgRef.current.naturalWidth / imgRef.current.width
	const scaleY = imgRef.current.naturalHeight / imgRef.current.height

	const canvas = document.createElement('canvas')

	canvas.width = Math.ceil(crop.width * scaleX)

	// To make sure width will always be an even number.
	if (canvas.width % 2 !== 0) {
		canvas.width = canvas.width - 1
	}
	canvas.height = canvas.width / aspectRatio

	const ctx = canvas.getContext('2d')
	const image = imgRef.current
	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width * scaleX,
		crop.height * scaleY
	)
	if (crop?.width) {
		const reader = new FileReader()
		return new Promise(resolve => {
			canvas.toBlob(blob => {
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					// const file = dataURLtoFile(reader.result, 'cropped.jpg')
					// resolve([file, fileUrl])
					const fileUrl = window.URL.createObjectURL(blob)
					resolve([blob, fileUrl])
				}
			}, 'image/jpeg')
		})
	} else {
		return [null, null]
	}
}
