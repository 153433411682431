import { M5 } from './storage'
import AWS from 'aws-sdk'
import { addWeeks, addMonths, addYears, addDays } from 'date-fns'
import theme from 'bms-le-components/utils/base-ui-theme'

const getCookie = (name) => M5.Storage.get({ name }) || ''

export { getCookie }

export const debounce = (func, delay) => {
	let debounceTimer
	return function () {
		const context = this
		const args = arguments
		clearTimeout(debounceTimer)
		debounceTimer = setTimeout(() => func.apply(context, args), delay)
	}
}

export const throttle = (fn, wait) => {
	let time = Date.now()
	return function () {
		const context = this
		const args = arguments
		if (time + wait - Date.now() < 0) {
			fn.apply(context, args)
			time = Date.now()
		}
	}
}

export const yyyymmdd = () => {
	const date = new Date()
	const year = date.getFullYear().toString()
	let month = (date.getMonth() + 1).toString()
	let day = date.getDate().toString()
	day.length == 1 && (day = '0' + day)
	month.length == 1 && (month = '0' + month)
	return year + month + day
}

export const hhmm = (date) => {
	let hour = date.getHours().toString()
	let minute = date.getMinutes().toString()
	hour.length == 1 && (hour = '0' + hour)
	minute.length == 1 && (minute = '0' + minute)
	return hour + minute
}

export const sort = (data, type) =>
	data.sort((a, b) => (a[type] < b[type] ? -1 : 1))

export const sortDate = (data) =>
	data.sort((a, b) =>
		new Date(a.showDateTime) === new Date(b.showDateTime)
			? sort(data, 'venueCode')
			: new Date(a.showDateTime) - new Date(b.showDateTime)
	)

export const trimWhiteSpaces = (str) => str.trim().split(' ').join('+')

export const getLocation = () => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(getPosition, getError)
	} else {
		return getError()
	}
}

const getPosition = ({ coords: { latitude, longitude, accuracy } }) => ({
	latitude,
	longitude,
	accuracy,
})

const getError = () => ({ latitude: 'na', longitude: 'na', accuracy: 'na' })

export const hmac = (key, value) => {
	return AWS.util.crypto.lib.createHmac('sha256', key).update(value).digest()
}

export const hexhmac = (key, value) => {
	return AWS.util.crypto.lib
		.createHmac('sha256', key)
		.update(value)
		.digest('hex')
}

export const calculateDatesBasedOnInterval = (type, fromDate, noOfDays) => {
	let dates = []
	for (let i = 1; i <= noOfDays; i++) {
		if (type === 'WEEK') {
			dates.push(addWeeks(new Date(fromDate), i))
		}
		if (type === 'MONTH') {
			dates.push(addMonths(new Date(fromDate), i))
		}
		if (type === 'YEAR') {
			dates.push(addYears(new Date(fromDate), i))
		}
		if (type === 'DAY') {
			dates.push(addDays(new Date(fromDate), i))
		}
	}
	return dates
}

/** http://jsfiddle.net/alnitak/hEsys/ */
export const accessObjectValuesByStringPath = (object, path) => {
	let key = path.replace(/\[(\w+)\]/g, '.$1')
	key = key.replace(/^\./, '')
	let keyValues = key.split('.')
	for (let i = 0, n = keyValues.length; i < n; ++i) {
		let k = keyValues[i]
		if (k in object) {
			object = object[k]
		} else {
			return
		}
	}
	return object
}

export const downloadFile = (url, name) => {
	let link = document.createElement('a')
	link.download = name
	link.href = url
	link.target = '_blank'
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export const placeHolderBannerUrl =
	'//in.bmscdn.com/webin/test-m6/placeholders/event-placeholder.jpg'

export const listingImagePlacehHolderUrl =
	'//in.bmscdn.com/events/mobile/vertical-noimg.png'

export const validateSessionUnderLimit = (key, limit) => {
	if (
		(localStorage.getItem(key) === null ||
			+localStorage.getItem(key) < limit) &&
		!sessionStorage.getItem(key)
	) {
		// using session storage here to avoind showing coach marks multiple times in a single session
		sessionStorage.setItem(key, true)
		if (localStorage.getItem(key) === null) {
			localStorage.setItem(key, 1)
		} else {
			localStorage.setItem(key, +localStorage.getItem(key) + 1)
		}

		return true
	}

	return false
}

export const getValueInCurrencyFormat = (value) =>
	new Intl.NumberFormat('en-IN', {
		style: 'currency',
		currency: 'INR',
		maximumSignificantDigits: Math.abs(value).toFixed(2).length,
	}).format(parseFloat(value))

export const getDataInDescendingOrder = (data, key) =>
	data.sort((a, b) => {
		if (a[key] < b[key]) {
			return 1
		} else if (a[key] > b[key]) {
			return -1
		}
		return 0
	})

export const getDataInAscendingOrder = (data, key) =>
	data.sort((a, b) => {
		if (a[key] > b[key]) {
			return 1
		} else if (a[key] < b[key]) {
			return -1
		}
		return 0
	})

export const getProgressBarColor = (salesPercent) => {
	if (salesPercent < 25) {
		return theme.colors.bmsRed
	}
	if (salesPercent < 50) {
		return theme.colors.orange
	}
	if (salesPercent < 75) {
		return theme.colors.yellow
	}
	return theme.colors.bmsGreen
}

export const convertISTToLocalTimeZone = (date) => {
	const localDate = new Date(date)
	const offset = localDate.getTimezoneOffset()
	// IST offset = 330
	localDate.setMinutes(localDate.getMinutes() + offset + 330)
	return localDate.toISOString()
}

export const convertLocalTimeZoneToIST = (date) => {
	const localDate = new Date(date)
	const offset = localDate.getTimezoneOffset()
	// IST offset = 330
	localDate.setMinutes(localDate.getMinutes() - offset - 330)
	return localDate.toISOString()
}
