import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Wrapper, ButtonWrapper } from './__style'
import ActionBar from 'common/components/ActionBar'
import ActionContent from 'common/components/ActionContent'
import BottomDrawer from 'bms-le-components/components/BottomDrawer'
import LanguageSelector from 'modules/EventManagement/components/Selectors/LanguageSelector'
import DurationSelector from 'modules/EventManagement/components/Selectors/DurationSelector'
import AgeSelector from 'modules/EventManagement/components/Selectors/AgeSelector'
import { AGE_CATEGORIES } from 'modules/EventManagement/helpers/constants'
import EventManagerDuc from 'modules/EventManagement/duc'
import Button from 'bms-le-components/atoms/Button'
import TextField from 'bms-le-components/atoms/TextField'
import { AppDuc } from 'modules/App/duc'
import theme from 'bms-le-components/utils/base-ui-theme'

const AdditionalInfo = (props) => {
	const {
		isDesktop,
		masterLanguageList,
		getMastersData,
		loading,
		draftId,
		eventCode,
		additionalInfo,
		updateSynopsis,
		updateEventData,
		buttonLoading,
		eventNotification,
		match: { params },
		duration,
		status,
		showSaveButton,
		analyticsPush,
	} = props

	const disableEdit = eventNotification?.type === 'review'
	const { section } = params
	const [active, setActive] = useState('')
	const [durationError, setDurationError] = useState(false)
	const languages = additionalInfo.languages ? additionalInfo.languages : []
	const ageLimit = additionalInfo.ageLimit ? additionalInfo.ageLimit : ''

	useEffect(() => {
		if (active && !disableEdit) {
			handleDrawerToggle()
		}
	}, [isDesktop, disableEdit])

	const getLanguagesMasterList = (searchValue = '') => {
		getMastersData({ component: 'languages', searchValue })
	}

	const handleActionClick = (name) => {
		if (!disableEdit) {
			analyticsPush({
				event_name: `event_${name}_selection_viewed`,
				event_type: 'screen_view',
				event: 'gtm_allpage',
				screen_name: `event_${name}_selection`,
			})
			setActive(name)
		}
	}

	const handleDrawerToggle = () => {
		if (!disableEdit) setActive('')
	}

	const handleLanguageSave = (languages) => {
		if (!languages.length || !ageLimit.length || duration < 0) return
		const additionalInfo = {
			languages,
			ageLimit,
			duration,
		}
		if (!disableEdit) {
			analyticsPush({
				event_name: 'event_language_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'continue_clicked',
				screen_name: 'event_language_selection',
			})
			updateEventData(
				draftId,
				eventCode,
				'additionalInfo',
				additionalInfo
			)
		}
	}

	const handleAgeLimitSave = (ageLimit) => {
		if (
			!languages.length ||
			!ageLimit.length ||
			duration === undefined ||
			duration < 0
		) {
			return
		}
		const additionalInfo = {
			ageLimit,
			languages,
			duration,
		}
		if (!disableEdit) {
			analyticsPush({
				event_name: 'event_age_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'continue_clicked',
				screen_name: 'event_age_selection',
			})
			updateEventData(
				draftId,
				eventCode,
				'additionalInfo',
				additionalInfo
			)
		}
	}

	const handleDurationSave = () => {
		const { id } = params
		const additionalInfo = {
			ageLimit,
			languages,
			duration,
		}
		if (!disableEdit) {
			analyticsPush({
				event_name: 'event_duration_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'continue_clicked',
				screen_name: 'event_duration_selection',
			})
			updateEventData(id, eventCode, 'additionalInfo', additionalInfo)
		}
		handleDrawerToggle()
	}

	const onSave = () => {
		const { id } = params
		const additionalInfo = {
			ageLimit,
			languages,
			duration,
		}
		if (!disableEdit) {
			analyticsPush({
				event_name: 'event_additional_info_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'continue_clicked',
				screen_name: 'event_additional_info',
			})
			updateEventData(id, eventCode, 'additionalInfo', additionalInfo)
		}
	}

	const onDurationchange = (e) => {
		if (e?.target?.value && durationError) {
			setDurationError(false)
		}
		updateSynopsis(
			['additionalInfo', 'duration'],
			parseInt(e?.target?.value)
		)
	}

	const onDurationBlur = () => {
		if (isNaN(duration)) {
			setDurationError(true)
		}
	}

	const ActiveLanguageContent =
		languages.length > 0 ? ActionContent : ActionBar

	const ActiveAgeContent = ageLimit.length > 0 ? ActionContent : ActionBar

	const DurationContent = duration > 0 ? ActionContent : ActionBar

	return (
		<>
			{isDesktop ? (
				<>
					<LanguageSelector
						isDesktop={isDesktop}
						loading={loading}
						categories={masterLanguageList}
						updateSynopsis={updateSynopsis}
						handleLanguageSave={handleLanguageSave}
						handleModalToggle={handleDrawerToggle}
						getLanguageCategories={getLanguagesMasterList}
						selected={languages}
						disableEdit={disableEdit}
						analyticsPush={analyticsPush}
					/>
					<AgeSelector
						isDesktop={isDesktop}
						categories={AGE_CATEGORIES}
						updateSynopsis={updateSynopsis}
						handleAgeLimitSave={handleAgeLimitSave}
						handleModalToggle={handleDrawerToggle}
						selected={ageLimit}
						disableEdit={disableEdit}
						analyticsPush={analyticsPush}
					/>
					<TextField
						style={{ margin: '8px auto', width: '100%' }}
						inputStyles={{
							border: `1px solid ${
								durationError
									? theme.colors.errorRed
									: '#CCCCCC'
							}`,
							borderRadius: '4px',
							fontSize: '16px',
							padding: '8px',
							marginRight: '20px',
						}}
						hideClearIcon
						isRequired
						name="duration"
						type="number"
						placeholder="Enter duration in minutes *"
						value={duration}
						onChange={onDurationchange}
						tabIndex={0}
						isPhoneNumber
						onBlur={onDurationBlur}
					/>
					{isDesktop && section && !disableEdit && showSaveButton && (
						<ButtonWrapper isDesktop={isDesktop}>
							<Button
								style={{ margin: '0 auto' }}
								size="LARGE"
								onClick={onSave}
								isFullWidth={!isDesktop}
								disabled={!duration}
								isLoading={buttonLoading}
							>
								Save
							</Button>
						</ButtonWrapper>
					)}
				</>
			) : (
				<>
					<BottomDrawer
						isOpen={active}
						toggleHandler={handleDrawerToggle}
						showCloseIcon
					>
						{active === 'language' && (
							<LanguageSelector
								loading={loading}
								categories={masterLanguageList}
								updateSynopsis={updateSynopsis}
								handleLanguageSave={handleLanguageSave}
								handleModalToggle={handleDrawerToggle}
								getLanguageCategories={getLanguagesMasterList}
								selected={languages}
								analyticsPush={analyticsPush}
								isBottomDrawer={true}
							/>
						)}
						{active === 'age' && (
							<AgeSelector
								categories={AGE_CATEGORIES}
								updateSynopsis={updateSynopsis}
								handleAgeLimitSave={handleAgeLimitSave}
								handleModalToggle={handleDrawerToggle}
								selected={ageLimit}
								analyticsPush={analyticsPush}
								isBottomDrawer={true}
							/>
						)}
						{active === 'duration' && (
							<DurationSelector
								isDesktop={isDesktop}
								onDurationchange={onDurationchange}
								duration={Number(duration)}
								handleProceed={handleDurationSave}
								analyticsPush={analyticsPush}
								isBottomDrawer={true}
							/>
						)}
					</BottomDrawer>
					<Wrapper>
						<ActiveLanguageContent
							placeholder="Search for a language"
							type="language"
							handleClick={() => handleActionClick('language')}
							textContent={languages
								.map((item) => item.name)
								.join(', ')}
							status={status}
							disableEdit={disableEdit}
						/>

						<ActiveAgeContent
							placeholder="Set age limit for the audience"
							type="age"
							handleClick={() => handleActionClick('age')}
							textContent={ageLimit}
							status={status}
							disableEdit={disableEdit}
						/>

						<DurationContent
							placeholder="Set duration for the event"
							type="duration"
							handleClick={() => handleActionClick('duration')}
							textContent={duration}
							status={status}
							disableEdit={disableEdit}
						/>
					</Wrapper>
				</>
			)}
		</>
	)
}

const mapStateToProps = ({ device, eventManager }) => ({
	isDesktop: device.detection.isDesktop,
	masterLanguageList: eventManager.masters.languages || [],
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	duration: eventManager.activeEvent?.additionalInfo?.duration,
	additionalInfo: eventManager.activeEvent.additionalInfo || {
		languages: [],
		ageLimit: '',
	},
	loading: eventManager.loading,
	buttonLoading: eventManager.buttonLoading,
	eventNotification: eventManager?.eventNotification,
	showSaveButton: eventManager?.showSaveButton,
})

const mapDispatchToProps = (dispatch) => ({
	getMastersData: (query) =>
		dispatch(EventManagerDuc.creators.getMastersData(query)),
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdditionalInfo))
