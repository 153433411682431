import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'rc-tooltip/assets/bootstrap.css'

// components
import LazyLoad from 'common/components/LazyLoad'
import PrivateRoute from 'utils/PrivateRoute'

// duc
import { AppDuc } from 'modules/App/duc'
import LoginDuc from 'modules/Login/duc'

const EventManager = React.lazy(() => import('modules/EventManagement'))
const Offers = React.lazy(() => import('modules/Offers'))
const AppListing = React.lazy(() => import('modules/AppListing'))
const ScanningApp = React.lazy(() => import('modules/ScanningApp'))
const Dashboard = React.lazy(() => import('modules/Dashboard'))
const OnBoardUser = React.lazy(() => import('modules/OnBoardUser'))
const OrganiserApproval = React.lazy(() => import('modules/OrganiserApproval'))
const CreateAccount = React.lazy(() => import('modules/CreateAccount'))

// themes
import theme from 'bms-le-components/utils/base-ui-theme'

const { IAM } = window

const ProtectedRoutes = ({
	initiateLogOut,
	analyticsPush,
	isDesktop,
	userName,
}) => {
	return (
		<>
			{IAM && (
				<>
					<PrivateRoute path="/apps/diy/users">
						<LazyLoad>
							<Dashboard isDesktop={isDesktop} />
						</LazyLoad>
					</PrivateRoute>
					<PrivateRoute path="/apps/diy/onboard">
						<LazyLoad>
							<OnBoardUser isDesktop={isDesktop} />
						</LazyLoad>
					</PrivateRoute>
					<PrivateRoute path="/apps/diy/organiser-approval">
						<LazyLoad>
							<OrganiserApproval />
						</LazyLoad>
					</PrivateRoute>
				</>
			)}
			<PrivateRoute path={IAM ? '/apps/diy/home' : '/apps'}>
				<LazyLoad>
					<AppListing
						userId={userName}
						initiateLogOut={initiateLogOut}
						history={history}
						analyticsPush={analyticsPush}
						isDesktop={isDesktop}
					/>
				</LazyLoad>
			</PrivateRoute>
			<PrivateRoute path="/scanningApp">
				<LazyLoad>
					<ScanningApp
						history={history}
						analyticsPush={analyticsPush}
						userId={userName}
					/>
				</LazyLoad>
			</PrivateRoute>
			<PrivateRoute path="/offers">
				<LazyLoad>
					<Offers
						initiateLogOut={initiateLogOut}
						history={history}
						analyticsPush={analyticsPush}
						userId={userName}
					/>
				</LazyLoad>
			</PrivateRoute>
			<PrivateRoute path="/events">
				<LazyLoad>
					<EventManager
						initiateLogOut={initiateLogOut}
						history={history}
						analyticsPush={analyticsPush}
						userId={userName}
						isDesktop={isDesktop}
					/>
				</LazyLoad>
			</PrivateRoute>
			<PrivateRoute exact path="/create-account/:id">
				<LazyLoad>
					<CreateAccount />
				</LazyLoad>
			</PrivateRoute>
		</>
	)
}

const mapStateToProps = ({ app, device }) => {
	return {
		isDesktop: device.detection.isDesktop,
		userName: app.userDetails?.username,
	}
}

const mapDispatchToProps = (dispatch) => ({
	initiateLogOut: (isAnalyticsEnabled) => {
		if (isAnalyticsEnabled) {
			dispatch(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							event_name: 'profile_icon_button_clicked',
							screen_name: 'offer_config_landing_page',
							event_type: 'click',
							event: 'gtm_std_event',
						},
					]
				)
			)
		}

		dispatch(
			AppDuc.creators.showPopup({
				title: 'Are you sure?',
				content: 'You will be logged out of your account',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: () => {
					dispatch(AppDuc.creators.hidePopup())
				},
				buttons: [
					{
						text: 'Yes',
						action: () => {
							dispatch(LoginDuc.creators.handleLogOut())
							if (isAnalyticsEnabled) {
								dispatch(
									AppDuc.creators.wrapAnalytics(
										AppDuc.creators.NoActionAnalytics(),
										[
											{
												type: 'GA',
												app_code: 'DIY',
												event_name:
													'logout_button_clicked',
												screen_name:
													'offer_config_landing_page',
												event_type: 'click',
												event: 'gtm_std_event',
											},
										]
									)
								)
							}
						},
					},
					{
						text: 'No',
						action: () => {
							dispatch(AppDuc.creators.hidePopup())
						},
						inverted: true,
						textColor: theme.buttonVariants.primary,
					},
				],
				contentstart: true,
			})
		)
	},
	hideToast: () => dispatch(AppDuc.creators.showToast()),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData.GA,
				},
			])
		),
	dispatch,
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ProtectedRoutes))
