import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import EventManagerDuc from 'modules/EventManagement/duc'
import ReasonToAttend from 'modules/EventManagement/components/EventReasons/ReasonToAttend'
import { BottomNavBar, DesktopWrapper, MobileWrapper } from './__style'
import Button from 'bms-le-components/atoms/Button'
import InfoAlertBar from 'common/components/InfoAlertBar'
import Information from 'common/icons/information.svg'
import ErrorAlert from 'common/components/ErrorAlert'
import { AppDuc } from 'modules/App/duc'

// helpers
import { validateInputFields } from 'modules/EventManagement/helpers'

function EventReasons({
	isDesktop,
	draftId,
	eventCode,
	reasonsToAttend,
	updateEventData,
	buttonLoading,
	rejectionReasons,
	analyticsPush,
}) {
	const history = useHistory()

	const onSave = () => {
		analyticsPush({
			event_name: 'event_reason_to_attend_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'continue_clicked',
			screen_name: 'event_reason_to_attend',
		})
		updateEventData(draftId, eventCode, 'reasonsToAttend', reasonsToAttend)
		if (validateInputFields('reasonsToAttend', reasonsToAttend)) {
			history.goBack()
		}
	}

	const onBlur = () => {
		analyticsPush({
			event_name: 'event_reason_to_attend_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'reason_inputted',
			screen_name: 'event_reason_to_attend',
		})
	}
	const Wrapper = isDesktop ? DesktopWrapper : MobileWrapper

	return (
		<Wrapper>
			<div>
				{!eventCode ? (
					<InfoAlertBar
						isDesktop={isDesktop}
						svgIcon={Information}
						details="Give users, upto 3 compelling reasons to attend your event."
						spaceBottom
					/>
				) : null}
				{rejectionReasons.length > 0 && (
					<ErrorAlert
						errorMessages={rejectionReasons}
						isDesktop={isDesktop}
					/>
				)}
				<ReasonToAttend
					label="Reasons to attend"
					reasonKey={0}
					maxLength={100}
					placeholder="Reason 1 (Optional)"
					onBlur={onBlur}
				/>
				<ReasonToAttend
					reasonKey={1}
					maxLength={100}
					placeholder="Reason 2 (Optional)"
					disabled={!reasonsToAttend[0]}
					onBlur={onBlur}
				/>
				<ReasonToAttend
					reasonKey={2}
					maxLength={100}
					placeholder="Reason 3 (Optional)"
					disabled={!reasonsToAttend[1]}
					onBlur={onBlur}
				/>
			</div>
			{!isDesktop && (
				<BottomNavBar fixed>
					<Button
						size="LARGE"
						isFullWidth
						onClick={onSave}
						disabled={
							!validateInputFields(
								'reasonsToAttend',
								reasonsToAttend
							)
						}
						isLoading={buttonLoading}
					>
						Continue
					</Button>
				</BottomNavBar>
			)}
		</Wrapper>
	)
}
const mapStateToProps = ({ device, eventManager }) => ({
	isDesktop: device.detection.isDesktop,
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	reasonsToAttend: eventManager.activeEvent.reasonsToAttend || ['', '', ''],
	buttonLoading: eventManager?.buttonLoading,
	rejectionReasons:
		eventManager.activeEvent.formattedRejectionReasons?.reasonsToAttend ||
		[],
})

const mapDispatchToProps = (dispatch) => ({
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventReasons)
