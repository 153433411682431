import React from 'react'
import { connect } from 'react-redux'
import EventManagerDuc from 'modules/EventManagement/duc'
import {
	ReasonWrapper,
	TextWrapper,
	TextLabel,
	TextError,
	CharacterLimitText,
} from './__style'
import TextAreaInput from 'common/components/TextAreaInput'

// helpers
import { validateInputFields } from 'modules/EventManagement/helpers'

function ReasonToAttend({
	label,
	reasonKey,
	placeholder,
	reasonsToAttend,
	updateSynopsis,
	maxLength,
	disabled,
	onBlur,
}) {
	const value = reasonsToAttend[reasonKey]
	const onChange = (value) => {
		const reason = value.slice(0, maxLength)
		const updatedReasons = [...reasonsToAttend]
		updatedReasons[reasonKey] = reason
		updateSynopsis('reasonsToAttend', updatedReasons)
	}
	const reasonLength = value ? value.length : 0
	const lengthStatus =
		reasonLength === 0
			? 'default'
			: reasonLength <= maxLength
			? 'success'
			: 'error'
	const showError = value
		? !validateInputFields('reasonsToAttend', value)
		: false
	return (
		<ReasonWrapper>
			<TextWrapper>
				<TextLabel>{label}</TextLabel>
				<CharacterLimitText
					status={lengthStatus}
				>{`${reasonLength}/${maxLength}`}</CharacterLimitText>
			</TextWrapper>
			<TextAreaInput
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled}
				onBlur={onBlur}
				isError={
					value &&
					(value.length > maxLength ||
						(value.length !== maxLength && showError))
				}
			/>
			{value && value.length > maxLength ? (
				<TextError>Character limit reached</TextError>
			) : null}
			{value && value.length !== maxLength && showError ? (
				<TextError>{`Only these characters A-Za-z0-9,.(){}~[]<>?;:'"!@#$%^&*-=_+ are allowed`}</TextError>
			) : null}
		</ReasonWrapper>
	)
}

const mapStateToProps = ({ eventManager }) => ({
	reasonsToAttend: eventManager.activeEvent.reasonsToAttend || ['', '', ''],
})

const mapDispatchToProps = (dispatch) => ({
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReasonToAttend)
