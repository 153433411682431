import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// components
import ActionBar from 'common/components/ActionBar'
import VenueScheduler from './components/VenueScheduler'
import SelectedDateTimeView from 'modules/EventManagement/components/VenueSchedule/components/SelectedDateTimeView'

// styles
import { VenueSectionWrapper, MainWrapper } from './__style'

// duc
import EventManagerDuc from 'modules/EventManagement/duc'
import { AppDuc } from 'modules/App/duc'

const VenueSchedule = (props) => {
	const {
		isDesktop,
		selectedVenues,
		history,
		match,
		setCurrentVenue,
		eventNotification,
		analyticsPush,
		venueType,
		eventCode,
		status,
		venueSubType,
		location,
		eventCurrentStatus,
	} = props

	const [showVenueModal, toggleVenueModal] = useState(false)
	const [errorText, displayErrorText] = useState('')
	const [showVenueChange, toggleShowVenueChange] = useState(false)
	const [disableEditingSessions, setDisableEditingSessions] = useState(false)

	// If the zoom event is active and is hosted by webOps team
	// disable all session editing
	useEffect(() => {
		if (
			selectedVenues.length &&
			venueType == 'online' &&
			status == 'active' &&
			venueSubType == 'zoom'
		) {
			const numberOfSessions =
				selectedVenues[0]['sessionInfo']?.length || 0
			setDisableEditingSessions(numberOfSessions > 1)
		}
	}, [venueType, status, venueSubType, selectedVenues])

	const handleActionClick = () => {
		toggleShowVenueChange(true)
		if (!showVenueModal) {
			analyticsPush({
				event_name: 'event_synopsis_action',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'venue_selection_clicked',
				screen_name: 'event_synopsis',
			})
		}
		toggleVenueModal(!showVenueModal)
	}

	const initiateVenueScheduler = () => {
		displayErrorText('')
		if (!showVenueModal) {
			analyticsPush({
				event_name: 'event_synopsis_action',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'venue_selection_clicked',
				screen_name: 'event_synopsis',
			})
		}
		toggleVenueModal(!showVenueModal)
	}

	const handleDateTimeClick = () => {
		if (selectedVenues.length === 0) {
			displayErrorText(
				'Oops! You can not enter date and time unless you pick a venue first.'
			)
		}
	}
	const onDeleteOfSelectedVenue = () => {
		toggleShowVenueChange(false)
	}

	const onDateChange = () => {
		const addSession = JSON.parse(
			new URLSearchParams(location?.search).get('addSession') || 'false'
		)
		toggleVenueModal(false)
		let path =
			match.url.indexOf('/edit/') === -1
				? match.url + '/edit/date-time-scheduler'
				: match.url.replace(/edit.*$/i, 'edit/date-time-scheduler')
		if (addSession) {
			path += '?addSession=true'
		}

		history.push(path)
	}

	/**
	 * Allow user to change the venue only if the event
	 * (1. Doesn't have event code
	 * 		--AND--
	 * 2. Status is "draft")
	 * 		--OR--
	 * 1. venueType is onGround (if the venue is onGround, BE will take care of this
	 * validation, user can change venue until atlease one ticket is been booked in the
	 * configured venue.)
	 */
	const allowEditingVenue =
		venueType === 'onGround' || (status === 'draft' && !eventCode)

	return (
		<MainWrapper isDesktop={isDesktop}>
			{showVenueModal && (
				<VenueScheduler
					isDesktop={isDesktop}
					showModal={showVenueModal}
					handleModalToggle={initiateVenueScheduler}
					attr="event-schedule"
					handleClick={initiateVenueScheduler}
					onDateChange={onDateChange}
					onDeleteOfSelectedVenue={onDeleteOfSelectedVenue}
					showVenueChange={showVenueChange}
				/>
			)}
			{selectedVenues && selectedVenues.length > 0 ? (
				<SelectedDateTimeView
					handleActionClick={handleActionClick}
					selectedVenues={selectedVenues}
					setCurrentVenue={setCurrentVenue}
					isDesktop={isDesktop}
					onDateChange={onDateChange}
					disableEdit={
						eventNotification?.type === 'review' ||
						!allowEditingVenue
					}
					venueSubType={venueSubType}
					disableSessions={disableEditingSessions}
					eventCurrentStatus={eventCurrentStatus}
				/>
			) : (
				<VenueSectionWrapper isDesktop={isDesktop}>
					<ActionBar
						placeholder="Pick a venue from our directory"
						type="venue"
						handleClick={initiateVenueScheduler}
						isDesktop={isDesktop}
					/>
					<ActionBar
						placeholder="Enter date and time"
						type="calendar"
						handleClick={handleDateTimeClick}
						errorText={errorText}
						isDesktop={isDesktop}
					/>
				</VenueSectionWrapper>
			)}
		</MainWrapper>
	)
}

const mapStateToProps = ({ eventManager }) => ({
	selectedVenues: eventManager.activeEvent.venueInfo || [],
	draftId: eventManager.activeEvent.draftId || '',
	currentVenue: eventManager.activeEvent.currentVenue || {},
	eventCode: eventManager.activeEvent.eventCode || '',
	eventNotification: eventManager?.eventNotification,
	venueType: eventManager.activeEvent?.venueType || 'onGround',
	status: eventManager?.activeEvent?.status,
	venueSubType: eventManager?.activeEvent?.venueSubType || '',
	eventCurrentStatus: eventManager.activeEvent.eventCurrentStatus || '',
})

const mapDispatchToProps = (dispatch) => ({
	setCurrentVenue: (venue) =>
		dispatch(EventManagerDuc.creators.setCurrentVenue(venue)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	setSelectedVenues: (venues) =>
		dispatch(EventManagerDuc.creators.setSelectedVenues(venues)),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(VenueSchedule))
