import { createComponent } from 'react-fela'

const __wrapper = ({ isDesktop }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	alignItems: 'center',
	alignContent: 'center',
	margin: '10px 0',
	extend: [
		{
			condition: !isDesktop,
			style: { boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.16)' },
		},
	],
})

const __buttonWrapper = ({ isDesktop }) => ({
	padding: 15,
	width: isDesktop ? '30%' : '100%',
})

const Wrapper = createComponent(__wrapper)
const ButtonWrapper = createComponent(__buttonWrapper)

export { Wrapper, ButtonWrapper }
