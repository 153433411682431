import React from 'react'
import { Alert, ListItem } from './__style'

const ErrorAlert = props => {
	const { errorMessages, isDesktop, style = {} } = props
	return (
		<Alert isDesktop={isDesktop} style={style}>
			{errorMessages.map(({ value }) => (
				<ListItem key={value}>{value}</ListItem>
			))}
		</Alert>
	)
}

export default ErrorAlert
