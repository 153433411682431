import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import OffersDuc from './duc'
import { privateRequest } from './../../utils/request'
import { push } from 'connected-react-router'
import { AppDuc } from './../App/duc'

export function* getAudience() {
	try {
		const response = yield call(() => privateRequest.get(`offers/audience`))
		const { data } = response || {}
		if (data.status === 200) {
			yield put(OffersDuc.creators.getAudienceSuccess(data.data))
			const audience = data.data.results.map(i => i.type)
			yield put(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							audience,
							event_name: 'audience_selection_page_viewed',
							screen_name: 'offer_config_audience_selection',
							event_type: 'screen_view',
							event: 'gtm_allpage',
						},
					]
				)
			)
		}
	} catch (e) {
		yield put(OffersDuc.creators.getAudienceError(e))
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops, something went wrong.',
				content: 'Try again later.',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: dispatch => {
					dispatch(AppDuc.creators.hidePopup())
					dispatch(push('/offers/landing'))
				},
				buttons: [
					{
						text: 'Okay',
						action: dispatch => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(push('/offers/landing'))
						},
					},
				],
				contentstart: false,
			})
		)
	}
}

export function* getEventsList(params) {
	try {
		const response = yield call(() =>
			privateRequest.get(`offers/events${params.query}`)
		)
		const { data } = response || {}
		if (data.status === 200) {
			yield put(OffersDuc.creators.getEventsListSuccess(data.data))
		}
	} catch (e) {
		const { response } = e || {}
		if (response.status === 401) {
			yield put(push('/login'))
		} else {
			yield put(OffersDuc.creators.getEventsListError(e))
			yield put(
				AppDuc.creators.showPopup({
					title: 'Oops, something went wrong.',
					content: 'Try again later.',
					verticalPosition: 'middle',
					nonCloseable: false,
					closeAction: dispatch => {
						dispatch(AppDuc.creators.hidePopup())
						dispatch(push('/offers/landing'))
					},
					buttons: [
						{
							text: 'Okay',
							action: dispatch => {
								dispatch(AppDuc.creators.hidePopup())
								dispatch(push('/offers/landing'))
							},
						},
					],
					contentstart: false,
				})
			)
		}
	}
}

export function* getOffers() {
	try {
		const { audience } = yield select(OffersDuc.selectors.selection)
		const config = {
			params: {
				audienceType: audience,
			},
		}
		const response = yield call(() => privateRequest.get(`offers`, config))
		const { data } = response || {}
		if (data.status === 200) {
			yield put(OffersDuc.creators.getOffersSuccess(data.data))
			const offer_id = data.data.results.map(i => i.code)
			const offer_title = data.data.results.map(i => i.name)
			yield put(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							offer_id,
							offer_title,
							event_name: 'offer_selection_page_viewed',
							screen_name: 'offer_config_offer_selection',
							event_type: 'screen_view',
							event: 'gtm_allpage',
						},
					]
				)
			)
		}
	} catch (e) {
		yield put(OffersDuc.creators.getOffersError(e))
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops, something went wrong.',
				content: 'Try again later.',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: dispatch => {
					dispatch(AppDuc.creators.hidePopup())
					dispatch(push('/offers/landing'))
				},
				buttons: [
					{
						text: 'Okay',
						action: dispatch => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(push('/offers/landing'))
						},
					},
				],
				contentstart: false,
			})
		)
	}
}

export function* createOffer() {
	try {
		const { eventCode, offer, event, audience } = yield select(
			OffersDuc.selectors.selection
		)
		const config = {
			payload: [
				{
					eventCode,
					offerCodes: [offer],
				},
			],
			status: true,
		}
		const response = yield call(() => privateRequest.post(`offers`, config))
		const { data } = response || {}
		if (data.status == 200) {
			yield put(OffersDuc.creators.createOfferSuccess(data.data))
			yield put(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							event_name: 'offer_created',
							screen_name: 'offer_config_offers_listing',
							event_type: 'screen_view',
							event: 'gtm_allpage',
							event_code: eventCode,
							event_title: event,
							offer_id: offer,
							audience,
						},
					]
				)
			)
			//Success - Show Toast
			yield put(
				AppDuc.creators.showToast(
					'info',
					true,
					'Offer created & will be live within 15 minutes.'
				)
			)
			yield put(push('/offers/dashboard'))
		}
	} catch (e) {
		yield put(OffersDuc.creators.createOfferError(e))
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops, something went wrong.',
				content: 'Try again later.',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: dispatch => {
					dispatch(AppDuc.creators.hidePopup())
					dispatch(push('/offers/landing'))
				},
				buttons: [
					{
						text: 'Okay',
						action: dispatch => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(push('/offers/landing'))
						},
					},
				],
				contentstart: false,
			})
		)
	}
}

export function* getEventOfferDetail(params) {
	try {
		const { eventCode } = params
		const response = yield call(() =>
			privateRequest.get(`offers/events/${eventCode}`)
		)
		const { data } = response || {}
		if (data.status === 200) {
			yield put(OffersDuc.creators.showEventOfferDetail(data.data))
			const offerDetail = data.data
			yield put(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							event_name: 'offer_detail_page_viewed',
							screen_name: 'offer_config_offer_detail',
							event_type: 'screen_view',
							event: 'gtm_allpage',
							event_title: offerDetail.eventName,
							event_code: offerDetail.eventCode,
							offer_title: offerDetail.offer[0].name,
							offer_id: offerDetail.offer[0].code,
						},
					]
				)
			)
		}
	} catch (e) {
		yield put(OffersDuc.creators.showEventOfferDetailError(e))
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops, something went wrong.',
				content: 'Try again later.',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: dispatch => {
					dispatch(AppDuc.creators.hidePopup())
					dispatch(push('/offers/dashboard'))
				},
				buttons: [
					{
						text: 'Okay',
						action: dispatch => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(push('/offers/dashboard'))
						},
					},
				],
				contentstart: false,
			})
		)
	}
}

export function* deactivateOffer({ params }) {
	try {
		const { eventCode, code, status } = params
		const activate = status == 'ARCHIVED' ? true : false
		const config = {
			payload: [
				{
					eventCode: eventCode,
					offerCodes: [code],
				},
			],
			status: activate,
		}
		const response = yield call(() => privateRequest.post(`offers`, config))
		const { data } = response || {}
		if (data.status == 200) {
			yield put(OffersDuc.creators.deactivateOfferSuccess(data.data))
			//Success - Show Toast
			yield put(
				AppDuc.creators.showToast(
					`${activate ? 'info' : 'success'}`,
					true,
					`Offer ${activate ? 'Activated' : 'Deactivated'}.`
				)
			)
			yield put(push('/offers/dashboard'))
		}
	} catch (e) {
		const { response } = e
		yield put(OffersDuc.creators.deactivateOfferError(response.data))
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops, something went wrong.',
				content: 'Try again later.',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: dispatch => {
					dispatch(AppDuc.creators.hidePopup())
					// dispatch(push('/offers/dashboard'))
				},
				buttons: [
					{
						text: 'Back to Dashboard',
						action: dispatch => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(push('/offers/dashboard'))
						},
					},
				],
				contentstart: false,
			})
		)
	}
}

export function* sendTransactionReports({ eventCode }) {
	try {
		const response = yield call(() =>
			privateRequest.post(`offers/sendTransactionReports`, {
				etCode: eventCode,
			})
		)
		const { data = {} } = response
		if (data && response.status === 200) {
			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					'Reports sent to your registered mail id'
				)
			)
		}
	} catch (e) {
		const { response } = e
		let errorMessage = 'Things went south, please try again'
		if (response && response.status) {
			const { data: { errors = {} } = {} } = response
			if (errors.message) {
				errorMessage = errors.message
			}
		}
		yield put(AppDuc.creators.showToast('info', true, errorMessage))
	} finally {
		yield put(OffersDuc.creators.fetchedTransactionReports())
	}
}

export function* locationChange({ payload }) {
	const { location } = payload
	const page = location.pathname.split('/')[1] || ''
	const space = location.pathname.split('/')[2] || ''
	const component = location.pathname.split('/')[3] || ''
	if (page === 'offers' && space === 'configure') {
		yield put(OffersDuc.creators.showActiveStep(component))
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(OffersDuc.types.GET_AUDIENCE, getAudience),
		takeLatest(OffersDuc.types.GET_EVENTS_LIST, getEventsList),
		takeLatest(OffersDuc.types.GET_OFFERS, getOffers),
		takeLatest(OffersDuc.types.CREATE_OFFER, createOffer),
		takeLatest(OffersDuc.types.GET_EVENT_OFFER_DETAIL, getEventOfferDetail),
		takeLatest(OffersDuc.types.DEACTIVATE_OFFER, deactivateOffer),
		takeLatest('@@router/LOCATION_CHANGE', locationChange),
		takeLatest(
			OffersDuc.types.SEND_TRANSACTION_REPORTS,
			sendTransactionReports
		),
	])
}
