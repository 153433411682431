export const ERROR_MESSAGES = {
	ERR_BARCODESERVICE_ALREADY_SCANNED: '<h2>Oops! Ticket already scanned</h2>',
	ERR_BARCODESERVICE_INVALID:
		'<h2>Invalid ticket!</h2><h4>Ticket does not belong to this session</h4>',
	ERR_BARCODESERVICE_UNKNOWN:
		'<h2>Something went wrong!</h2> <h4>Please try again</h4>',
	ERR_BARCODESERVICE_INVALID_BOOKINGID:
		'<h2>This is not a valid format of Booking ID.</h2>',
	ERR_BARCODESERVICE_NOT_FOUND: '<h2>No ticket found.</h2>',
	ERR_BARCODESERVICE_NO_ACTIVE_SESSIONS:
		'<h2>There are currently no active session for this event.</h2>',
	ERR_BARCODESERVICE_APPERR:
		'We could not fetch the list of events. Please try again after some time.',
	ERR_AUTH_UNAUTHORIZED: 'Sorry, you are not authorized perform this action',
	ERR_BARCODESERVICE_NOTSCANNEDIN: '<h2>Please scan for entry first</h2>',
	ERR_BARCODESERVICE_ALREADY_SCANNED_OUT:
		'<h2>Ticket already scanned out.<h2>',
}

export const ERROR_TYPES = {
	MISSED_PARAM: {
		type: 'MISSED_PARAM',
		message:
			'Page refreshed, Please select your event again before proceeding',
	},
}

export const TAB_KEYS = ['Entry', 'Exit']
