import React from 'react'
import { InputBar, TextSpace, EditBtn, InfoWrapper } from './__style'
import Icon from 'bms-le-components/common/ui/Icon'
import venueIcon from 'common/icons/venueIcon.svg'
import artistIcon from 'common/icons/ArtistIcon.svg'
import languageIcon from 'common/icons/LanguageIcon.svg'
import ageIcon from 'common/icons/AgeIcon.svg'
import Duration from 'common/icons/Duration.svg'

const iconMap = {
	venue: venueIcon,
	artist: artistIcon,
	age: ageIcon,
	language: languageIcon,
	duration: Duration,
}

const ActionContent = ({
	textContent,
	type,
	attr,
	handleClick,
	disableEdit,
	status,
}) => {
	return (
		<InputBar>
			<InfoWrapper>
				<div style={{ width: '20px', height: '25px' }}>
					<Icon width={100} glyph={iconMap[type]} />
				</div>
				<TextSpace>{textContent}</TextSpace>
			</InfoWrapper>
			{!disableEdit && (
				<EditBtn data-attr={attr} onClick={handleClick} status={status}>
					EDIT
				</EditBtn>
			)}
		</InputBar>
	)
}

export default ActionContent
