import * as React from 'react'
import Artists from 'modules/EventManagement/components/Artists'
import AdditionalInfo from 'modules/EventManagement/components/AdditionalInfo'
import VenueSchedule from 'modules/EventManagement/components/VenueSchedule'
import AboutEventDesktop from 'modules/EventManagement/components/AboutEventDesktop'
import EventDescription from 'modules/EventManagement/components/EventDescription'
import EventReasons from 'modules/EventManagement/components/EventReasons'
import EventTNC from 'modules/EventManagement/components/EventTNC'
import EventImages from 'modules/EventManagement/components/EventImages'
import EventInfo from 'modules/EventManagement/components/EventInfo'
import MobileTimeScheduler from 'modules/EventManagement/components/VenueSchedule/components/MobileTimeScheduler'

const synopsisEventSegments = [
	{
		title: 'Event Name & Category',
		mobileTitle: 'Event Name & Category',
		attr: 'event-name-category',
		component: EventInfo,
		dataSlug: 'eventInfo',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'Add Images',
		mobileTitle: 'Image / Poster Upload',
		attr: 'event-images',
		component: EventImages,
		dataSlug: 'banner',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'Schedule',
		attr: 'event-schedule',
		component: VenueSchedule,
		dataSlug: 'venueInfo',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'Time Scheduler',
		attr: 'date-time-scheduler',
		component: MobileTimeScheduler,
		dataSlug: 'dateTimeSchduler',
		display: false,
		desktopSegment: false,
	},
	{
		title: 'Additional Info',
		attr: 'event-additional-info',
		component: AdditionalInfo,
		dataSlug: 'additionalInfo',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'Artist & Crew',
		mobileTitle: 'Artist & Crew',
		attr: 'event-artist-crew',
		component: Artists,
		dataSlug: 'artistInfo',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'About the event',
		mobileTitle: 'About the event',
		attr: 'about-event',
		component: AboutEventDesktop,
		dataSlug: 'about',
		display: true,
		desktopSegment: true,
	},
	{
		title: 'About the event',
		mobileTitle: 'About the event',
		attr: 'event-description',
		component: EventDescription,
		dataSlug: 'synopsis',
		display: false,
		desktopSegment: false,
	},
	{
		title: 'Reasons to attend',
		mobileTitle: 'Reasons to attend',
		attr: 'event-reasons',
		component: EventReasons,
		dataSlug: 'reasonsToAttend',
		display: false,
		desktopSegment: false,
	},
	{
		title: 'Terms & Conditions',
		mobileTitle: 'Terms & Conditions',
		attr: 'event-tnc',
		component: EventTNC,
		dataSlug: 'tnc',
		display: true,
		desktopSegment: true,
	},
]

const addSessionEventSegments = [
	{
		title: 'Schedule',
		attr: 'event-schedule',
		component: VenueSchedule,
		dataSlug: 'venueInfo',
		display: true,
		desktopSegment: true,
	},
]

const AGE_CATEGORIES = [
	{ name: 'All age groups', code: 'All age groups' },
	{ name: '3yrs +', code: '3yrs +' },
	{ name: '5yrs +', code: '5yrs +' },
	{ name: '8yrs +', code: '8yrs +' },
	{ name: '10yrs +', code: '10yrs +' },
	{ name: '12yrs +', code: '12yrs +' },
	{ name: '16yrs +', code: '16yrs +' },
	{ name: '18yrs +', code: '18yrs +' },
	{ name: '21yrs +', code: '21yrs +' },
]

const onlineVenueMap = {
	ONNS: 'liveStreaming',
	WOZM: 'liveStreaming',
	VODO: 'vod',
}

const onlineVenueData = {
	liveStreaming: {
		heading: 'Watch on BookMyShow',
		details: 'Live streaming event completely powered by BookMyShow.',
		isExclusive: true,
		data: [
			`Once the event is published, BMS
			will send a detailed email which
			will have all the details on how you
			can go live on BMS platform.`,
			// eslint-disable-next-line
			<div>
				The streaming details:
				<b>Server URL</b>
				and <b>Stream key</b> will be mailed to the your email ids 15
				mins prior to the showtime.
			</div>,
		],
	},
	zoom: {
		heading: 'Watch on Zoom/Other',
		details:
			'Live streaming event via external online meet platforms like Zoom/other.',
		isExclusive: false,
		showExternalPaidEventsOptions: true,
		data: [
			// eslint-disable-next-line
			<div>
				The event can be streamed live from platforms like Zoom, Google
				Meet, Teams or any other.
			</div>,
			// eslint-disable-next-line
			<div>
				Required event joining credentials are:
				<br />
				1. The link to join the event when the show starts
				<br />
				2. A meeting ID to join in.
				<br />
				3. A password to join the meeting
				<br />
			</div>,
		],
	},
	vod: {
		heading: 'Watch on BookMyShow',
		details:
			'Pre-recorded video streaming event completely powered by BookMyShow.',
		isExclusive: true,
		data: [
			`For this event you will need to add a video that will be streamed at the showtime.`,
			`The video needs to get an approval from BookMyshow.`,
			`The video can be uploaded after publishing the event also at-least 24 hours before the showtime.`,
		],
	},
}

const eventPrerequisitesData = {
	liveStreaming: {
		bottmoDrawerSubHeading: 'Watch on BookMyShow (Live Streaming)',
		venueData: (
			<div>
				The streaming details:{' '}
				<b style={{ color: 'black' }}>Server URL</b> and{' '}
				<b style={{ color: 'black' }}>Stream key</b> will be mailed to
				your below provided email ids 15 mins prior to the showtime.
			</div>
		),
		venueSubHeading: 'Organizer Email List',
		bottmoDrawerHeading: 'Enter Email to continue',
	},
	vod: {
		bottmoDrawerSubHeading: 'Watch on BookMyShow (Live Streaming)',
		venueSubHeading: 'Event Video',
		venueData:
			'Your selected online event type requires a video to be added here. Your online event will stream the uploaded video. You can add the video later after publishing the event also within 24 hours before showtime.',
	},
	zoom: {
		bottmoDrawerSubHeading: 'Watch on Zoom/Other (External Live Streaming)',
		venueData:
			'Your selected online event type requires joining credentials for the audience. The credentials are mandatory for event submission.',
		venueSubHeading: 'Event Joining Credentials',
		bottmoDrawerHeading: 'Enter Meeting link and Password',
	},
}

const EMAILREGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const INPUTREGEX = /^[A-Za-z0-9,.(){}~[\]<>?;:'"!@#$%^&*-=_+\s]*$/

const INPUTERRORMESSAGE = (section) => {
	if (section === 'Email') {
		return 'Please enter valid email id'
	}
	return `Please use only permitted characters in the ${section}. Alphabets, Numerals and specials characters like ! * & $ etc. are permitted. Special symbols like ★ ⅋ © are not permitted`
}

const EVENT_TYPES = {
	LIVE: 'live',
	PAST: 'past',
	LIVE_PAST: 'live-past',
	DRAFT: 'draft',
	CLOSED: 'closed',
}

const SHOW_TYPES = {
	[EVENT_TYPES.LIVE]: 'live',
	[EVENT_TYPES.PAST]: 'past',
	[EVENT_TYPES.CLOSED]: 'live',
}

const EVENT_STATUS = {
	LIVE: 'live',
	PAST: 'past',
	DRAFT: 'draft',
	CLOSED: 'closed',
	ACTIVE: 'active',
	IN_ACTIVE: 'inactive',
	CREATE_EVENT: 'createEvent',
	DRAFT_EVENT_DETAILS: 'draftEventDetails',
	DRAFT_TICKET_DETAILS: 'draftTicketDetails',
	READY_FOR_REVIEW: 'readyForReview',
	REVIEW: 'review',
	ON_HOLD: 'onHold',
	READY_TO_PUBLISH: 'readyToPublish',
	PUBLISHED: 'published',
	PUBLISHED_UPDATE: 'publishedUpdates',
	APPROVED: 'approved',
	UNLISTED: 'unlisted',
}

const EVENT_LISTING_LABELS = {
	LIVE: 'Live',
	PAST: 'Past',
	DRAFT: 'Draft',
	CLOSED: 'Unlisted & Closed',
}

const STATUS_NAMES = {
	active: 'LIVE',
	inactive: 'READY TO PUBLISH',
	draft: 'WIP/DRAFT',
	review: 'UNDER REVIEW',
	onHold: 'ON-HOLD EDIT-REQUIRED',
	noSessions: 'NO ACTIVE SESSIONS',
	closed: 'Closed',
	unlisted: 'Unlisted',
}

const VARIANT_NAME = {
	active: 'ready',
	inactive: 'ready',
	noSessions: 'ready',
	draft: 'wip',
	onHold: 'onHold',
	review: 'wip',
}

const STATUS_LABEL = {
	PUBLISHED: 'Published',
	UNPUBLISHED: 'Un-Published',
	SALES_CLOSED: 'Sales Closed',
	CANCELLED: 'Cancelled',
}

const DIY_REPORTS_READ_ACCESS = 'diy.diyreport.dashboard.r'

export {
	synopsisEventSegments,
	addSessionEventSegments,
	AGE_CATEGORIES,
	onlineVenueMap,
	onlineVenueData,
	eventPrerequisitesData,
	EMAILREGEX,
	INPUTREGEX,
	INPUTERRORMESSAGE,
	DIY_REPORTS_READ_ACCESS,
	EVENT_TYPES,
	SHOW_TYPES,
	EVENT_STATUS,
	EVENT_LISTING_LABELS,
	STATUS_NAMES,
	VARIANT_NAME,
	STATUS_LABEL,
}
