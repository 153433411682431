import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import { privateDraftServiceRequest, privateRequest } from 'utils/request'
import { AppDuc } from 'modules/App/duc'
import EventManagerDuc from './duc'
import { push } from 'connected-react-router'
import { format } from 'date-fns'
import { EVENT_STATUS } from 'modules/EventManagement/helpers/constants'
// helpers
import {
	getSessionInfoDates,
	checkForDuplicateSessions,
} from 'modules/EventManagement/components/VenueTimeScheduler/helper'
import { validateInputFields } from 'modules/EventManagement/helpers'

import {
	INPUTERRORMESSAGE,
	EMAILREGEX,
} from 'modules/EventManagement/helpers/constants'
import { SERVER_ISSUE, SUCCESS, ERROR, IN_PROGRESS } from 'constants.js'
import {
	convertISTToLocalTimeZone,
	convertLocalTimeZoneToIST,
} from 'utils/helpers'

const venueMap = {
	ONNS: 'liveStreaming',
	VODO: 'vod',
	WOZM: 'externalLiveStreaming',
}

export function* getEventsList({ params, onPaginationChange }) {
	try {
		if (!onPaginationChange) {
			yield put(EventManagerDuc.creators.getEventsListProgress())
		}
		const { data } = yield call(() =>
			privateRequest.get(`manage/event`, { params })
		)

		const eventsList = yield select(EventManagerDuc.selectors.eventsList) ||
			{}
		if (onPaginationChange) {
			yield put(
				EventManagerDuc.creators.getEventsListSuccess({
					...eventsList,
					list: [
						...(eventsList?.list || []),
						...(data?.data?.list || []),
					],
					hideLoader: data?.data?.list.length < 10,
				})
			)
		} else {
			yield put(
				EventManagerDuc.creators.getEventsListSuccess({
					...data.data,
					hideLoader: data?.data?.list.length < 10,
				})
			)
		}
	} catch (error) {
		const { response } = error
		yield call(handleError, response)
	}
}

export function* getEventCategories({ searchParam }) {
	try {
		const searchQuery = searchParam
			? `?searchKey=name&searchValue=${searchParam}&limit=100&offset=0&fetchChildren=true`
			: '?limit=100&offset=0&fetchChildren=true'
		const { data } = yield call(() =>
			privateRequest.get(`/events/masters/categories${searchQuery}`)
		)

		const { list } = data.data
		yield put(EventManagerDuc.creators.setEventCategories({ list }))
	} catch (error) {
		const { response } = error
		yield call(handleError, response)
	}
}

export function* getEventGenres({
	eventType = '',
	category = '',
	subCategories = [],
}) {
	try {
		const filters = [eventType, category, ...subCategories]
			.filter(Boolean)
			.join('|')

		const config = filters
			? {
					params: {
						categories: filters,
					},
			  }
			: {}

		const { data } = yield call(() =>
			privateRequest.get(`/events/masters/genre`, config)
		)

		yield put(EventManagerDuc.creators.setEventGenres({ list: data.data }))
	} catch (error) {
		const { response } = error
		yield call(handleError, response)
	}
}

export function* getEventSynopsis({ params, showSaveButton = false }) {
	try {
		yield put(EventManagerDuc.creators.toggleLoader())
		const { data } = yield call(() =>
			privateRequest.get(`/manage/event/synopsis`, { params })
		)
		if (data.status === 200) {
			let { data: currentData } = data
			// convert server's showtime to local timezone
			// this is done to handle the case when user is in some other timezone
			currentData = {
				...currentData,
				updatedAt: format(new Date(), 'hh:mm a'),
				venueInfo: currentData.venueInfo?.map((venue) => ({
					...venue,
					sessionInfo: venue.sessionInfo?.map((session) => ({
						...session,
						showTime: convertISTToLocalTimeZone(session.showTime),
					})),
				})),
			}

			const { snackbar, reasonsToAttend } = currentData
			const { title, content, class: type } = snackbar

			if (!reasonsToAttend) {
				currentData.reasonsToAttend = ['', '', '']
			}

			yield put(
				EventManagerDuc.creators.setEventNotification(
					true,
					true,
					title,
					content,
					type
				)
			)
			yield put(EventManagerDuc.creators.getSynopsisSuccess(currentData))
			yield put(
				EventManagerDuc.creators.updateShowSaveButton(showSaveButton)
			)
		}
		yield put(EventManagerDuc.creators.toggleLoader())
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleLoader())
		const { response } = error
		if (
			response?.data?.errors?.code === 'ERR.EVENTCREATION.UNKNOWN' ||
			response?.data?.errors?.code ===
				'ERR.EVENTCREATION.INVALID_REFERENCEID'
		) {
			yield put(push(`/events/live`))
		} else {
			yield call(handleError, response)
		}
	}
}

export function* getMastersData({
	query: { component, searchValue, venueType },
}) {
	try {
		let params
		if (searchValue) {
			params = { searchKey: 'name', searchValue }
		}
		if (venueType) {
			params = {
				venueType,
			}
		}

		const { data } = yield call(() =>
			privateRequest.get(`/events/masters/${component}`, { params })
		)
		yield put(
			EventManagerDuc.creators.getMastersDataSuccess(
				component,
				data.data[component]
			)
		)
	} catch (e) {
		const { response } = e
		yield call(handleError, response)
	}
}

export function* getApprovedTicketTypes({ search }) {
	try {
		let params
		if (search) {
			params = { search }
		}

		const { data } = yield call(() =>
			privateDraftServiceRequest.get('/tickettypes/approved', { params })
		)

		yield put(
			EventManagerDuc.creators.getApprovedTicketTypesSuccess(
				search,
				data.data
			)
		)
	} catch (e) {
		const { response } = e
		yield call(handleError, response)
	}
}

export function* createNewTicketType({ ticketTypeName }) {
	try {
		yield put(EventManagerDuc.creators.toggleButtonLoading(true))
		const requestBody = {
			ticketTypeName,
		}
		const { data } = yield call(() =>
			privateDraftServiceRequest.post('/tickettypes', requestBody)
		)
		const { status } = data

		if (status === 200) {
			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					'Ticket name is sent for approval'
				)
			)
		}
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
	} catch (e) {
		const { response } = e

		if (response.data) {
			const { errors } = response.data
			yield put(
				EventManagerDuc.creators.updateCreateTicketTypeError(
					errors.message
				)
			)
		} else {
			yield call(handleError, response)
		}
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
	}
}

export function* createEvent({ eventInfo }) {
	try {
		yield put(EventManagerDuc.creators.toggleLoader())
		yield put(EventManagerDuc.creators.toggleButtonLoading(true))

		const requestBody = {
			eventInfo,
		}
		const response = yield call(() =>
			privateRequest.post(`/manage/event`, requestBody)
		)
		const { status } = response
		if (status === 200) {
			const eventData = response.data
			const { draftId, snackbar } = eventData.data
			const { title, content, class: type } = snackbar
			yield put(
				EventManagerDuc.creators.setEventNotification(
					true,
					true,
					title,
					content,
					type
				)
			)

			yield put(
				EventManagerDuc.creators.eventCreationSuccess(eventData.data)
			)
			yield put(EventManagerDuc.creators.toggleButtonLoading(false))
			yield put(EventManagerDuc.creators.toggleLoader())
			yield put(push(`/events/configure/${draftId}`))
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleLoader())
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const { response } = error
		yield call(handleError, response)
	}
}
function validateInput(section, value) {
	if (value) {
		if (section === 'synopsis') {
			const input = value.replace(/(<([^>]+)>)/gi, '')
			return validateInputFields(section, input)
		} else if (section === 'tnc') {
			const input = value.replace(/(<([^>]+)>)/gi, '')
			return validateInputFields(section, input)
		} else if (section === 'reasonsToAttend') {
			const input = value.filter((reasons) => reasons !== '')
			let isValidInput = true
			for (let i = 0; i < input.length; i++) {
				if (!validateInputFields(section, input[i])) {
					isValidInput = false
					break
				}
			}
			return isValidInput
		} else {
			return true
		}
	} else {
		return true
	}
}

function validateMailIds(mailIds = []) {
	const validMailIds = mailIds.filter((mail) => EMAILREGEX.test(mail))
	return validMailIds.length === mailIds.length
}

// fixTicketTypeName ...
function fixTicketTypeName(eventBody) {
	const event = JSON.parse(JSON.stringify(eventBody))
	event.venueInfo?.forEach((venue) =>
		venue.sessionInfo?.forEach((session) =>
			session.ticketInfo?.forEach((ticket) => {
				if (
					typeof ticket.ticketTypeName === 'object' &&
					ticket.ticketTypeName.ticketTypeName
				) {
					ticket.ticketTypeName = ticket.ticketTypeName.ticketTypeName
				}
			})
		)
	)
	return event
}

function convertVenueInfoToIST(venueInfo) {
	// convert local timezone to server's showtime (IST)
	// this is done to handle the case when user is in some other timezone
	return venueInfo.map((venue) => ({
		...venue,
		sessionInfo: venue.sessionInfo?.map((session) => ({
			...session,
			showTime: convertLocalTimeZoneToIST(session.showTime),
		})),
	}))
}

export function* updateEventData({
	draftId,
	eventCode,
	section,
	sectionInfo,
	redirectTo,
}) {
	try {
		if (section === 'venueInfo') {
			sectionInfo = convertVenueInfoToIST(sectionInfo)
		}
		let isInputValid = yield call(() => validateInput(section, sectionInfo))

		if (section === 'emailIds') {
			isInputValid = validateMailIds(sectionInfo)
		}

		let customPathForRedirection = ''
		if (redirectTo?.indexOf('customRedirection=') > -1) {
			customPathForRedirection =
				redirectTo?.split('customRedirection=')[1]
			redirectTo = 'customRedirection'
		}

		if (isInputValid) {
			yield put(EventManagerDuc.creators.toggleButtonLoading(true))
			const activeEvent = yield select(
				EventManagerDuc.selectors.activeEvent
			)

			if (
				eventCode &&
				(activeEvent.status === 'active' ||
					activeEvent.status === 'inactive')
			) {
				if (section === 'venueInfo') {
					activeEvent.venueInfo = sectionInfo
				}
				const body = fixTicketTypeName(activeEvent)
				let { data } = yield call(() =>
					privateRequest.post(`/manage/event`, body)
				)
				const { status } = data
				if (status === 200) {
					yield put(EventManagerDuc.creators.updateRecurringInfo({}))

					//Set the recived draft if and data in store(active event)
					data.data.snackbar.class = '' // remove status to not show submit for review button before refresh
					const { snackbar } = data.data
					const { title, content, class: type } = snackbar
					yield put(
						EventManagerDuc.creators.setEventNotification(
							true,
							true,
							title,
							content,
							type
						)
					)

					let path = (
						redirectTo === 'customRedirection'
							? customPathForRedirection
							: window.location.pathname
					).replace(activeEvent?.eventCode, data?.data?.draftId)
					path += window.location.search
						? window.location.search + '&'
						: '?'
					path += 'eventCode=' + activeEvent?.eventCode
					switch (redirectTo) {
						case 'addSessionMob':
							yield call(() =>
								getEventSynopsis({
									params: {
										draftId: data?.data?.draftId,
										eventCode: activeEvent?.eventCode,
									},
								})
							)
							yield put(push(path))
							break
						case 'synopsis':
							window.location.replace(
								`/events/configure/${data?.data?.draftId}?eventCode=${activeEvent?.eventCode}`
							)
							break
						case 'customRedirection':
							window.location.replace(path)
							break
						default:
							yield put(
								EventManagerDuc.creators.getSynopsisSuccess(
									data.data
								)
							)
							window.location.replace(path)
					}
				}
			} else {
				let requestBody = {
					[section]: sectionInfo,
				}
				yield put(EventManagerDuc.creators.updateRecurringInfo({}))

				if (eventCode) {
					requestBody = {
						...requestBody,
						eventCode: eventCode,
					}
				}
				// Add all section specific additional params required by LE-API here
				switch (section) {
					case 'venueInfo':
						requestBody['city'] =
							activeEvent.city ||
							sectionInfo?.[0]?.region ||
							'mumbai'
						requestBody['regionCode'] =
							activeEvent?.regionCode ||
							sectionInfo?.[0]?.regionCode ||
							'MUMBAI'
						requestBody['venueType'] =
							activeEvent?.venueType || 'onGround'
						requestBody['venueSubType'] =
							activeEvent?.venueType === 'online'
								? activeEvent?.venueSubType ||
								  venueMap[sectionInfo?.[0]?.venueCode]
								: ''
						requestBody['emailIds'] = activeEvent?.emailIds
						requestBody['joiningDetails'] = {
							...activeEvent?.joiningDetails,
							link:
								activeEvent?.joiningDetails?.link || 'https://',
						}
						// convert ticketInfo to string if it is object
						requestBody = fixTicketTypeName(requestBody)
						break
					default:
						break
				}
				const { data } = yield call(() =>
					privateRequest.patch(
						`/manage/event/${draftId}`,
						requestBody
					)
				)
				const { status } = data
				if (status === 200) {
					//Snack bar updated
					const { snackbar, draftId, rejectionReasons, tnc } =
						data.data
					const { title, content, class: type } = snackbar
					if (section === 'eventInfo') {
						yield put(push(`/events/configure/${draftId}`))
					} else {
						yield call(() =>
							getEventSynopsis({ params: { draftId, eventCode } })
						)
					}
					if (redirectTo === 'synopsis') {
						window.location.replace(
							`/events/configure/${data?.data?.draftId}?eventCode=${activeEvent?.eventCode}`
						)
					} else if (redirectTo === 'customRedirection') {
						window.location.replace(customPathForRedirection)
					}

					yield put(
						EventManagerDuc.creators.updateRejectionReasons(
							rejectionReasons || []
						)
					)
					yield put(
						EventManagerDuc.creators.updateSynopsis('tnc', tnc)
					)
					/**
					 * TODO: Storing timestamp in the store to know the last tnc update.
					 * We are also update the store on user edit content in the editor, which will
					 * end up with rerendering the editor hence loosing focus from text box.
					 * This fix will solve the problem but need to reengeer this part for better fix.
					 */
					yield put(
						EventManagerDuc.creators.updateSynopsis(
							'tncRefreshedOn',
							new Date().getTime()
						)
					)
					yield put(
						EventManagerDuc.creators.setEventNotification(
							true,
							true,
							title,
							content,
							type
						)
					)
				}
			}
			yield put(EventManagerDuc.creators.updateShowSaveButton(false))
			yield put(EventManagerDuc.creators.toggleButtonLoading(false))
			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					'Changes have been saved.'
				)
			)
		} else {
			if (section === 'emailIds') {
				yield call(() => handleInputValidationsPopup('Email'))
			} else {
				yield call(() => handleInputValidationsPopup(section))
			}
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.updateShowSaveButton(true))
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)

		const { response } = error
		// const idType = eventCode ? 'eventCode' : 'draftId'
		let queryParams = {}
		if (activeEvent?.eventCode) {
			queryParams = {
				...queryParams,
				eventCode: activeEvent.eventCode,
			}
		}
		if (activeEvent?.draftId) {
			queryParams = {
				...queryParams,
				draftId: activeEvent.draftId,
			}
		}
		if (response?.data?.errors?.code === 'ERR.EVENTCREATION.SALE_STARTED') {
			yield call(() =>
				getEventSynopsis({
					params: {
						...queryParams,
					},
				})
			)
		}
		yield call(handleError, response)
	}
}

export function* updatePublishedSessions({
	draftId,
	eventCode,
	section,
	sectionInfo,
}) {
	try {
		if (section === 'venueInfo') {
			sectionInfo = convertVenueInfoToIST(sectionInfo)
		}
		let isInputValid = yield call(() => validateInput(section, sectionInfo))

		if (isInputValid) {
			yield put(EventManagerDuc.creators.toggleButtonLoading(true))
			const activeEvent = yield select(
				EventManagerDuc.selectors.activeEvent
			)

			let requestBody = {
				[section]: sectionInfo,
				eventCode: eventCode,
				draftId,
				venueType: activeEvent?.venueType || 'onGround',
			}
			yield put(EventManagerDuc.creators.updateRecurringInfo({}))

			const { data } = yield call(() =>
				privateRequest.post(
					'/manage/event/published/sessions',
					fixTicketTypeName(requestBody)
				)
			)
			const { status } = data
			if (status === 200) {
				//Snack bar updated
				let { snackbar, rejectionReasons, sessionAudit } = data.data
				const { title, content, class: type } = snackbar
				// convert server's showtime to local timezone
				// this is done to handle the case when user is in some other timezone
				sessionAudit = sessionAudit?.map((audit) => ({
					...audit,
					session: {
						...audit.session,
						session: {
							...audit.session.session,
							showtime: convertISTToLocalTimeZone(
								audit.session.session.showtime
							),
						},
					},
				}))
				yield put(
					EventManagerDuc.creators.updateRejectionReasons(
						rejectionReasons || []
					)
				)
				yield put(
					EventManagerDuc.creators.setEventNotification(
						true,
						true,
						title,
						content,
						type
					)
				)

				yield put(
					EventManagerDuc.creators.setPartialFailure({
						title: 'Changes are successfully updated',
						message: '',
						note: 'Note: It may take up to 5 mins to reflect this event under live/past section',
						showTimes: sessionAudit?.map(({ session }) => ({
							showTime: session.session.showtime,
							message: session.operations.map(
								({ message }) => message
							),
						})),
						showViewDetails: !!sessionAudit,
						onOk: function () {
							window.location.href = '/events/live'
						},
					})
				)
			}
			yield put(EventManagerDuc.creators.updateShowSaveButton(false))
			yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		} else {
			yield call(() => handleInputValidationsPopup(section))
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.updateShowSaveButton(false))
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)

		const { response } = error
		// const idType = eventCode ? 'eventCode' : 'draftId'
		let queryParams = {}
		if (activeEvent?.eventCode) {
			queryParams = {
				...queryParams,
				eventCode: activeEvent.eventCode,
			}
		}
		if (activeEvent?.draftId) {
			queryParams = {
				...queryParams,
				draftId: activeEvent.draftId,
			}
		}
		if (response?.data?.errors?.code === 'ERR.EVENTCREATION.SALE_STARTED') {
			yield call(() =>
				getEventSynopsis({
					params: {
						...queryParams,
					},
				})
			)
		}
		yield call(handleError, response)
	}
}

export function* updateEventStatus({ eventId, status }) {
	const requestBody = {
		status,
	}
	try {
		yield put(EventManagerDuc.creators.toggleButtonLoading(true))
		const { data } = yield call(() =>
			privateRequest.put(`/manage/event/${eventId}`, requestBody)
		)
		const { status } = data
		if (status === 200) {
			const { snackbar } = data.data
			const { title, content, class: type, toastMsg } = snackbar
			yield put(
				EventManagerDuc.creators.setEventNotification(
					true,
					true,
					title,
					content,
					type
				)
			)

			yield put(EventManagerDuc.creators.toggleButtonLoading(false))

			if (requestBody?.status === EVENT_STATUS.REVIEW) {
				yield put(push('/events/draft'))
			} else {
				yield call(() =>
					getEventSynopsis({ params: { eventCode: eventId } })
				)
			}

			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					toastMsg || 'Successfully updated the event status',
					false,
					5000
				)
			)
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const { response } = error
		yield call(handleError, response)
	}
}

export function* updateEventImages() {
	try {
		yield put(EventManagerDuc.creators.toggleButtonLoading(true))
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)
		const { draftId, eventCode, banner } = activeEvent

		yield put(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				'banner',
				banner
			)
		)
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		yield put(
			AppDuc.creators.showToast(
				'success',
				true,
				'Changes have been saved.'
			)
		)
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const { response } = error
		yield call(handleError, response)
	}
}

export function* updateAllEventSections({ slug }) {
	try {
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)

		const isSynopsisValid = yield call(() =>
			validateInput('synopsis', activeEvent?.synopsis || '')
		)
		const isTNCValid = yield call(() =>
			validateInput('tnc', activeEvent?.tnc || '')
		)
		const isReasonsToAttendValid = yield call(() =>
			validateInput('reasonsToAttend', activeEvent?.reasonsToAttend || '')
		)

		const emailIds = activeEvent?.emailIds?.filter((mail) => !!mail)
		const isMailIdsValid = yield call(() => validateMailIds(emailIds))
		if (
			isSynopsisValid &&
			isTNCValid &&
			isReasonsToAttendValid &&
			isMailIdsValid
		) {
			yield put(EventManagerDuc.creators.toggleButtonLoading(true))
			const recurringInfo = activeEvent?.recurringInfo || {}
			const selectedVenues = activeEvent?.venueInfo || []
			let { venues = [] } = getSessionInfoDates(
				selectedVenues,
				recurringInfo
			)
			venues = convertVenueInfoToIST(venues)

			if (
				activeEvent.eventCode &&
				(activeEvent.status === 'active' ||
					activeEvent.status === 'inactive')
			) {
				if (!checkForDuplicateSessions(venues)) {
					const body = {
						...activeEvent,
						emailIds,
						venueInfo: venues,
					}
					yield put(
						EventManagerDuc.creators.updateSynopsis(
							'venueInfo',
							venues
						)
					)
					yield put(EventManagerDuc.creators.updateRecurringInfo({}))
					const { data } = yield call(() =>
						privateRequest.post(`/manage/event`, body)
					)
					const { status } = data
					if (status === 200) {
						//Set the recived draft if and data in store(active event)
						const { snackbar } = data.data
						const { title, content, class: type } = snackbar
						yield put(
							EventManagerDuc.creators.setEventNotification(
								true,
								true,
								title,
								content,
								type
							)
						)
						yield put(
							EventManagerDuc.creators.getSynopsisSuccess(
								data.data
							)
						)
						let path = `/events/configure/${data?.data?.draftId}`
						if (slug) {
							path += `/edit/${slug}`
						}
						if (activeEvent?.eventCode) {
							path += `?eventCode=${activeEvent?.eventCode}`
						}
						window.location.replace(path)
					}
				} else {
					yield put(
						EventManagerDuc.creators.toggleButtonLoading(false)
					)
					yield put(
						AppDuc.creators.showPopup({
							title: 'Duplicate Show time',
							content:
								'There is already another show at the same date and time',
							nonCloseable: true,
							verticalPosition: 'middle',
							buttons: [
								{
									text: 'Okay',
									action: (dispatch) => {
										dispatch(AppDuc.creators.hidePopup())
									},
								},
							],
						})
					)
				}
			} else {
				if (!checkForDuplicateSessions(venues)) {
					yield put(EventManagerDuc.creators.updateRecurringInfo({}))

					yield put(
						EventManagerDuc.creators.updateSynopsis(
							'venueInfo',
							venues
						)
					)
					let body = {
						...activeEvent,
						venueInfo: venues,
						city:
							activeEvent.city ||
							selectedVenues?.[0]?.region ||
							'mumbai',
						regionCode:
							activeEvent?.regionCode ||
							selectedVenues?.[0]?.regionCode ||
							'MUMBAI',
						venueType: activeEvent?.venueType || 'onGround',
						emailIds,
					}

					if (!activeEvent?.additionalInfo?.duration) {
						delete body?.additionalInfo
					}

					const { data } = yield call(() =>
						privateRequest.patch(
							`/manage/event/${activeEvent.draftId}`,
							body
						)
					)
					const { status } = data
					if (status === 200) {
						//Snack bar updated
						const { snackbar, rejectionReasons, tnc } = data.data
						const { title, content, class: type } = snackbar
						yield put(
							EventManagerDuc.creators.updateRejectionReasons(
								rejectionReasons || []
							)
						)
						yield put(
							EventManagerDuc.creators.setEventNotification(
								true,
								true,
								title,
								content,
								type
							)
						)
						yield put(
							EventManagerDuc.creators.updateSynopsis('tnc', tnc)
						)
						yield put(
							EventManagerDuc.creators.updateSynopsis(
								'tncRefreshedOn',
								new Date().getTime()
							)
						)

						let path = `/events/configure/${data?.data?.draftId}`
						if (slug) {
							path += `/edit/${slug}`
						}
						if (activeEvent?.eventCode) {
							path += `?eventCode=${activeEvent?.eventCode}`
						}
						window.location.replace(path)
					}

					yield put(
						EventManagerDuc.creators.updateShowSaveButton(false)
					)
					yield put(
						EventManagerDuc.creators.toggleButtonLoading(false)
					)
					yield put(
						AppDuc.creators.showToast(
							'success',
							true,
							'Changes have been saved.'
						)
					)
				} else {
					yield put(
						EventManagerDuc.creators.toggleButtonLoading(false)
					)
					yield put(
						AppDuc.creators.showPopup({
							title: 'Duplicate Show time',
							content:
								'There is already another show at the same date and time',
							nonCloseable: true,
							verticalPosition: 'middle',
							buttons: [
								{
									text: 'Okay',
									action: (dispatch) => {
										dispatch(AppDuc.creators.hidePopup())
									},
								},
							],
						})
					)
				}
			}
		} else {
			let invalidSection = !isSynopsisValid
				? 'Synopsis'
				: !isTNCValid
				? 'Terms and Conditions'
				: !isReasonsToAttendValid
				? 'Reasons to Attend'
				: !isMailIdsValid
				? 'Email'
				: ''

			yield call(() => handleInputValidationsPopup(invalidSection))
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.updateShowSaveButton(true))
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)
		const { response } = error
		// const idType = activeEvent.eventCode ? 'eventCode' : 'draftId'
		let queryParams = {}
		if (activeEvent?.eventCode) {
			queryParams = {
				...queryParams,
				eventCode: activeEvent.eventCode,
			}
		}
		if (activeEvent?.draftId) {
			queryParams = {
				...queryParams,
				draftId: activeEvent.draftId,
			}
		}
		if (response?.data?.errors?.code === 'ERR.EVENTCREATION.SALE_STARTED') {
			yield call(() =>
				getEventSynopsis({
					params: {
						...queryParams,
					},
				})
			)
		}
		yield call(handleError, response)
	}
}

export function* handleInputValidationsPopup(section) {
	yield put(
		AppDuc.creators.showPopup({
			title: `Invalid ${section}`,
			content: INPUTERRORMESSAGE(section),
			nonCloseable: true,
			verticalPosition: 'middle',
			buttons: [
				{
					text: 'Okay',
					action: (dispatch) => {
						dispatch(AppDuc.creators.hidePopup())
					},
				},
			],
		})
	)
}

export function* getBannerImageUrl({ bannerType, image }) {
	try {
		yield put(EventManagerDuc.creators.toggleButtonLoading(true))
		const activeEvent = yield select(EventManagerDuc.selectors.activeEvent)
		const {
			eventCode,
			eventInfo: { eventTitle },
		} = activeEvent
		const formData = new FormData()
		if (eventCode) {
			formData.append('eventCode', eventCode)
		}
		formData.append('eventTitle', eventTitle)
		formData.append('file', image, `${bannerType}.jpeg`)

		const response = yield call(() =>
			privateRequest.post(
				`/manage/event/upload/banner/${bannerType}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
		)

		const { status, data } = response

		if (status === 200) {
			const { banner = { desktop: [], webListing: [] } } = activeEvent
			let updatedBanner = {}
			if (bannerType === 'desktop') {
				const { desktop = {}, mobile = {} } = data?.data || {}
				updatedBanner = {
					...banner,
					desktop: [
						{
							...desktop,
							caption: eventTitle,
							type: 'image',
						},
						...(banner.desktop || []).slice(1),
					],
					mobile: [
						{
							...mobile,
							caption: eventTitle,
							type: 'image',
						},
						...(banner.mobile || []).slice(1),
					],
				}
			} else if (bannerType === 'webListing') {
				const { webListing } = data?.data || {}
				updatedBanner = {
					...banner,
					[bannerType]: [
						{ ...webListing, caption: eventTitle, type: 'image' },
						...(banner[bannerType] || []).slice(1),
					],
				}
			}

			yield put(
				EventManagerDuc.creators.updateSynopsis('banner', updatedBanner)
			)
			yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		const { response } = error
		yield call(handleError, response)
	}
}

export function* removeDraftEvent({ getEventsParams = [], ...payload }) {
	try {
		const deletedDraftData = yield select(
			EventManagerDuc.selectors.deletedDraftData
		)
		let requestBody = { ...payload }
		const { data } = yield call(() =>
			privateRequest.delete('/delete/draft', {
				data: deletedDraftData,
			})
		)
		const { status } = data
		if (status === 200) {
			yield put(EventManagerDuc.creators.deleteDraft(payload))
			if (getEventsParams.length) {
				yield put(
					EventManagerDuc.creators.getEventsList(...getEventsParams)
				)
			}
			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					`${requestBody.payload.eventName} deleted`
				)
			)
		}
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleButtonLoading(false))
		yield put(EventManagerDuc.creators.deleteDraft(payload))
		const { response } = error
		yield call(handleError, response)
	}
}
export function* handleError(response = {}, title) {
	yield put(EventManagerDuc.creators.resetLoader())
	const responseData = response.data || {
		errors: { message: 'Something went wrong. Please try again.' },
	}
	const { errors } = responseData
	if (errors.code === 'ERR.SESSION.EXPIRED') {
		yield put(
			EventManagerDuc.creators.setPartialFailure({
				title: `Failed to update the changes`,
				message: errors.message,
				showTimes: errors.showTimes,
				showViewDetails: false,
				onOk: 'reload',
			})
		)
	} else {
		yield put(
			AppDuc.creators.showPopup({
				title: title || 'Oops',
				content: errors.message,
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	}
}

export function* getEventLevelReports(payload) {
	try {
		const {
			params: { eventCode, showsType },
		} = payload

		yield put(EventManagerDuc.creators.updateGetReportsStatus(IN_PROGRESS))

		const {
			data: { data },
			status,
		} = yield call(() =>
			privateRequest.get(
				`/reports/event-wise/v1/event/${eventCode}/${showsType}`
			)
		)

		yield put(EventManagerDuc.creators.getEventLevelReportsSuccess(data))
		yield put(
			EventManagerDuc.creators.updateGetReportsStatus(
				status === 500 ? SERVER_ISSUE : SUCCESS
			)
		)
	} catch (error) {
		yield put(EventManagerDuc.creators.updateGetReportsStatus(ERROR))
	}
}

export function* getSessionLevelReports(payload) {
	try {
		const {
			params: { venueCode, eventCode, sessionId },
		} = payload

		yield put(EventManagerDuc.creators.updateGetReportsStatus(IN_PROGRESS))

		const {
			data: { data },
			status,
		} = yield call(() =>
			privateRequest.get(
				`/reports/session-wise/v1/event/${eventCode}/session/${sessionId}?vnuCode=${venueCode}`
			)
		)

		yield put(EventManagerDuc.creators.getSessionLevelReportsSuccess(data))
		yield put(
			EventManagerDuc.creators.updateGetReportsStatus(
				status === 500 ? SERVER_ISSUE : SUCCESS
			)
		)
	} catch (error) {
		yield put(EventManagerDuc.creators.updateGetReportsStatus(ERROR))
	}
}

export function* getBookingId(payload) {
	try {
		const {
			params: { venueCode, eventCode, sessionId, key },
		} = payload
		const {
			data: { data },
		} = yield call(() =>
			privateRequest.get(
				`/reports/search-by-bookingId/v1/venue/${venueCode}/event/${eventCode}/session/${sessionId}?bookingId=${key}`
			)
		)
		yield put(EventManagerDuc.creators.getBookingIdSuccess(data))
	} catch (error) {
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				'Error while searching for bookingId'
			)
		)
	}
}

export function* downloadSalesData(payload) {
	try {
		const {
			data: { venueCode, eventCode, sessionId, format, sessionTime },
		} = payload
		const fileName = `${eventCode} ${sessionTime}`

		yield put(
			EventManagerDuc.creators.updateSalesDataDownloadStatus(IN_PROGRESS)
		)

		const response = yield call(() =>
			privateRequest.get(
				`/reports/download/v1/venue/${venueCode}/event/${eventCode}/session/${sessionId}/format/${format}`,
				{ responseType: 'blob' }
			)
		)
		const { data } = response

		if (data) {
			const downloadLink = document.createElement('a')
			downloadLink.setAttribute('download', fileName)
			downloadLink.href = window.URL.createObjectURL(data)
			downloadLink.style.display = 'none'
			document.body.appendChild(downloadLink)
			downloadLink.click()

			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					'Report Downloaded Successfully'
				)
			)
			yield put(
				EventManagerDuc.creators.updateSalesDataDownloadStatus(SUCCESS)
			)
		} else {
			throw new Error()
		}
	} catch (error) {
		console.error(error)
		yield put(EventManagerDuc.creators.updateSalesDataDownloadStatus(ERROR))
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				'Error while downloading the report'
			)
		)
	}
}

export function* emailSalesData(payload) {
	try {
		const {
			data: { venueCode, eventCode, sessionId, format },
		} = payload

		yield put(
			EventManagerDuc.creators.updateEmailSalesDataStatus(IN_PROGRESS)
		)

		yield call(() =>
			privateRequest.get(
				`/reports/email/v1/venue/${venueCode}/event/${eventCode}/session/${sessionId}/format/${format}`
			)
		)
		yield put(EventManagerDuc.creators.updateEmailSalesDataStatus(SUCCESS))
		yield put(
			AppDuc.creators.showToast(
				'success',
				true,
				'Report Sent Successfully'
			)
		)
	} catch (error) {
		console.error(error)
		yield put(EventManagerDuc.creators.updateEmailSalesDataStatus(ERROR))
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				'Error while emailing the report'
			)
		)
	}
}

export function* getMultiSessionFlag(payload) {
	try {
		const {
			data: { eventCode = '' },
		} = payload

		if (eventCode) {
			const {
				data: { data },
			} = yield call(() =>
				privateRequest.get(
					`/reports/v1/get-multi-session-flag/event/${eventCode}`
				)
			)
			yield put(EventManagerDuc.creators.getMultiSessionFlagSuccess(data))
		}
	} catch (error) {
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				'Error while fetching multi session flag'
			)
		)
	}
}

export function* publishInventoryChange({
	etCode,
	vnuCode,
	sessions,
	ticketTypeName,
	totalInventory,
	callType,
}) {
	try {
		yield put(EventManagerDuc.creators.toggleLoader())
		let requestBody = {
			etCode,
			vnuCode,
			sessions,
			ticketTypeName,
			totalInventory,
			callType,
		}

		const { data } = yield call(() =>
			privateRequest.post(
				'v1/manage/event/session/update-inventory',
				requestBody
			)
		)

		yield put(
			AppDuc.creators.showToast('success', true, data.data, false, 7000)
		)
		yield put(EventManagerDuc.creators.toggleLoader())
		yield call(() => getEventSynopsis({ params: { eventCode: etCode } }))
	} catch (error) {
		yield put(EventManagerDuc.creators.toggleLoader())
		const { response } = error
		yield call(handleError, response, 'Edit ticket quantity')
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(
			EventManagerDuc.types.GET_EVENT_CATEGORIES,
			getEventCategories
		),
		takeLatest(EventManagerDuc.types.GET_EVENT_GENRES, getEventGenres),
		takeLatest(EventManagerDuc.types.GET_EVENTS_LISTING, getEventsList),
		takeLatest(EventManagerDuc.types.GET_SYNOPSIS, getEventSynopsis),
		takeLatest(EventManagerDuc.types.GET_MASTERS_DATA, getMastersData),
		takeLatest(
			EventManagerDuc.types.GET_APPROVED_TICKET_TYPES,
			getApprovedTicketTypes
		),
		takeLatest(
			EventManagerDuc.types.CREATE_NEW_TICKET_TYPE,
			createNewTicketType
		),
		takeLatest(EventManagerDuc.types.CREATE_EVENT, createEvent),
		takeLatest(EventManagerDuc.types.UPDATE_EVENT_DATA, updateEventData),
		takeLatest(
			EventManagerDuc.types.UPDATE_PUBLISHED_SESSIONS,
			updatePublishedSessions
		),
		takeLatest(
			EventManagerDuc.types.UPDATE_EVENT_STATUS,
			updateEventStatus
		),
		takeLatest(
			EventManagerDuc.types.GET_BANNER_IMAGE_URL,
			getBannerImageUrl
		),
		takeLatest(
			EventManagerDuc.types.UPDATE_EVENT_IMAGES,
			updateEventImages
		),
		takeLatest(
			EventManagerDuc.types.UPDATE_ALL_EVENT_SECTIONS,
			updateAllEventSections
		),
		takeLatest(
			EventManagerDuc.types.DELETE_DRAFT_SUCCESS,
			removeDraftEvent
		),
		takeLatest(
			EventManagerDuc.types.GET_EVENT_LEVEL_REPORTS,
			getEventLevelReports
		),
		takeLatest(
			EventManagerDuc.types.GET_SESSION_LEVEL_REPORTS,
			getSessionLevelReports
		),
		takeLatest(EventManagerDuc.types.GET_BOOKING_ID, getBookingId),
		takeLatest(
			EventManagerDuc.types.DOWNLOAD_SALES_DATA,
			downloadSalesData
		),
		takeLatest(EventManagerDuc.types.SEND_SALES_DATA_EMAIL, emailSalesData),
		takeLatest(
			EventManagerDuc.types.GET_MULTI_SESSION_FLAG,
			getMultiSessionFlag
		),
		takeLatest(
			EventManagerDuc.types.PUBLISH_INVENTORY_CHANGE,
			publishInventoryChange
		),
	])
}
