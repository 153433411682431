import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

// duc
import EventManagerDuc from 'modules/EventManagement/duc'
import { AppDuc } from 'modules/App/duc'

// components
import ImageCropper from 'common/components/ImageCropper'
import EventImage from './EventImage'
import Modal from 'common/components/Modal'
import Lightbox from 'react-image-lightbox'
import Button from 'bms-le-components/atoms/Button'

// styles
import 'react-image-lightbox/style.css'
import { ButtonWrapper } from './__style'

// helpers
import {
	MAX_HEADER_IMAGE_SIZE,
	MAX_VERTICAL_POSTER_SIZE,
	headerImageGuide,
	verticalPosterGuide,
	IMAGE_TYPE,
	generatePopUp,
} from './helper'
import ErrorAlert from 'common/components/ErrorAlert'
import InfoAlertBar from 'common/components/InfoAlertBar'

function EventImages({
	isDesktop,
	activeEvent,
	getBannerImageUrl,
	updateEventImages,
	buttonLoading,
	eventNotification,
	bannerRejectionReasons,
	showPopup,
	hidePopup,
	showSaveButton,
	analyticsPush,
}) {
	const { section } = useParams()
	const history = useHistory()

	const { banner = {} } = activeEvent
	const { desktop = [], webListing = [] } = banner
	/** Temp state for both header and poster images */
	const [imageType, setImageType] = useState('')
	const [imageSrc, setImageSrc] = useState('')
	const [showModal, setShowModal] = useState(false)

	/** Header image state */
	const [headerImageUrl, setHeaderImageUrl] = useState('')
	const [headerImageError, setHeaderImageError] = useState('')

	/** Vertical poster state */
	const [verticalPosterUrl, setVerticalPosterUrl] = useState(null)
	const [verticalPosterError, setVerticalPosterError] = useState('')

	/** Guide modal state */
	const [guideIsOpen, setGuideIsOpen] = useState(false)
	const [activeGuide, setActiveGuide] = useState(IMAGE_TYPE.HEADER_IMAGE)
	const [photoIndex, setPhotoIndex] = useState(0)
	const [correctImageDimension, setCorrectImageDimension] = useState(false)

	const memoizedDesktop = useMemo(() => desktop, [desktop])
	const memoizedWebListing = useMemo(() => webListing, [webListing])

	useEffect(() => {
		if (desktop.length && desktop[0].url) {
			setHeaderImageUrl(desktop[0].url)
		}
	}, [memoizedDesktop])

	useEffect(() => {
		if (webListing.length && webListing[0].url) {
			setVerticalPosterUrl(webListing[0].url)
		}
	}, [memoizedWebListing])

	const clearState = () => {
		setImageType('')
		setImageSrc('')
	}

	const clearHeaderImageState = () => {
		setHeaderImageUrl('')
	}

	const clearVerticalPosterState = () => {
		setVerticalPosterUrl('')
	}

	const openModal = () => {
		setHeaderImageError('')
		setVerticalPosterError('')
		setShowModal(true)
	}

	const closeModal = () => {
		setShowModal(false)
		clearState()
	}

	const handleUpload = (files, type) => {
		const file = files[0]
		const fileReader = new FileReader()
		analyticsPush({
			event_name: 'event_image_upload_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action:
				type === IMAGE_TYPE.HEADER_IMAGE
					? 'horizontal_image_clicked'
					: 'vertical_image_clicked',
			screen_name: 'event_image_upload',
		})
		if (file) {
			fileReader.readAsDataURL(file)
		}
		const c = document.createElement('canvas')
		const ctx = c.getContext('2d')
		fileReader.onloadend = () => {
			if (type === IMAGE_TYPE.HEADER_IMAGE) {
				const img = new Image()
				img.onload = function () {
					// If the image dimension is 1280 X 640, convert image to blob and save it
					if (img?.width === 1280 && img?.height === 640) {
						c.width = this.naturalWidth // update canvas size to match image
						c.height = this.naturalHeight
						ctx.drawImage(this, 0, 0) // draw in image
						c.toBlob(function (blob) {
							const fileUrl = window.URL.createObjectURL(blob)
							handleDone(blob, fileUrl, type)
						}, 'image/jpeg')
					} else {
						// Popup image cropper model if the image size is different
						setImageType(type)
						setImageSrc(fileReader.result)
						openModal()
					}
				}
				img.src = fileReader.result // is the data URL because called with readAsDataURL
			} else {
				setImageType(type)
				setImageSrc(fileReader.result)
				openModal()
			}
		}
	}

	const handleCancel = () => {
		closeModal()
	}

	const handleDone = (croppedFile, croppedFileUrl, type = '') => {
		const typeOfImage = imageType || type
		if (typeOfImage === IMAGE_TYPE.HEADER_IMAGE) {
			if (croppedFile.size > MAX_HEADER_IMAGE_SIZE) {
				setHeaderImageError('File too large')
			} else {
				setHeaderImageUrl(croppedFileUrl)
				getBannerImageUrl('desktop', croppedFile)
			}
		} else if (typeOfImage === IMAGE_TYPE.VERTICAL_POSTER) {
			if (croppedFile.size > MAX_VERTICAL_POSTER_SIZE) {
				setVerticalPosterError('File too large')
			} else {
				setVerticalPosterUrl(croppedFileUrl)
				getBannerImageUrl('webListing', croppedFile)
			}
		}
		analyticsPush({
			event_name: 'event_image_upload_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'image_resized',
			screen_name: 'event_image_upload',
		})
		closeModal()
	}

	const openGuide = (guide) => {
		analyticsPush({
			event_name: 'event_image_upload_viewed',
			event_type: 'screen_view',
			event: 'gtm_allpage',
			screen_name: 'event_image_guidelines',
		})
		if (guide === IMAGE_TYPE.HEADER_IMAGE) {
			setActiveGuide(IMAGE_TYPE.HEADER_IMAGE)
		} else {
			setActiveGuide(IMAGE_TYPE.VERTICAL_POSTER)
		}
		setGuideIsOpen(true)
	}

	const onSave = () => {
		if (headerImageUrl && verticalPosterUrl) {
			updateEventImages()
			if (!isDesktop) history.goBack()
			return
		}

		if (!headerImageUrl) {
			setHeaderImageError('Upload horizontal banner to proceed')
		}
		if (!verticalPosterUrl) {
			setVerticalPosterError('Upload vertical poster to proceed')
		}
	}

	const checkForMinImageSize = (img) => {
		if (imageType === IMAGE_TYPE.HEADER_IMAGE) {
			if (img?.naturalWidth === 1280 && img?.naturalHeight === 640) {
				setCorrectImageDimension(true)
			}
			if (img.naturalWidth < 1280 || img.naturalHeight < 640) {
				showPopup({
					...generatePopUp({
						actual: `${img.naturalWidth} X ${img.naturalHeight}`,
						minimum: '1280 X 640',
					}).LOW_DIMENSIONS,
					nonCloseable: true,
					verticalPosition: 'middle',
					buttons: [
						{
							text: 'Okay',
							action: () => {
								setShowModal(false)
								hidePopup()
							},
						},
					],
				})
				return false
			}
		}
		if (imageType === IMAGE_TYPE.VERTICAL_POSTER) {
			if (img.naturalWidth < 500 || img.naturalHeight < 750) {
				showPopup({
					...generatePopUp({
						actual: `${img.naturalWidth} X ${img.naturalHeight}`,
						minimum: '500 X 750',
					}).LOW_DIMENSIONS,
					nonCloseable: true,
					verticalPosition: 'middle',
					buttons: [
						{
							text: 'Okay',
							action: () => {
								setShowModal(false)
								hidePopup()
							},
						},
					],
				})
				return false
			}
		}
		return true
	}

	const images =
		activeGuide === IMAGE_TYPE.HEADER_IMAGE
			? headerImageGuide
			: verticalPosterGuide

	const horizontalBannerRejectionReasons =
		bannerRejectionReasons.length > 0
			? bannerRejectionReasons.filter(
					(reason) => reason.rejectedValue === headerImageUrl
			  )
			: []
	const verticalBannerRejectionReasons =
		bannerRejectionReasons.length > 0
			? bannerRejectionReasons.filter(
					(reason) => reason.rejectedValue === verticalPosterUrl
			  )
			: []

	return (
		<>
			{horizontalBannerRejectionReasons.length > 0 && (
				<ErrorAlert
					errorMessages={horizontalBannerRejectionReasons}
					isDesktop={isDesktop}
					style={{
						boxShadow: '0px',
						width: '100%',
					}}
				/>
			)}
			<InfoAlertBar
				spaceBottom
				details="Note: Any new changes to event name or banner image will be sent for a review again. If you wish for no review and instant re-start of sales you should use same name & banner image"
			/>
			<EventImage
				isDesktop={isDesktop}
				titleText="Horizontal Banner"
				subTitleText="Image should be minimum 1280 x 640 or with the aspect ratio
					2:1. Max file size is 1MB."
				onGuideClick={() => openGuide(IMAGE_TYPE.HEADER_IMAGE)}
				uploadText="Header Image"
				handleUpload={handleUpload}
				imageType={IMAGE_TYPE.HEADER_IMAGE}
				imageWidth={isDesktop ? '420px' : '100%'}
				imageHeight="210px"
				imageUrl={headerImageUrl}
				imageAlt="Header Image"
				imageError={headerImageError}
				onImageDelete={clearHeaderImageState}
				disableEdit={eventNotification?.type === 'review'}
			/>
			{verticalBannerRejectionReasons.length > 0 && (
				<ErrorAlert
					errorMessages={verticalBannerRejectionReasons}
					isDesktop={isDesktop}
					style={{
						boxShadow: '0px',
						width: '100%',
					}}
				/>
			)}
			<EventImage
				isDesktop={isDesktop}
				titleText="Vertical Poster"
				subTitleText="Image should be minimum 500 x 750 or with the aspect ratio
				1:1.5. Max file size is 1MB."
				onGuideClick={() => openGuide(IMAGE_TYPE.VERTICAL_POSTER)}
				uploadText="Vertical Poster"
				handleUpload={handleUpload}
				imageType={IMAGE_TYPE.VERTICAL_POSTER}
				imageWidth="140px"
				imageHeight="210px"
				imageUrl={verticalPosterUrl}
				imageAlt="Vertical Poster"
				imageError={verticalPosterError}
				onImageDelete={clearVerticalPosterState}
				disableEdit={eventNotification?.type === 'review'}
			/>
			<InfoAlertBar
				spaceBottom
				details=<div>
					For promoting your event with Ad Banners, please email{' '}
					<a
						href="mailto:eventads@bookmyshow.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						eventads@bookmyshow.com
					</a>
					. Our Packages start from ₹6000 and can be customized
					according to your budget.
				</div>
			/>
			{imageSrc ? (
				<Modal
					title="Crop your image"
					showModal={showModal}
					isDesktop={true}
					toggleModal={closeModal}
					customStyle={{
						width: !isDesktop && '90vw',
						height:
							!isDesktop && imageType === IMAGE_TYPE.HEADER_IMAGE
								? '50vh'
								: !isDesktop && '70vh',
						maxWidth: '100vw',
						maxHeight: '100vh',
					}}
				>
					<ImageCropper
						imageSrc={imageSrc}
						handleCancel={handleCancel}
						handleDone={handleDone}
						cropWidth={isDesktop ? 300 : 200}
						cropHeight={
							imageType === IMAGE_TYPE.HEADER_IMAGE
								? isDesktop
									? 150
									: 100
								: isDesktop
								? 450
								: 300
						}
						aspectWidth={
							imageType === IMAGE_TYPE.HEADER_IMAGE ? 2 : 1
						}
						aspectHeight={
							imageType === IMAGE_TYPE.HEADER_IMAGE ? 1 : 1.5
						}
						isDesktop={isDesktop}
						isLocked={false}
						onImageLoadedCallback={checkForMinImageSize}
						correctImageDimension={correctImageDimension}
					/>
				</Modal>
			) : null}
			{guideIsOpen && (
				<Lightbox
					mainSrc={images[photoIndex]}
					nextSrc={images[(photoIndex + 1) % images.length]}
					prevSrc={
						images[(photoIndex + images.length - 1) % images.length]
					}
					onCloseRequest={() => {
						setGuideIsOpen(false)
						setPhotoIndex(0)
					}}
					onMovePrevRequest={() =>
						setPhotoIndex(
							(photoIndex + images.length - 1) % images.length
						)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % images.length)
					}
					enableZoom={false}
				/>
			)}
			{((!isDesktop && eventNotification?.type !== 'review') ||
				(isDesktop &&
					section &&
					showSaveButton &&
					eventNotification?.type !== 'review')) && (
				<ButtonWrapper isDesktop={isDesktop}>
					<Button
						size="LARGE"
						onClick={onSave}
						isFullWidth={!isDesktop}
						isLoading={buttonLoading}
					>
						Save
					</Button>
				</ButtonWrapper>
			)}
		</>
	)
}

const mapStateToProps = ({ device, eventManager }) => ({
	isDesktop: device.detection.isDesktop,
	activeEvent: eventManager.activeEvent,
	buttonLoading: eventManager.buttonLoading,
	eventNotification: eventManager?.eventNotification,
	bannerRejectionReasons:
		eventManager.activeEvent.formattedRejectionReasons?.banner || [],
	showSaveButton: eventManager?.showSaveButton,
})

const mapDispatchToProps = (dispatch) => ({
	getBannerImageUrl: (bannerType, image) =>
		dispatch(EventManagerDuc.creators.getBannerImageUrl(bannerType, image)),
	updateEventImages: () =>
		dispatch(EventManagerDuc.creators.updateEventImages()),

	// app duc
	showPopup: (config) => dispatch(AppDuc.creators.showPopup(config)),
	hidePopup: () => dispatch(AppDuc.creators.hidePopup()),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventImages)
