import React, { useRef, useState } from 'react'
import Tooltip from 'rc-tooltip'
import RadioButton from 'bms-le-components/components/RadioButton'
import Icon from 'bms-le-components/common/ui/Icon'
import WhiteCross from 'bms-le-components/common/icons/close-cross-white.svg'
import DeleteIcon from 'common/icons/delete-trash.svg'
import {
	CommonWrapper,
	IconWrapper,
	ToggleLabel,
	ToolTipTitleWrapper,
	ToolTipTitle,
	ToolTipDescriptuion,
	getOverlayInnerStyle,
	getOverlayStyle,
} from './__style'
import { useEffect } from 'react'

const StatusBar = (props) => {
	const {
		isOn,
		statusLabel,
		handleToggle,
		handleDelete,
		showDeleteIcon = true,
		disabled = false,
		isFirstSession,
	} = props
	const [showTooltip, setShowTooltip] = useState(true)
	const ticketRef = useRef()
	const toggleRef = useRef()

	useEffect(() => {
		/**
		 * This is to change the position of the the tooltip pointer
		 */
		toggleRef.current.parentNode.parentNode.childNodes[0].style.left = '5%'
	}, [])

	return (
		<CommonWrapper>
			<CommonWrapper isToggle>
				<Tooltip
					placement="bottomLeft"
					visible={showTooltip}
					getTooltipContainer={() => ticketRef.current}
					overlayStyle={getOverlayStyle()}
					overlayInnerStyle={getOverlayInnerStyle()}
					overlay={
						<div ref={toggleRef}>
							<ToolTipTitleWrapper>
								<ToolTipTitle>
									Close/Inactivate Session
								</ToolTipTitle>
								<Icon
									glyph={WhiteCross}
									height={10}
									width={10}
									style={{
										position: 'absolute',
										top: 18,
										right: 8,
										cursor: 'pointer',
									}}
									onClick={() => setShowTooltip(false)}
								/>
							</ToolTipTitleWrapper>
							<ToolTipDescriptuion>
								You can close your sessions easily by using this
								toggle button. Once closed, your session will no
								longer be visible on our platform for booking.
							</ToolTipDescriptuion>
						</div>
					}
				>
					<div ref={isFirstSession ? ticketRef : null}>
						<RadioButton
							size={36}
							isOn={isOn}
							onToggle={handleToggle}
							isEnabled={!disabled}
						/>
					</div>
				</Tooltip>
				<ToggleLabel>{statusLabel}</ToggleLabel>
			</CommonWrapper>
			{showDeleteIcon && (
				<IconWrapper onClick={handleDelete}>
					<Icon glyph={DeleteIcon} />
				</IconWrapper>
			)}
		</CommonWrapper>
	)
}

StatusBar.defaultProps = {
	showDeleteIcon: true,
}

export default StatusBar
