import React from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import { isSameDay, isValid } from 'date-fns'
import { withRouter } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

// components
import Button from 'bms-le-components/atoms/Button'
import DesktopWrapper from 'bms-le-components/components/DesktopWrapper'

import TimeEditor from './TimeEditor'
import ConfirmNewShows from './ConfirmNewShows'

// styled components
import { Wrapper, ButtonWrapper } from './__style'
import { BottomNavBar } from 'modules/EventManagement/components/ManageEvent/FirstTimeUser/__style'

// duc
import EventManagerDuc from 'modules/EventManagement/duc'
import { AppDuc } from 'modules/App/duc'

// helpers
import { calculateDatesBasedOnInterval } from 'utils/helpers'
import { getSessionInfoDates } from 'modules/EventManagement/components/VenueTimeScheduler/helper'
import { getUniqueDays } from 'modules/EventManagement/helpers'
import { EVENT_STATUS } from 'modules/EventManagement/helpers/constants'

const defaultSessionObject = { status: true, ticketInfo: [] }

class TimeScheduler extends React.Component {
	constructor(props) {
		super(props)
		let venue = props.isDesktop
			? props.venue || {}
			: props.currentVenue || {}

		if (!venue.sessionInfo) {
			venue = {
				...venue,
				sessionInfo: [
					{
						...defaultSessionObject,
						showTime: '',
						sessionId: 0,
					},
				],
			}
		} else {
			venue = {
				...venue,
				sessionInfo: venue?.sessionInfo.map((session, index) => {
					if (session?.sessionId) {
						if (session?.sessionId === '0') {
							return {
								...session,
								sessionId: index,
							}
						} else {
							return {
								...session,
								sessionId: session?.sessionId,
							}
						}
					} else {
						return {
							...session,
							sessionId: index,
						}
					}
				}),
			}
		}

		this.state = {
			venueState: venue,
			confirmNewShows: false,
			newShows: [],
			newSessionID: 0,
			showPublishNewShowsButton: false,
		}
	}

	getFilteredVenues = (venues) => {
		const filteredShows = venues.map((venue) => ({
			...venue,
			sessionInfo: venue?.sessionInfo?.filter((item) => {
				if (!item) return false
				return (
					(item.sessionId === '0' ||
						Number.isInteger(item.sessionId)) &&
					item.status
				)
			}),
		}))

		const filteredVenues = filteredShows.filter(
			(venue) => venue.sessionInfo.length
		)

		return filteredVenues
	}

	componentDidUpdate(prevProps) {
		const {
			selectedVenues: prevSelectedVenues,
			recurringInfo: prevRecurringInfo,
		} = prevProps
		const {
			selectedVenues: currSelectedVenues,
			recurringInfo: currRecurringInfo,
		} = this.props

		if (
			!isEqual(prevSelectedVenues, currSelectedVenues) ||
			!isEqual(prevRecurringInfo, currRecurringInfo)
		) {
			const { venues: prevVenues = [] } = getSessionInfoDates(
				prevSelectedVenues,
				prevRecurringInfo
			)

			const { venues: currVenues = [] } = getSessionInfoDates(
				currSelectedVenues,
				currRecurringInfo
			)

			const prevFilteredVenues = this.getFilteredVenues(prevVenues)
			const currFilteredVenues = this.getFilteredVenues(currVenues)

			this.setState({
				showPublishNewShowsButton:
					!isEqual(prevFilteredVenues, currFilteredVenues) &&
					currFilteredVenues.length > 0,
				newShows: [...currFilteredVenues],
			})
		}
	}

	isAddSession = () => {
		const { location } = this.props
		return JSON.parse(
			new URLSearchParams(location?.search).get('addSession') || false
		)
	}

	stateUpdate = (venueId) => {
		const { venueState } = this.state
		const { selectedVenues } = this.props

		const currentVenueIndex = selectedVenues.findIndex(
			(venue) => venue.venueCode === venueId
		)

		this.props.updateSessionInfo(venueState, [
			...selectedVenues.slice(0, currentVenueIndex),
			venueState,
			...selectedVenues.slice(currentVenueIndex + 1),
		])
	}

	updateSessionTime = (showTime, sessionIndex, venueId) => {
		this.setState(
			(prevState) => {
				const { venueState } = prevState
				return {
					...prevState,
					venueState: {
						...venueState,
						sessionInfo: [
							...venueState.sessionInfo.slice(0, sessionIndex),
							{
								...venueState.sessionInfo[sessionIndex],
								showTime: showTime,
							},
							...venueState.sessionInfo.slice(sessionIndex + 1),
						],
					},
				}
			},
			() => this.stateUpdate(venueId)
		)
	}

	handleShowTimeChange = (value, venueId, sessionId) => {
		const { venueState } = this.state
		const { showPopup, hidePopup, analyticsPush } = this.props
		const sessionIndex = venueState.sessionInfo.findIndex(
			(session) => session.sessionId === sessionId
		)

		analyticsPush({
			event_name: 'event_datetime_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'date_selected',
			screen_name: 'event_datetime_selection',
		})

		if (isValid(value)) {
			if (Date.parse(value) - Date.parse(new Date()) < 0) {
				showPopup({
					title: 'Invalid Show Date',
					content:
						'Please enter a Show Date at least one day in the future',
					nonCloseable: true,
					verticalPosition: 'middle',
					buttons: [
						{
							text: 'Okay',
							action: () => {
								this.updateSessionTime(
									'',
									sessionIndex,
									venueId
								)
								hidePopup()
							},
						},
					],
				})
			}
			this.updateSessionTime(value.toUTCString(), sessionIndex, venueId)
		}
	}

	handleTimeChange = (value, venueId, sessionId) => {
		const { venueState } = this.state
		const { analyticsPush } = this.props
		const sessionIndex = venueState.sessionInfo.findIndex(
			(session) => session.sessionId === sessionId
		)

		analyticsPush({
			event_name: 'event_datetime_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'time_selected',
			screen_name: 'event_datetime_selection',
		})
		if (
			isValid(value) &&
			venueState?.sessionInfo?.[sessionIndex].showTime
		) {
			const date = new Date(
				venueState?.sessionInfo?.[sessionIndex].showTime
			)
			date.setHours(value.getHours())
			date.setMinutes(value.getMinutes())
			date.setSeconds(0)
			this.setState(
				(prevState) => {
					const { venueState } = prevState
					return {
						...prevState,
						venueState: {
							...venueState,
							sessionInfo: [
								...venueState.sessionInfo.slice(
									0,
									sessionIndex
								),
								{
									...venueState.sessionInfo[sessionIndex],
									showTime: date.toUTCString(),
									errorMessage: venueState?.sessionInfo?.find(
										(d) =>
											new Date(
												d.showTime
											).toUTCString() ===
												date.toUTCString() &&
											d.sessionId !== sessionId
									)
										? 'Show time already exist for this day'
										: date < new Date()
										? 'Show time cannot be older than the current time'
										: null,
								},
								...venueState.sessionInfo.slice(
									sessionIndex + 1
								),
							],
						},
						newSessionID: '',
					}
				},
				() => this.stateUpdate(venueId)
			)
		}
	}

	handleDeleteTimer = (venueId, sessionId) => {
		const { venueState } = this.state
		const sessionIndex = venueState.sessionInfo.findIndex(
			(session) => session.sessionId === sessionId
		)
		const selectedSessionTime =
			venueState?.sessionInfo?.[sessionIndex]?.showTime || ''

		const sessionTimeWithDifferentDates = venueState.sessionInfo.filter(
			(session) =>
				selectedSessionTime !== session.showTime &&
				!isSameDay(
					new Date(selectedSessionTime),
					new Date(session.showTime)
				)
		)
		if (sessionTimeWithDifferentDates.length > 0) {
			this.setState(
				(prevState) => ({
					venueState: {
						...prevState.venueState,
						sessionInfo: sessionTimeWithDifferentDates,
					},
				}),
				() => this.stateUpdate(venueId)
			)
		} else {
			this.setState(
				(prevState) => ({
					venueState: {
						...prevState.venueState,
						sessionInfo: [
							{
								...defaultSessionObject,
								showTime: '',
								sessionId: 0,
							},
						],
					},
				}),
				() => this.stateUpdate(venueId)
			)
		}
	}

	handleToggleStatus = (value, venueId, showTime) => {
		const { venueState } = this.state

		const sessionInfo = venueState.sessionInfo.map((session) => {
			if (isSameDay(new Date(session.showTime), new Date(showTime))) {
				return { ...session, status: value }
			} else {
				return session
			}
		})

		this.setState(
			(prevState) => {
				const { venueState } = prevState
				return {
					...prevState,
					venueState: {
						...venueState,
						sessionInfo,
					},
				}
			},
			() => this.stateUpdate(venueId)
		)
	}

	handleAddDay = (venueId) => {
		this.setState(
			(prevState) => {
				// const { venueState } = prevState
				return {
					...prevState,
					venueState: {
						...prevState.venueState,
						sessionInfo: [
							...prevState.venueState.sessionInfo,
							{
								...defaultSessionObject,
								showTime: '',
								sessionId:
									prevState.venueState.sessionInfo.length + 1,
							},
						],
					},
					newSessionID: prevState.venueState.sessionInfo.length + 1,
				}
			},
			() => this.stateUpdate(venueId)
		)
	}

	handleAddRemoveShowTime = (venueId, value, sessionId, index) => {
		if (index === 0) {
			this.setState(
				(prevState) => {
					return {
						...prevState,
						venueState: {
							...prevState.venueState,
							sessionInfo: [
								...prevState.venueState.sessionInfo,
								{
									...defaultSessionObject,
									showTime: value,
									sessionId:
										prevState.venueState.sessionInfo
											.length + 1,
									errorMessage:
										prevState.venueState?.sessionInfo?.find(
											(d) => {
												return (
													new Date(
														d.showTime
													).getTime() ===
													new Date(value).getTime()
												)
											}
										)
											? 'Show time already exist for this day'
											: new Date(value) < new Date()
											? 'Show time cannot be older than the current time'
											: null,
								},
							],
						},
					}
				},
				() => this.stateUpdate(venueId)
			)
		} else {
			const { venueState } = this.state
			const sessionIndex = venueState.sessionInfo.findIndex(
				(session) => session.sessionId === sessionId
			)
			this.setState(
				(prevState) => ({
					venueState: {
						...prevState.venueState,
						sessionInfo: [
							...prevState.venueState.sessionInfo.slice(
								0,
								sessionIndex
							),
							...prevState.venueState.sessionInfo.slice(
								sessionIndex + 1
							),
						],
					},
				}),
				() => this.stateUpdate(venueId)
			)
		}
	}

	getSessionInfoOnRangeOfDates = (sessions, recurringInfo) => {
		return sessions.map((session) => {
			if (recurringInfo?.code && parseInt(recurringInfo?.noOfTimes)) {
				const getDates = calculateDatesBasedOnInterval(
					recurringInfo?.code,
					session.showTime,
					parseInt(recurringInfo?.noOfTimes) || 0
				)
				return getDates.map((data) => ({
					...defaultSessionObject,
					showTime: new Date(data).toISOString(),
				}))
			}
		})
	}

	checkForDuplicateSessions = (sessions) => {
		const uniqueSessions = new Set(sessions)
		return sessions.length === uniqueSessions.size
	}

	handleSubmit = (requestOrigin) => {
		const {
			history,
			selectedVenues,
			updateEventData,
			draftId,
			eventCode,
			isDesktop,
			recurringInfo,
			updateSynopsis,
			analyticsPush,
			showPopup,
			hidePopup,
			updateShowSaveButton,
			updateRecurringInfo,
		} = this.props
		const { venueState, newShows } = this.state

		const { sessionInfo, venues } = getSessionInfoDates(
			selectedVenues,
			recurringInfo
		)

		const newVenueState = {
			...venueState,
			sessionInfo: [
				...(venueState?.sessionInfo || []),
				...(sessionInfo[venueState?.venueCode]?.sessionInfo?.flat() ||
					[]),
			].filter(Boolean),
		}

		analyticsPush({
			event_name: 'event_datetime_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'submit_clicked',
			screen_name: 'event_datetime_selection',
		})

		this.setState((prevState) => ({
			...prevState,
			venueState: newVenueState,
		}))
		if (
			this.checkForDuplicateSessions(
				newVenueState?.sessionInfo?.map((session) => session?.showTime)
			)
		) {
			updateSynopsis('venueInfo', venues)
			if (requestOrigin === 'proceedToTicket') {
				updateRecurringInfo({})
				// Redirect to tickets page
				if (isDesktop) {
					updateShowSaveButton(false)
					const slug =
						newShows[0]?.venueCode || newVenueState?.venueCode
					let path = `/events/configure/${
						draftId || eventCode
					}/edit/${slug}`
					updateEventData(
						draftId,
						eventCode,
						'venueInfo',
						venues,
						`customRedirection=${path}`
					)
				} else {
					history.goBack()
				}
				this.setState({ confirmNewShows: false })
			} else if (!isDesktop) {
				history.goBack()
				updateEventData(
					draftId,
					eventCode,
					'venueInfo',
					venues,
					'synopsis'
				)
			} else {
				updateEventData(draftId, eventCode, 'venueInfo', venues)
			}
		} else {
			showPopup({
				title: 'Duplicate Show time',
				content:
					'There is already another show at the same date and time',
				nonCloseable: true,
				verticalPosition: 'middle',
				buttons: [
					{
						text: 'Okay',
						action: () => {
							hidePopup()
						},
					},
				],
			})
		}
	}

	handleAddRecurringInfo = (data, showTime) => {
		const showTimeString = new Date(showTime).toISOString()
		const { recurringInfo } = this.props
		const { venueState } = this.state
		this.props.updateShowSaveButton(true)
		this.props.updateRecurringInfo({
			...recurringInfo,
			[venueState.venueCode]: {
				...recurringInfo[venueState?.venueCode],
				[showTimeString]: {
					...recurringInfo?.[venueState?.venueCode]?.[showTimeString],
					...data,
					showTime,
				},
			},
		})
	}

	handleDeleteRecurringInfo = (showTime) => {
		const showTimeString = new Date(showTime).toISOString()
		const { recurringInfo, updateShowSaveButton, updateRecurringInfo } =
			this.props
		const { venueState } = this.state

		const newRecurringInfo = JSON.parse(JSON.stringify(recurringInfo)) || {}
		const currentVenueRecurringInfo =
			newRecurringInfo[venueState?.venueCode] || {}
		if (currentVenueRecurringInfo?.[showTimeString]) {
			delete currentVenueRecurringInfo[showTimeString]
		}

		if (
			!Object.keys(currentVenueRecurringInfo).length &&
			newRecurringInfo[venueState?.venueCode]
		) {
			delete newRecurringInfo[venueState?.venueCode]
		}

		updateShowSaveButton(true)
		updateRecurringInfo(newRecurringInfo)
	}

	convertSelectedDatesToObj = (showTime) => {
		const { venueState } = this.state

		return venueState.sessionInfo.reduce((acc, e) => {
			// `toLocaleDateString` is done because time may be different for both dates
			if (
				e.showTime &&
				new Date(showTime).toLocaleDateString() !==
					new Date(e.showTime).toLocaleDateString()
			) {
				acc[new Date(e.showTime).toLocaleDateString()] = true
			}
			return acc
		}, {})
	}

	getRecurringDisabledDates = (showTime, initialDisabledDates) => {
		const { recurringInfo } = this.props
		const {
			venueState: { venueCode },
		} = this.state

		return Object.keys(recurringInfo[venueCode])?.reduce(
			(acc, selectedDate) => {
				// `toLocaleDateString` is done because time may be different for both dates
				if (
					new Date(selectedDate).toLocaleDateString() !==
					new Date(showTime).toLocaleDateString()
				) {
					const dates = calculateDatesBasedOnInterval(
						recurringInfo[venueCode][selectedDate].code,
						selectedDate,
						parseInt(
							recurringInfo[venueCode][selectedDate].noOfTimes ||
								0
						)
					)

					return dates.reduce(
						(a, date) => ({
							...a,
							[new Date(date).toLocaleDateString()]: true,
						}),
						acc
					)
				}
				return acc
			},
			initialDisabledDates
		)
	}

	calculateDisabledDates = (showTime) => {
		const { recurringInfo } = this.props
		const { venueState } = this.state

		// calculate diabled dates from all the selected dates
		let disabledDates = this.convertSelectedDatesToObj(showTime)

		// if there are any recurring events, then calculate disabled dates from them
		if (recurringInfo[venueState.venueCode]) {
			disabledDates = this.getRecurringDisabledDates(
				showTime,
				disabledDates
			)
		}

		return disabledDates
	}

	openConfirmNewShows = () => {
		this.setState({ confirmNewShows: true })
	}

	primaryCtaTextToShow = () => {
		const { isDesktop, eventCurrentStatus } = this.props

		if (
			(!isDesktop && eventCurrentStatus === EVENT_STATUS.PAST) ||
			isDesktop
		)
			return 'Save'
		else return 'Continue'
	}

	render() {
		const {
			isDesktop,
			recurringInfo,
			match,
			buttonLoading,
			eventNotification,
			showSaveButton,
			venueType,
			disabled,
			eventCurrentStatus,
			isFirstVenue,
		} = this.props
		const {
			venueState,
			confirmNewShows,
			newShows,
			newSessionID,
			showPublishNewShowsButton,
		} = this.state
		const venueData = venueState
		const { sessionInfo = [] } = venueState
		const showAddAnotherDayButton =
			sessionInfo[0]?.sessionId !== 0 || sessionInfo[0]?.showTime !== ''
		const isOnlineVenue = venueType === 'online'

		let days = []
		if (venueData) {
			const { sessionInfo = [] } = venueData
			days = getUniqueDays(sessionInfo)
		}

		return (
			<Wrapper isDesktop={isDesktop}>
				{days.length > 0 &&
					days.map((info, index) => {
						const recurring = isValid(new Date(info?.showTime))
							? recurringInfo?.[venueState?.venueCode]?.[
									new Date(info.showTime).toISOString()
							  ]
							: null
						return (
							<TimeEditor
								key={info.sessionId}
								{...info}
								isDesktop={isDesktop}
								isNewSession={info.sessionId === newSessionID}
								handleToggle={(val) =>
									this.handleToggleStatus(
										val,
										venueData.venueCode,
										info.showTime
									)
								}
								showStatus={info?.showTime !== ''}
								handleShowDateChange={(val) =>
									this.handleShowTimeChange(
										val,
										venueData.venueCode,
										info.sessionId
									)
								}
								handleShowTimeChange={(val, sessionId) => {
									this.handleTimeChange(
										val,
										venueData.venueCode,
										sessionId
									)
								}}
								handleDelete={() =>
									this.handleDeleteTimer(
										venueData.venueCode,
										info.sessionId
									)
								}
								handleAddRemoveShowTime={(
									sessionId,
									index,
									showTime
								) =>
									this.handleAddRemoveShowTime(
										venueData.venueCode,
										showTime || info.showTime,
										sessionId,
										index
									)
								}
								sessionInfo={venueData.sessionInfo}
								handleAddRecurringInfo={(data) =>
									this.handleAddRecurringInfo(
										data,
										info.showTime
									)
								}
								handleDeleteRecurringInfo={() =>
									this.handleDeleteRecurringInfo(
										info.showTime
									)
								}
								recurringInfo={recurring}
								isOnlineVenue={isOnlineVenue}
								disabled={disabled}
								disabledDates={this.calculateDisabledDates(
									info?.showTime
								)}
								eventCurrentStatus={eventCurrentStatus}
								isFirstSession={!index && isFirstVenue}
							/>
						)
					})}
				{!isOnlineVenue && showAddAnotherDayButton && (
					<ButtonWrapper isDesktop={isDesktop}>
						<Button
							onClick={() =>
								this.handleAddDay(venueData.venueCode)
							}
							style={{
								width: '100%',
								backgroundColor: 'white',
								margin: '0 auto 20px auto',
							}}
							type="SECONDARY"
							size="LARGE"
							disabled={disabled}
						>
							Add another day
						</Button>
					</ButtonWrapper>
				)}

				{((isDesktop &&
					showSaveButton &&
					eventNotification?.type !== 'review' &&
					match?.params?.section === 'event-schedule') ||
					(eventNotification?.type !== 'review' &&
						match.params?.section === 'date-time-scheduler')) && (
					<BottomNavBar fixed>
						<DesktopWrapper
							isDesktop
							customStyles={{
								display: 'flex',
								justifyContent: 'center',
								width: isDesktop ? '224px' : '100%',
							}}
						>
							<span
								style={{
									width: !isDesktop ? '100%' : 'initial',
								}}
							>
								<Button
									size="LARGE"
									style={{
										margin: isDesktop
											? '0 40px 0 0'
											: '0 auto',
										width: isDesktop ? '160px' : '100%',
									}}
									disabled={disabled}
									onClick={() => this.handleSubmit('save')}
									isLoading={buttonLoading}
									type={isDesktop ? 'SECONDARY' : 'PRIMARY'}
									isFullWidth={!isDesktop}
								>
									{this.primaryCtaTextToShow()}
								</Button>
							</span>
							{showPublishNewShowsButton && (
								<span>
									{isDesktop && (
										<Button
											size="LARGE"
											style={{
												margin: '0 auto',
												width: isDesktop
													? '220px'
													: '100%',
											}}
											disabled={disabled}
											onClick={this.openConfirmNewShows}
											isLoading={buttonLoading}
										>
											Proceed To Tickets
										</Button>
									)}
								</span>
							)}
						</DesktopWrapper>
					</BottomNavBar>
				)}
				{confirmNewShows && (
					<ConfirmNewShows
						isDesktop={isDesktop}
						showModal={confirmNewShows}
						backToEdit={() =>
							this.setState({ confirmNewShows: false })
						}
						confirm={() => this.handleSubmit('proceedToTicket')}
						data={newShows}
					/>
				)}
			</Wrapper>
		)
	}
}

TimeScheduler.propTypes = { isDesktop: bool.isRequired }
TimeScheduler.defaultProps = { isDesktop: true }

const mapStateToProps = ({ eventManager }) => ({
	selectedVenues: eventManager.activeEvent.venueInfo || [],
	currentVenue: eventManager.activeEvent.currentVenue || {},
	draftId: eventManager.activeEvent.draftId || '',
	eventCurrentStatus: eventManager.activeEvent.eventCurrentStatus || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	recurringInfo: eventManager.activeEvent?.recurringInfo || {},
	buttonLoading: eventManager?.buttonLoading,
	eventNotification: eventManager?.eventNotification,
	showSaveButton: eventManager?.showSaveButton,
	venueType: eventManager?.activeEvent?.venueType || '',
})

const mapDispatchToProps = (dispatch) => ({
	updateSessionInfo: (venue, selectedVenues) =>
		dispatch(
			EventManagerDuc.creators.updateSessionInfo(venue, selectedVenues)
		),
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateEventData: (draftId, eventCode, section, data, redirectTo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				data,
				redirectTo
			)
		),
	updateRecurringInfo: (data) =>
		dispatch(EventManagerDuc.creators.updateRecurringInfo(data)),
	updateShowSaveButton: (data) =>
		dispatch(EventManagerDuc.creators.updateShowSaveButton(data)),

	// app duc
	showPopup: (config) => dispatch(AppDuc.creators.showPopup(config)),
	hidePopup: () => dispatch(AppDuc.creators.hidePopup()),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TimeScheduler))
