import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'

const initialState = {
	isLoading: false,
	formData: {},
	isError: false,
	stateList: [],
	bankList: [],
}

export default new Duck({
	namespace: 'diy-reporting',
	store: 'createAccount',
	types: [
		'GET_SIGN_UP_FORM',
		'GET_SIGN_UP_FORM_SUCCESS',
		'GET_SIGN_UP_FORM_FAILED',
		'SAVE_GENERAL_INFO',
		'SAVE_DOCUMENTS_INFO',
		'SAVE_INFO_SUCCESS',
		'SAVE_INFO_FAILED',
		'UPLOAD_FILE',
		'UPLOAD_FILE_SUCCESS',
		'UPLOAD_FILE_FAILED',
		'UPLOAD_AGREEMENT',
		'UPLOAD_AGREEMENT_SUCCESS',
		'UPLOAD_AGREEMENT_FAILED',
		'SUBMIT_GENERAL_INFO',
		'SUBMIT_GENERAL_INFO_SUCCESS',
		'SUBMIT_DOCUMENTS_INFO',
		'SUBMIT_DOCUMENTS_INFO_SUCCESS',
		'SUBMIT_AGREEMENT_INFO',
		'SUBMIT_AGREEMENT_INFO_SUCCESS',
		'GET_STATE_LIST',
		'GET_STATE_LIST_SUCCESS',
		'GET_BANK_LIST',
		'GET_BANK_LIST_SUCCESS',
		'DOWNLOAD_AGREEMENT',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.GET_SIGN_UP_FORM: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.GET_SIGN_UP_FORM_SUCCESS: {
				const { payload } = action
				return {
					...state,
					formData: payload || {},
					isLoading: false,
				}
			}
			case duck.types.GET_SIGN_UP_FORM_FAILED: {
				const { error } = action
				return {
					...state,
					formData: error || {},
					isLoading: false,
					isError: true,
				}
			}
			case duck.types.SAVE_GENERAL_INFO: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.SAVE_DOCUMENTS_INFO: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.SAVE_INFO_SUCCESS: {
				const { payload } = action
				return {
					...state,
					formData: { ...state.formData, ...(payload || {}) },
					isLoading: false,
				}
			}
			case duck.types.SAVE_INFO_FAILED: {
				return {
					...state,
					isLoading: false,
				}
			}
			case duck.types.UPLOAD_FILE_SUCCESS: {
				const {
					payload: { path, value },
				} = action

				return setIn(state, path, {
					...getIn(state, path),
					fileName: value,
					errors: [],
				})
			}
			case duck.types.UPLOAD_FILE_FAILED: {
				const {
					payload: { path, errors },
				} = action

				return setIn(state, path, {
					...getIn(state, path),
					fileName: '',
					errors,
				})
			}
			case duck.types.UPLOAD_AGREEMENT_SUCCESS: {
				const {
					payload: { fileName },
				} = action

				return setIn(state, ['formData', 'step3', 'agreement'], {
					fileName,
					error: false,
				})
			}
			case duck.types.UPLOAD_AGREEMENT_FAILED: {
				const { payload } = action

				return setIn(state, ['formData', 'step3', 'agreement'], {
					value: '',
					error: payload,
				})
			}
			case duck.types.SUBMIT_GENERAL_INFO: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.SUBMIT_DOCUMENTS_INFO: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.SUBMIT_AGREEMENT_INFO: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.GET_STATE_LIST: {
				return {
					...state,
				}
			}
			case duck.types.GET_STATE_LIST_SUCCESS: {
				const { payload } = action
				return {
					...state,
					stateList: payload || {},
				}
			}
			case duck.types.GET_BANK_LIST: {
				return {
					...state,
				}
			}
			case duck.types.GET_BANK_LIST_SUCCESS: {
				const { payload } = action
				return {
					...state,
					bankList: payload || {},
				}
			}
			default:
				return state
		}
	},
	selectors: {
		root: (state) => state,
	},
	creators: (duck) => ({
		getSignUpForm: () => ({
			type: duck.types.GET_SIGN_UP_FORM,
		}),
		getSignUpFormSuccess: (payload) => ({
			type: duck.types.GET_SIGN_UP_FORM_SUCCESS,
			payload,
		}),
		getSignUpFormFailed: (error) => ({
			type: duck.types.GET_SIGN_UP_FORM_FAILED,
			error,
		}),
		saveGeneralInfo: (payload) => ({
			type: duck.types.SAVE_GENERAL_INFO,
			payload,
		}),
		saveDocumentsInfo: (payload) => ({
			type: duck.types.SAVE_DOCUMENTS_INFO,
			payload,
		}),
		saveInfoSuccess: (payload) => ({
			type: duck.types.SAVE_INFO_SUCCESS,
			payload,
		}),
		saveInfoFailed: () => ({
			type: duck.types.SAVE_INFO_FAILED,
		}),
		uploadFile: (payload) => ({
			type: duck.types.UPLOAD_FILE,
			payload,
		}),
		uploadFileSuccess: (payload) => ({
			type: duck.types.UPLOAD_FILE_SUCCESS,
			payload,
		}),
		uploadFileFailed: (payload) => ({
			type: duck.types.UPLOAD_FILE_FAILED,
			payload,
		}),
		uploadAgreement: (payload) => ({
			type: duck.types.UPLOAD_AGREEMENT,
			payload,
		}),
		uploadAgreementSuccess: (payload) => ({
			type: duck.types.UPLOAD_AGREEMENT_SUCCESS,
			payload,
		}),
		uploadAgreementFailed: (payload) => ({
			type: duck.types.UPLOAD_AGREEMENT_FAILED,
			payload,
		}),
		submitGeneralInfo: (payload) => ({
			type: duck.types.SUBMIT_GENERAL_INFO,
			payload,
		}),
		submitDocumentsInfo: (payload) => ({
			type: duck.types.SUBMIT_DOCUMENTS_INFO,
			payload,
		}),
		submitAgreementInfo: (payload) => ({
			type: duck.types.SUBMIT_AGREEMENT_INFO,
			payload,
		}),
		getStateList: () => ({
			type: duck.types.GET_STATE_LIST,
		}),
		getStateListSuccess: (payload) => ({
			type: duck.types.GET_STATE_LIST_SUCCESS,
			payload,
		}),
		getBankList: () => ({
			type: duck.types.GET_BANK_LIST,
		}),
		getBankListSuccess: (payload) => ({
			type: duck.types.GET_BANK_LIST_SUCCESS,
			payload,
		}),
		downloadAgreement: (data) => ({
			type: duck.types.DOWNLOAD_AGREEMENT,
			data,
		}),
	}),
})
