import React, { useCallback } from 'react'
import { object, string, bool, func } from 'prop-types'
import Artists from 'bms-le-components/components/Artists'
import ButtonPill from 'bms-le-components/components/TicketPill'
import { createComponent } from 'react-fela'
import { __mainWrapper } from './__style'

const MainWrapper = createComponent(__mainWrapper, 'div', ['onClick'])

const ArtistCard = props => {
	const { artistInfo, info, status, handleClick } = props

	const handleBtnClick = useCallback(() => {
		handleClick(artistInfo)
	}, [])

	const { name, url, artistType } = artistInfo

	return (
		<MainWrapper>
			<Artists
				row
				isDesktop
				pullRight="12"
				imageWidth={46}
				marginBottom={12}
				artistName={name}
				artistImage={url}
				artistType={artistType}
			/>
			<ButtonPill
				info={info}
				status={status}
				handleClick={handleBtnClick}
			/>
		</MainWrapper>
	)
}

ArtistCard.propTypes = {
	artistInfo: object.isRequired,
	info: string.isRequired,
	status: bool.isRequired,
	handleClick: func.isRequired,
}

ArtistCard.defaultProps = {
	artistInfo: null,
	info: '',
	status: false,
	handleClick: () => {},
}

export default ArtistCard
