import { createComponent } from 'react-fela'

const ButtonWrapper = createComponent(() => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	width: '100%',
	display: 'flex',
	backgroundColor: '#fff',
	padding: '7.5px 15px',
	justifyContent: 'center',
	boxShadow: '0px 0 5px rgba(0, 0, 0, 0.2)',
}))

export { ButtonWrapper }
