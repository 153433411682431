import { createComponent } from 'react-fela'

export const DesktopWrapper = createComponent(() => ({
	background: '#ECF5FF',
	borderRadius: '3px 3px 0px 0px',
	padding: '24px',
}))

export const MobileWrapper = createComponent(() => ({
	background: '#ECF5FF',
	borderRadius: '4px',
	padding: '16px',
}))

export const TitleText = createComponent(() => ({
	fontFamily: 'Roboto',
	fontWeight: 'bold',
	fontSize: '16px',
	lineHeight: '20px',
	color: '#000000',
	marginBottom: '4px',
}))

export const SubTitleWrapper = createComponent(isDesktop => ({
	display: 'flex',
	justifyContent: isDesktop ? 'space-between' : 'flex-start',
}))

export const SubTitleText = createComponent(
	({ isActionText }) => ({
		fontFamily: 'Roboto',
		fontSize: '12px',
		lineHeight: '16px',
		color: '#666666',
		marginBottom: '4px',
		extend: [
			{
				condition: isActionText,
				style: {
					color: '#0378FF',
					cursor: 'pointer',
				},
			},
		],
	}),
	'div',
	['onClick']
)

export const ButtonWrapper = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	marginTop: '16px',
}))

export const FooterText = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	marginTop: '10px',
	color: '#000000',
}))

export const HeadWrapper = createComponent(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	margin: 'auto auto 10px',
	alignItems: 'center',
}))

export const HeaderActionTxt = createComponent(
	() => ({
		color: '#0378FF',
		fontSize: 12,
		fontWeight: 400,
	}),
	'div',
	['onClick']
)

export const TitleWrapper = createComponent(() => ({
	display: 'flex',
	flex: 1,
	justifyContent: 'space-between',
	alignItems: 'center',
	flexWrap: 'wrap',
}))
