import { takeLatest, all, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import CreateAccountDuc from 'modules/CreateAccount/duc'
import { AppDuc } from 'modules/App/duc'
import { newUserRequest, downloadFile, getFile } from 'utils/request'
import {
	genericErrorMesssage,
	PENDING,
	GENERAL_INFO_PATH,
	UPLOAD_DOCUMENTS_PATH,
	SIGN_AGREEMENT_PATH,
	ACKNOWLEDGEMENT_PATH,
	ERRORCODES,
} from './constants'
import { M5 } from 'utils/storage'

export function* handleError(error) {
	let errorMessage = genericErrorMesssage

	if (error?.response?.data) {
		const {
			response: { data = {} },
		} = error || {}
		errorMessage = data?.message?.shortDesc
	}

	yield put(
		AppDuc.creators.showToast('error', true, errorMessage, false, 6000)
	)
}

const getCurrentStepPath = (step) => {
	switch (step) {
		case 'STEP2':
			return UPLOAD_DOCUMENTS_PATH
		case 'STEP3':
			return SIGN_AGREEMENT_PATH
		default:
			return GENERAL_INFO_PATH
	}
}

export function* getSignUpForm() {
	try {
		const {
			data: { data },
		} = yield call(() => newUserRequest.get(`/v1/signup-form`))

		yield put(CreateAccountDuc.creators.getSignUpFormSuccess(data))

		const { currentStep, status } = data
		if (currentStep === 'STEP3' && status === PENDING) {
			yield put(push(ACKNOWLEDGEMENT_PATH))
		} else {
			yield put(push(getCurrentStepPath(currentStep)))
		}
	} catch (error) {
		const {
			response: { data: { errors: { code = '' } = {} } = {} },
		} = error

		yield put(CreateAccountDuc.creators.getSignUpFormFailed(code))
		if (ERRORCODES.includes(code)) {
			M5.Storage.del({ name: 'newUserAccessToken' })
			window.location.replace('/signup')
		}
	}
}

export function* saveGeneralInfo({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.put(`v1/save-form/userdetails`, payload)
		)

		yield put(CreateAccountDuc.creators.saveInfoSuccess(data))
		if (data.HasError) {
			yield put(
				AppDuc.creators.showToast(
					'error',
					true,
					'Please validate the data entered!'
				)
			)
		} else {
			yield put(
				AppDuc.creators.showToast(
					'success',
					true,
					data?.message || 'Data saved successfully'
				)
			)
		}
	} catch (error) {
		yield put(CreateAccountDuc.creators.saveInfoFailed())
		yield call(handleError, error)
	}
}

export function* saveDocumentsInfo({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.put(`v1/save-form/filesupload`, payload)
		)

		yield put(CreateAccountDuc.creators.saveInfoSuccess(data))
		yield put(
			AppDuc.creators.showToast(
				'success',
				true,
				data?.message || 'Data saved successfully'
			)
		)
	} catch (error) {
		yield put(CreateAccountDuc.creators.saveInfoFailed())
		yield call(handleError, error)
	}
}

export function* submitGeneralInfo({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.post(`v1/proceed/userdetails`, payload)
		)

		yield put(CreateAccountDuc.creators.saveInfoSuccess(data))
		if (data.HasError) {
			yield put(
				AppDuc.creators.showToast(
					'error',
					true,
					'Please validate the data entered!'
				)
			)
		} else {
			yield put(CreateAccountDuc.creators.getSignUpForm())
			yield put(push(UPLOAD_DOCUMENTS_PATH))
		}
	} catch (error) {
		yield put(CreateAccountDuc.creators.saveInfoFailed())
		yield call(handleError, error)
	}
}

export function* submitDocumentsInfo({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.post(`v1/proceed/filesupload`, payload)
		)

		yield put(CreateAccountDuc.creators.saveInfoSuccess(data))
		if (data.HasError) {
			yield put(
				AppDuc.creators.showToast(
					'error',
					true,
					'Please validate the data entered!'
				)
			)
		} else {
			yield put(push(SIGN_AGREEMENT_PATH))
		}
	} catch (error) {
		yield put(CreateAccountDuc.creators.saveInfoFailed())
		yield call(handleError, error)
	}
}

export function* submitAgreementInfo({ payload }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.post(`v1/proceed/agreementupload`, payload)
		)

		yield put(CreateAccountDuc.creators.saveInfoSuccess(data))

		if (data.HasError) {
			yield put(
				AppDuc.creators.showToast(
					'error',
					true,
					'Please validate the data entered!'
				)
			)
		} else {
			yield put(push(ACKNOWLEDGEMENT_PATH))
		}
	} catch (error) {
		yield put(CreateAccountDuc.creators.saveInfoFailed())
		yield call(handleError, error)
	}
}

const getPath = (type, index) => {
	switch (type) {
		case 'pan':
			return ['formData', 'step2', 'panCard']
		case 'cheque':
			return ['formData', 'step2', 'cancelledCheque']
		case 'gst':
			return ['formData', 'step2', 'gstCertificates', index]
		default:
			return null
	}
}

export function* uploadFile({ payload }) {
	const { file, name, gstNo = '', index = 0 } = payload

	try {
		const formData = new FormData()

		formData.append('file', file)
		formData.append('media', name)

		if (name === 'gst') {
			formData.append('id', gstNo)
		}

		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.post(`v1/signup/file`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		)

		yield put(
			CreateAccountDuc.creators.uploadFileSuccess({
				path: getPath(name, index),
				value: data.fileName,
			})
		)
	} catch (error) {
		let errorMessage = genericErrorMesssage

		if (error?.response?.data) {
			const {
				response: { data = {} },
			} = error || {}
			errorMessage = data?.message?.shortDesc
		}

		yield put(
			CreateAccountDuc.creators.uploadFileFailed({
				path: getPath(name, index),
				errors: errorMessage.split(),
			})
		)
	}
}

export function* uploadAgreement({ payload }) {
	const { file } = payload

	try {
		const formData = new FormData()

		formData.append('file', file)
		formData.append('media', 'agreement')

		const {
			data: { data },
		} = yield call(() =>
			newUserRequest.post(`v1/signup/file`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
		)

		yield put(CreateAccountDuc.creators.uploadAgreementSuccess(data))
	} catch (error) {
		let errorMessage = genericErrorMesssage

		if (error?.response?.data) {
			const {
				response: { data = {} },
			} = error || {}
			errorMessage = data?.message?.shortDesc
		}

		yield put(
			CreateAccountDuc.creators.uploadAgreementFailed(
				errorMessage.split()
			)
		)
	}
}

export function* getStateList() {
	try {
		const {
			data: { data },
		} = yield call(() => newUserRequest.get(`v1/state-list`))

		yield put(CreateAccountDuc.creators.getStateListSuccess(data?.states))
	} catch (error) {
		yield call(handleError, error)
	}
}

export function* getBankList() {
	try {
		const {
			data: { data },
		} = yield call(() => newUserRequest.get(`v1/bank-list`))

		yield put(CreateAccountDuc.creators.getBankListSuccess(data?.bankList))
	} catch (error) {
		yield call(handleError, error)
	}
}

export function getGstState(gstNo) {
	const headers = {
		'gst-no': gstNo,
	}

	newUserRequest.defaults.headers = Object.assign(
		newUserRequest.defaults.headers,
		headers
	)
	try {
		const response = newUserRequest.get(`v1/gst-state`)
		return response
	} catch (error) {
		console.error(error)
	}
}

export function checkUniqueValue(type, value) {
	const headers = {
		data: value,
	}

	newUserRequest.defaults.headers = Object.assign(
		newUserRequest.defaults.headers,
		headers
	)
	try {
		const response = newUserRequest.get(`v1/signup/check-unique/${type}`)
		return response
	} catch (error) {
		console.error(error)
	}
}

export async function getFileImage(value) {
	const headers = {
		data: value,
		'Cache-Control': 'no-cache',
	}

	try {
		const response = await getFile('/v1/get-file', headers)

		if (!response.ok) {
			throw new Error('Something Went Wrong!')
		}

		const blob = await response.blob()
		const fileData = {
			blob: blob,
			objectURL: URL.createObjectURL(blob),
		}

		return fileData
	} catch (error) {
		console.error(error)
		throw error
	}
}

export function* downloadAgreement() {
	try {
		yield put(
			AppDuc.creators.showToast(
				'info',
				true,
				'Downloading the agreement',
				false,
				2000
			)
		)
		const data = yield downloadFile('/v1/signup/agreement-file')

		if (!data) {
			throw new Error('Something Went Wrong!')
		}

		const blob = yield data.blob()

		const downloadLink = document.createElement('a')
		downloadLink.setAttribute('download', 'agreement')
		downloadLink.href = window.URL.createObjectURL(blob)
		downloadLink.style.display = 'none'
		document.body.appendChild(downloadLink)
		downloadLink.click()
		yield put(
			AppDuc.creators.showToast(
				'success',
				true,
				'Agreement Downloaded Successfully'
			)
		)
	} catch (error) {
		console.error(error)
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				'Error while downloading the agreement'
			)
		)
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(CreateAccountDuc.types.GET_SIGN_UP_FORM, getSignUpForm),
		takeLatest(CreateAccountDuc.types.SAVE_GENERAL_INFO, saveGeneralInfo),
		takeLatest(
			CreateAccountDuc.types.SAVE_DOCUMENTS_INFO,
			saveDocumentsInfo
		),
		takeLatest(CreateAccountDuc.types.UPLOAD_FILE, uploadFile),
		takeLatest(CreateAccountDuc.types.UPLOAD_AGREEMENT, uploadAgreement),
		takeLatest(
			CreateAccountDuc.types.SUBMIT_GENERAL_INFO,
			submitGeneralInfo
		),
		takeLatest(
			CreateAccountDuc.types.SUBMIT_DOCUMENTS_INFO,
			submitDocumentsInfo
		),
		takeLatest(
			CreateAccountDuc.types.SUBMIT_AGREEMENT_INFO,
			submitAgreementInfo
		),
		takeLatest(CreateAccountDuc.types.GET_STATE_LIST, getStateList),
		takeLatest(CreateAccountDuc.types.GET_BANK_LIST, getBankList),
		takeLatest(
			CreateAccountDuc.types.DOWNLOAD_AGREEMENT,
			downloadAgreement
		),
	])
}
