import Duck from 'extensible-duck'
import { merge, setIn, getIn } from 'timm'

const initialState = {
	detection: {
		isMobile: false,
		isTablet: false,
		isDesktop: false,
	},
}

export const DeviceDetectionDuc = new Duck({
	namespace: 'device',
	store: 'global',
	types: ['SYNC_DEVICE_DETECTION'],
	initialState,
	reducer: (state, action, duck) => {
		if (!action) return state
		const { type, detection } = action
		switch (type) {
			case duck.types.SYNC_DEVICE_DETECTION: {
				const existingDetection = getIn(state, ['detection']) || {}
				return setIn(
					state,
					['detection'],
					merge(existingDetection, detection)
				)
			}
			default:
				return state
		}
	},
	selectors: {
		device: state => state.device,
		detection: state => getIn(state, ['device', 'detection']) || {},
	},
	creators: duck => ({
		syncDeviceDetection: detection => ({
			type: duck.types.SYNC_DEVICE_DETECTION,
			detection,
		}),
	}),
})
