import { createComponent } from 'react-fela'
import Icon from 'bms-le-components/common/ui/Icon'
import theme from 'bms-le-components/utils/base-ui-theme'

export const Wrapper = createComponent(({ width, height, isError }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	background: '#F8F8F8',
	border: '1px solid ' + (isError ? theme.colors.errorRed : '#CCCCCC'),
	borderRadius: '4px',
	width,
	height,
}))

export const Content = createComponent(
	() => ({
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	}),
	'div',
	['onClick']
)

export const IconWrapper = createComponent(() => ({
	marginBottom: '15px',
}))

export const UploadIcon = createComponent(
	() => ({
		color: '#5E8AFF',
		cursor: 'pointer',
		width: '18px',
		height: '18px',
	}),
	Icon,
	['glyph']
)

export const TextWrapper = createComponent(() => ({
	fontSize: '14px',
	lineHeight: '20px',
	fontFamily: 'Roboto',
	color: '#5E8AFF',
	cursor: 'pointer',
}))

export const TextError = createComponent(() => ({
	fontSize: '14px',
	lineHeight: '20px',
	fontFamily: 'Roboto',
	color: '#EB4E62',
	textAlign: 'center',
	padding: '0 8px',
}))
