import { createComponent } from 'react-fela'

export const DesktopWrapper = createComponent(() => ({
	marginBottom: '30px',
	marginLeft: '11px',
	marginRight: '11px',
}))

export const MobileWrapper = createComponent(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	minHeight: `calc(100vh - 90px)`,
}))

export const TitleWrapper = createComponent(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}))

export const TextAlignWrapper = createComponent(
	() => ({
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
	}),
	'div',
	['onClick']
)

export const TitleText = createComponent(() => ({
	fontSize: '16px',
	fontWeight: 500,
	lineHeight: '20px',
	color: '#000000',
	marginBottom: '4px',
}))

export const SubTitleText = createComponent(({ upperCase }) => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: '#666666',
	textTransform: upperCase ? 'uppercase' : 'inherit',
	marginBottom: '12px',
}))

export const UploadWrapper = createComponent(() => ({
	display: 'flex',
	justifyContent: 'center',
}))

export const ImageWrapper = createComponent(() => ({
	position: 'relative',
	maxWidth: '100%',
	maxHeight: '210px',
	'> .overlay': {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		opacity: 0,
		color: '#FFF',
		':hover': {
			opacity: 1,
			background: 'rgba(0, 0, 0, 0.5)',
		},
	},
	'> .mobile-overlay': {
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		padding: '4px',
	},
}))

export const IconWrapper = createComponent(
	({ isDesktop }) => ({
		border: 'none',
		background: '#FFFFFF',
		height: isDesktop ? 'auto' : '36px',
		borderRadius: '50%',
		padding: '8px',
		cursor: 'pointer',
		'> svg': {
			width: isDesktop ? '22px' : '18px',
			height: isDesktop ? '22px' : '18px',
		},
	}),
	'button'
)

export const ButtonWrapper = createComponent(({ isDesktop }) => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	width: '100%',
	display: 'flex',
	backgroundColor: '#fff',
	padding: '7.5px 15px',
	justifyContent: 'center',
	boxShadow: '0px 0 5px rgba(0, 0, 0, 0.2)',
	extend: [
		{
			condition: isDesktop,
			style: {
				justifyContent: 'flex-end',
				paddingRight: '25vw',
				'>div': {
					margin: '0 20px',
				},
			},
		},
	],
}))
