import { useEffect } from 'react'

export const useOutsideClick = (ref, clickHandler) => {
	useEffect(() => {
		/**
		 * Call clickHandler if clicked outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				clickHandler()
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}
