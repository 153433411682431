import { isSameDay, isValid } from 'date-fns'

// helpers
import { calculateDatesBasedOnInterval } from 'utils/helpers'

export const getSessionInfoDates = (selectedVenues, recurringInfo) => {
	let newSessionInfo = {}

	Object.keys(recurringInfo).map(venueCode => {
		const venueData = recurringInfo[venueCode]
		Object.keys(venueData).map(data => {
			const selectedVenue = selectedVenues.find(
				venue => venue.venueCode === venueCode
			)
			if (isValid(new Date(data))) {
				const sessionWithSameDates = selectedVenue?.sessionInfo?.filter(
					session => {
						if (isValid(new Date(session?.showTime))) {
							return !!isSameDay(
								new Date(data),
								new Date(session?.showTime)
							)
						} else {
							return false
						}
					}
				)
				newSessionInfo = {
					...newSessionInfo,
					[venueCode]: {
						...newSessionInfo[venueCode],
						sessionInfo: getSessionInfoOnRangeOfDates(
							sessionWithSameDates,
							recurringInfo[venueCode][data]
						),
					},
				}
			}
		})
	})

	const updatedVenueInfo = selectedVenues.map(venue => {
		if (
			venue?.sessionInfo?.length &&
			isValid(new Date(venue?.sessionInfo?.[0]?.showTime))
		) {
			const prevSessionInfo = venue?.sessionInfo
				?.map(session => {
					if (
						session?.ticketInfo?.length &&
						isValid(new Date(session?.showTime))
					) {
						return {
							...session,
							sessionId:
								typeof session?.sessionId === 'string'
									? session?.sessionId
									: '0',
							showTime: new Date(session?.showTime).toISOString(),
						}
					}
					if (isValid(new Date(session?.showTime))) {
						return {
							...session,
							sessionId: '0',
							showTime: new Date(session.showTime).toISOString(),
						}
					}
				})
				.filter(session => session)
			return {
				...venue,
				sessionInfo: [
					...(prevSessionInfo || []),
					...(newSessionInfo[venue.venueCode]?.sessionInfo?.flat() ||
						[]),
				],
			}
		} else {
			return {
				...venue,
				sessionInfo: [],
			}
		}
	})
	return { venues: updatedVenueInfo, sessionInfo: newSessionInfo }
}

const getSessionInfoOnRangeOfDates = (sessions, recurringInfo) => {
	return sessions.map(session => {
		if (recurringInfo?.code && parseInt(recurringInfo?.noOfTimes)) {
			const getDates = calculateDatesBasedOnInterval(
				recurringInfo?.code,
				session.showTime,
				parseInt(recurringInfo?.noOfTimes) || 0
			)
			return getDates.map(data => ({
				sessionId: '0',
				status: true,
				showTime: new Date(data).toISOString(),
			}))
		}
	})
}

export const checkForDuplicateSessions = venues => {
	let isDuplicateFound = false
	for (let i = 0; i < venues.length; i++) {
		const sessions = venues[i]?.sessionInfo?.map(
			session => session?.showTime
		)
		if (new Set(sessions).size !== sessions?.length) {
			isDuplicateFound = true
			break
		}
	}
	return isDuplicateFound
}
