import { takeLatest, all, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

// duc
import DashboardDuc from 'modules/Dashboard/duc'
import { AppDuc } from 'modules/App/duc'

import { privateUCMSRequest, privateRequest } from 'utils/request'

function paramsSerializer(params) {
	return Object.entries(Object.assign({}, params))
		.map(([key, value]) => `${key}=${value}`)
		.join('&')
}

export function* getUserSecret(username) {
	try {
		const {
			data: { results, count },
		} = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/iam/v2/api/usersecrets/?user=${username}&project=diy`
			)
		)
		if (count >= 0 && Array.isArray(results)) {
			const organizerId = results.filter((i) => i.project === 'diy')?.[0]
				?.secret?.OrganizerId?.[0]
			return organizerId
		}
		yield put(
			AppDuc.creators.showPopup({
				title: 'Organiser not configured for the mention user',
				content: 'Kindly reach out IAM Team',
				nonCloseable: false,
				verticalPosition: 'middle',
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	} catch (err) {
		yield put(
			AppDuc.creators.showPopup({
				title: 'Sorry! Unable to process your request. Try again!!',
				content: err.message,
				nonCloseable: false,
				verticalPosition: 'middle',
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	}
}

export function* getMerchantDetails(organiserID) {
	try {
		const {
			data: {
				data: {
					vendorDetails: { tcsApplicable, merchantDetails },
				},
			},
		} = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/le-nontrans-is/bms/vendormaster/details?vendorId=${organiserID}`
			)
		)
		if (tcsApplicable === 'E') {
			return true
		}
		if (tcsApplicable === 'Y' && merchantDetails.length === 0) {
			yield put(
				AppDuc.creators.showPopup({
					title: 'TCS! Applicable but merchant details not configured.',
					content: 'Kindly upload the exception certificate',
					nonCloseable: false,
					verticalPosition: 'middle',
					closeAction: (dispatch) => {
						dispatch(AppDuc.creators.hidePopup())
					},
				})
			)
			return false
		}
		return true
	} catch (err) {
		yield put(
			AppDuc.creators.showPopup({
				title: 'Sorry! Unable to process your request. Try again!!',
				content: err.message,
				nonCloseable: false,
				verticalPosition: 'middle',
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
		return false
	}
}

export function* getEventUsername(etcode) {
	try {
		const {
			data: {
				data: { list },
			},
		} = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/nmcms/manage/search/events?q=${etcode}`
			)
		)
		let username = String(list?.[0]?.author).replace('-DIY', '')
		return username
	} catch (err) {
		yield put(
			AppDuc.creators.showPopup({
				title: 'Sorry! Unable to process your request. Try again!!',
				content: err.message,
				nonCloseable: false,
				verticalPosition: 'middle',
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	}
}

export function* getDashboardData(params) {
	// add url here
	try {
		let param = params?.payload?.params
		const search = params?.payload?.params.value
		if (String(search).includes('et00')) {
			const username = yield getEventUsername(search)
			param = { ...param, value: username }
		}
		yield put(DashboardDuc.creators.getDashboardDetailsInProgress())
		const { data } = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/iam/v2/api/users?${paramsSerializer(param)}`
			)
		)
		console.log(data)
		const { count, results } = data
		let users = []
		if (Array.isArray(results)) {
			users = yield all(
				results.map(function* (i) {
					const { username, email, firstName, lastName, active } = i
					let groups = []
					groups = yield getUserGroupData(username)
					return {
						username,
						email,
						emailID: email,
						organiserName: firstName,
						organiserId: lastName,
						organiserID: lastName,
						groups,
						active,
						contract: false,
					}
				})
			)
		}
		yield put(
			DashboardDuc.creators.getDashboardDetailsSuccess({ count, users })
		)
	} catch (err) {
		yield handleError(err)
	}
}

export function* getUserGroupData(username) {
	try {
		const { data } = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/iam/v2/api/usergroups?username=${username}`
			)
		)
		const { results } = data
		if (Array.isArray(results)) {
			return results.map((i) => {
				const { group } = i
				return group
			})
		}
		return []
	} catch {
		return []
	}
}

export function* getAccess(payload) {
	try {
		const { username } = payload?.payload || {}
		const organizerId = yield getUserSecret(username)
		const hasMerchant = yield getMerchantDetails(organizerId)
		if (hasMerchant) {
			yield call(() =>
				privateRequest.put(`/user/secret`, { username, organizerId })
			)
			yield put(push('/apps/diy/home'))
		}
	} catch (err) {
		yield handleError(err)
	}
}

export function* setAccess(payload) {
	try {
		const {
			emailID,
			username,
			organiserName,
			mobile,
			groups,
			contract,
			organiserId,
			createUser,
		} = payload?.payload || {}
		if (createUser) {
			privateRequest.post(`/organiser`, {
				username,
				profile: {
					mobile: username,
					email: emailID,
					name: organiserName,
				},
				business: {},
				bank: {},
				document: {},
				organiserid: organiserId,
			})
		} else {
			yield call(() =>
				privateRequest.post(`/user/access`, {
					emailID,
					username,
					mobile,
					contract,
					groups,
					organiserId,
				})
			)
		}
		yield put(
			AppDuc.creators.showPopup({
				title: `Success`,
				content: `DIY dashboard access provided to ${username} ${
					contract ? 'along with contract' : ''
				}`,
				nonCloseable: false,
				verticalPosition: 'middle',
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	} catch (err) {
		console.error(err)
		yield handleError(err)
	}
}

export function* handleError(err) {
	const message = err?.response?.data?.errors?.message || err.message
	yield put(
		AppDuc.creators.showPopup({
			title: 'Sorry! Unable to process your request. Try again!!',
			content: message,
			nonCloseable: false,
			verticalPosition: 'middle',
			closeAction: (dispatch) => {
				dispatch(AppDuc.creators.hidePopup())
			},
		})
	)
}

export default function* rootSaga() {
	yield all([
		takeLatest(DashboardDuc.types.GET_DASHBOARD, getDashboardData),
		takeLatest(DashboardDuc.types.GET_ACCESS, getAccess),
		takeLatest(DashboardDuc.types.SET_ACCESS, setAccess),
	])
}
