// import format from 'date-fns/esm/format'
import { isValid, format, isSameDay } from 'date-fns'

import { accessObjectValuesByStringPath } from 'utils/helpers'
import { INPUTREGEX } from './constants'

const formatDateTime = (time, formatString = 'dd MMM yyyy hh:mm a') => {
	return isValid(new Date(time)) ? format(new Date(time), formatString) : ''
}

const getSecs = (t) => {
	return t
		.split(':')
		.map(Number)
		.reduce((i, j, id) => (id ? i + j : j * 60 + i), 0)
}

const secondsToHM = (d) => {
	d = Number(d)
	const h = Math.floor(d / 60)
	const m = Math.floor(d % 60)

	const hDisplay = h > 0 ? h + (h === 1 ? ' hr' : ' hrs') : ''
	const mDisplay = m > 0 ? m + (m === 1 ? ' min' : ' mins') : ''
	return hDisplay + (hDisplay && mDisplay ? ', ' : '') + mDisplay
}

// const segmentNameMap = {
// 	eventInfo: 'eventInfo',
// }

const getTimeDuration = (start, end) => {
	const diff = getSecs(end) - getSecs(start)
	const duration = diff > 0 ? secondsToHM(diff) : '--'
	return duration
}

const errorInjector = (rejectionReasons, synopsisData) => {
	let parsedRejectionReasons = {}
	rejectionReasons.forEach(({ reasons, section, field }) => {
		let value = ''
		if (section === 'ticketInfo' && field) {
			const path = `${field?.split('.')[0]}.venueCode`
			value = accessObjectValuesByStringPath(synopsisData, path)
		} else if (field) {
			value = accessObjectValuesByStringPath(synopsisData, field)
		}
		parsedRejectionReasons[section] = [
			...(parsedRejectionReasons[section] || []),
			...[
				{
					...reasons[0],
					rejectedValue: value,
				},
			],
		]
	})
	return parsedRejectionReasons
}

export const validateInputFields = (type, value) => {
	const inputRegex = new RegExp(INPUTREGEX)

	switch (type) {
		case 'synopsis':
			return (
				inputRegex.exec(
					String(value).trim().replace('<p>', '').replace('</p>', '')
				)?.[0] !== ''
			)
		case 'tnc':
			return inputRegex.exec(value.trim())?.[0] !== ''
		case 'reasonsToAttend':
			if (typeof value === 'string') {
				return inputRegex.test(value.trim())
			}
			return value.reduce(
				(acc, input) => acc && inputRegex.test(input.trim()),
				true
			)
		default:
			return inputRegex.exec(value.trim())?.[0] !== ''
	}
}

export const ticketTypeValidationsRegex = new RegExp(
	'^[0-9a-zA-Z`~!@\\$%\\^\\*()\\-_\\+{}\\[\\];,\\.\\? ](?!.* {3})[0-9a-zA-Z`~!@\\$%\\^\\*()\\-_\\+{}\\[\\];,\\.\\? ]{2,24}$'
)

export const checkTicketValidations = (ticketDetails) => {
	let isInvalidTicket = false
	let isTicketTypeLength = false
	let isInvalidInventory = false

	for (let i = 0; i < ticketDetails?.length; i++) {
		if (
			ticketDetails?.[i]?.ticketTypeName &&
			ticketDetails?.[i]?.totalInventory > 0
		) {
			isInvalidTicket = false
		} else {
			isTicketTypeLength =
				ticketDetails?.[i]?.ticketTypeName?.length > 25 ||
				ticketDetails?.[i]?.ticketTypeName?.length < 2
			isInvalidTicket = true
			isInvalidInventory = !ticketDetails?.[i]?.totalInventory > 0
			break
		}
	}
	return {
		isInvalidTicket,
		isTicketTypeLength,
		isInvalidInventory,
	}
}

export const getTicketErrorMessageContent = (ticketDetails) => {
	if (ticketDetails?.isTicketTypeLength) {
		return 'Please use a ticket name with at least 2 characters and not more than 25 characters'
	} else if (ticketDetails?.isInvalidInventory) {
		return 'Please provide inventory value greater than 0'
	} else {
		return `Please do not use the following special characters in the ticket name 
		#&|:'<>/=`
	}
}

export const getUniqueDays = (sessionInfo) => {
	let uniqueDays = {}
	return sessionInfo.filter(({ showTime }) => {
		const identifier = new Date(showTime).toLocaleDateString()
		if (uniqueDays[identifier]) {
			return false
		} else {
			uniqueDays[identifier] = true
			return true
		}
	})
}

export const showsOnSameDay = (sessionInfo, date) => {
	return sessionInfo.filter((session) =>
		isSameDay(new Date(session.showTime), new Date(date))
	)
}

export const getNoOfNewSessAndTickets = (venueInfo) => {
	let numOfNewSessions = 0
	let numOfNewTickets = 0

	venueInfo?.forEach(({ sessionInfo }) =>
		sessionInfo?.forEach(({ sessionId, ticketInfo = [] }) => {
			if (sessionId === '0' || Number.isInteger(sessionId)) {
				// session is draft
				numOfNewSessions++
				numOfNewTickets += ticketInfo.length
				return
			}
			// session is published but ticket may be in draft
			ticketInfo.forEach(({ ticketTypeCode }) => {
				if (!ticketTypeCode) {
					// if ticket is in draft mode
					numOfNewTickets++
				}
			})
		})
	)

	return { numOfNewSessions, numOfNewTickets }
}

export const handleDataReload = () => {
	window.location.reload()
}

export const calculateMinTicketQty = (totalQty, availableQty) => {
	let percent
	if (totalQty <= 200) {
		percent = 0.9
	} else if (totalQty <= 1000) {
		percent = 0.95
	} else if (totalQty <= 5000) {
		percent = 0.97
	} else {
		percent = 0.99
	}

	// if user has already sold percent of inventory, then return total Qty
	// Math.round is done because of floating point precision issue
	if (availableQty <= (Math.round((1 - percent) * 100) / 100) * totalQty) {
		return totalQty
	}

	return Math.ceil(
		Math.max(totalQty - percent * availableQty, totalQty - availableQty + 2)
	)
}

export { getTimeDuration, formatDateTime, errorInjector }
