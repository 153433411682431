import device from 'common/icons/device.svg'
import time from 'common/icons/time.svg'
import monitor from 'common/icons/monitor.svg'

export const benefitsTitle =
	'Benefits of using-Do It Yourself our new event management tool'
export const benefitsData = [
	{
		title: 'Quick & easy registration',
		description:
			'Complete your registration with just your PAN card and bank details',
		icon: device,
	},
	{
		title: 'Take your events live superfast!',
		description:
			'Publish your event within just 15 minutes! Add event details, dates and ticket and BAM! Your event is ready.',
		icon: time,
	},
	{
		title: 'Monitor analytics & insights',
		description:
			'Track event sales, daily ticketing, get daily insights and more in real time.',
		icon: monitor,
	},
]

export const flowTitles = {
	signUp: "Don't have an account?",
	signIn: 'Already have an account?',
}

export const emailInfo = {
	description: 'Incase of any query, please write to',
	emailId: 'bd@bookmyshow.com',
}

export const mobileNoChangeValidationRegex = '^[0-9]{0,10}$'
export const otpChangeValidationRegex = '^[0-9]{0,6}$'

export const RESEND_OTP_TEXT = 'Resend OTP'
export const RESEND_TIMER_SUPPORT_TEXT = 'Resend OTP in'

export const genericErrorMesssage = 'Something went wrong. Please try again.'
