const __wrapper = ({ isDesktop }) => ({
	minWidth: 240,
	extend: [
		{
			condition: isDesktop,
			style: {
				width: '36vw',
			},
		},
		{
			condition: !isDesktop,
			style: {
				padding: '0 20px',
			},
		},
	],
})

const __header = () => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '5px 0',
	'> div:nth-child(1)': { flexGrow: 1 },
})

const __filterWrapper = () => ({
	borderTop: '1px solid #CCCCCC',
	margin: '10px 0 20px',
})

const __iconWrapper = () => ({ width: 25, height: 25, cursor: 'pointer' })

export { __wrapper, __header, __filterWrapper, __iconWrapper }
