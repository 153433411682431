import React from 'react'
import VenueTimeScheduler from 'modules/EventManagement/components/VenueTimeScheduler'

const MobileTimeScheduler = ({ isDesktop }) => {
	return (
		<>
			<VenueTimeScheduler isDesktop={isDesktop} />
		</>
	)
}

export default MobileTimeScheduler
