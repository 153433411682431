import Duck from 'extensible-duck'
import { setIn, getIn, merge } from 'timm'
const initialState = {
	loading: {
		pending: false,
		requestQueue: [],
	},
	cookies: {},
	query: {},
	config: {},
	popup: {},
	toast: {},
	showModal: false,
	errorPageSeoPromo: [],
	featuresList: [],
	uiState: '',
}

export const AppDuc = new Duck({
	namespace: 'app',
	store: 'global',
	types: [
		'SHOW_POPUP',
		'HIDE_POPUP',
		'SHOW_TOAST',
		'NO_ACTION_ANALYTICS',
		'TOGGLE_MODAL',
		'GET_FEATURES_LIST',
		'GET_FEATURES_LIST_SUCCESS',
		'GET_FEATURES_LIST_IN_PROGRESS',
		'GET_USER_PROFILE',
		'GET_USER_PROFILE_IN_PROGRESS',
		'SET_USER_DETAILS',
	],
	initialState,
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state
		switch (action.type) {
			case duck.types.SHOW_POPUP:
				if (!action.config) return state

				return setIn(state, ['popup'], {
					show: true,
					config: merge(action.config, { margin: 32 }),
				})

			case duck.types.HIDE_POPUP:
				return setIn(state, ['popup', 'show'], false)

			case duck.types.SHOW_TOAST:
				return setIn(state, ['toast'], action.params)

			case duck.types.TOGGLE_MODAL: {
				const { showModal } = state
				return setIn(state, ['showModal'], !showModal)
			}
			case duck.types.GET_FEATURES_LIST_SUCCESS:
				return {
					...state,
					featuresList: action?.payload?.data,
					uiState: 'SUCCESS',
					lodingFeaturesList: false,
				}
			case duck.types.GET_FEATURES_LIST_IN_PROGRESS:
				return {
					...state,
					uiState: 'IN_PROGRESS',
					lodingFeaturesList: true,
				}
			case duck.types.GET_USER_PROFILE_IN_PROGRESS:
				return {
					...state,
					lodingUserProfile: true,
				}
			case duck.types.SET_USER_DETAILS: {
				const { data } = action
				return {
					...state,
					...setIn(state, ['userDetails'], data),
					lodingUserProfile: false,
				}
			}
			default:
				return state
		}
	},

	selectors: {
		location: (state) => state.location,
		popUp: (state) => state.popUp,
		toast: (state) => state?.app?.toast || {},
		popupConfig: new Duck.Selector(
			(selectors) => (state) =>
				getIn(selectors.popUp(state), ['popup', 'config'])
		),
		displayPopup: new Duck.Selector(
			(selectors) => (state) =>
				getIn(selectors.popUp(state), ['popup', 'show']) || false
		),
		featuresList: (state) => state?.app?.featuresList,
		userDetails: (state) => state?.app?.userDetails || {},
	},

	creators: (duck) => ({
		showToast: (
			type,
			show = false,
			data,
			persists = false,
			timeout = 3000
		) => ({
			type: duck.types.SHOW_TOAST,
			params: {
				type,
				show,
				data,
				persists,
				timeout,
			},
		}),
		showPopup: (config) => ({
			type: duck.types.SHOW_POPUP,
			config,
		}),
		hidePopup: () => ({
			type: duck.types.HIDE_POPUP,
		}),
		NoActionAnalytics: () => ({
			type: duck.types.NO_ACTION_ANALYTICS,
		}),
		wrapAnalytics: (action, analytics) => ({
			...action,
			meta: { analytics },
		}),
		toggleModal: () => ({
			type: duck.types.TOGGLE_MODAL,
		}),
		getFeaturesList: () => ({
			type: duck.types.GET_FEATURES_LIST,
		}),
		getFeaturesListInProgress: () => ({
			type: duck.types.GET_FEATURES_LIST_IN_PROGRESS,
		}),
		getFeaturesListSuccess: (data) => ({
			type: duck.types.GET_FEATURES_LIST_SUCCESS,
			payload: {
				data,
			},
		}),
		getUserDetails: () => ({
			type: duck.types.GET_USER_PROFILE,
		}),
		getUserDetailsInProgress: () => ({
			type: duck.types.GET_USER_PROFILE_IN_PROGRESS,
		}),
		setUserDetails: (data) => ({
			type: duck.types.SET_USER_DETAILS,
			data,
		}),
	}),
})
