import React, { useState, useCallback, useRef, useEffect } from 'react'
import { bool, number, string, func } from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Button from 'bms-le-components/atoms/Button'
import { Wrapper, ButtonWrapper } from './__style'
import { getCroppedImg } from './helper'

const ImageCropper = ({
	imageSrc,
	aspectWidth,
	aspectHeight,
	isLocked,
	handleDone,
	handleCancel,
	isDesktop,
	onImageLoadedCallback,
	correctImageDimension,
}) => {
	const [cropInfo, setCropInfo] = useState({
		aspect: aspectWidth / aspectHeight,
		unit: '%',
		height: correctImageDimension ? 100 : 66.666,
	})

	useEffect(() => {
		setCropInfo({
			aspect: aspectWidth / aspectHeight,
			unit: '%',
			height: correctImageDimension ? 100 : 66.666,
		})
	}, [correctImageDimension])

	const [completedCropInfo, setCompletedCropInfo] = useState(null)
	const imgRef = useRef(null)

	const onImageLoaded = useCallback(img => {
		imgRef.current = img
		onImageLoadedCallback(img)
	}, [])

	const finishCropping = async () => {
		const [croppedFile, croppedFileUrl] = await getCroppedImg(
			imgRef,
			completedCropInfo,
			aspectWidth / aspectHeight
		)
		const img = new Image()
		img.addEventListener('load', function() {
			if (onImageLoadedCallback(this)) {
				handleDone(croppedFile, croppedFileUrl)
			}
		})
		img.src = croppedFileUrl
	}
	return (
		<Wrapper isDesktop={isDesktop}>
			<ReactCrop
				src={imageSrc}
				crop={cropInfo}
				locked={isLocked}
				onImageLoaded={onImageLoaded}
				ruleOfThirds
				onComplete={info => setCompletedCropInfo(info)}
				onChange={cropInfo => setCropInfo(cropInfo)}
			/>
			<ButtonWrapper isDesktop={isDesktop}>
				{isDesktop && (
					<Button
						onClick={handleCancel}
						style={{ margin: '0 auto' }}
						type="SECONDARY"
					>
						Cancel
					</Button>
				)}

				<Button onClick={finishCropping} isFullWidth={!isDesktop}>
					Done
				</Button>
			</ButtonWrapper>
		</Wrapper>
	)
}

ImageCropper.propTypes = {
	imageSrc: string.isRequired,
	aspectWidth: number.isRequired,
	aspectHeight: number.isRequired,
	cropWidth: number.isRequired,
	cropHeight: number.isRequired,
	isLocked: bool.isRequired,
	handleDone: func.isRequired,
	handleCancel: func.isRequired,
	isDesktop: bool.isRequired,
	correctImageDimension: bool.isRequired,
}

ImageCropper.defaultProps = {
	imageSrc: '',
	aspectWidth: 2,
	aspectHeight: 1,
	cropWidth: 300,
	cropHeight: 150,
	isLocked: true,
	handleDone: () => {},
	handleCancel: () => {},
	isDesktop: true,
	onImageLoadedCallback: () => {},
	correctImageDimension: false,
}

export default ImageCropper
