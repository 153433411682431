import { createComponent } from 'react-fela'

export const DesktopWrapper = createComponent(() => ({
	paddingBottom: '20px',
}))

export const MobileWrapper = createComponent(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	minHeight: `calc(100vh - 90px)`,
}))

export const TextLabel = createComponent(() => ({
	fontWeight: 500,
	fontSize: '12px',
	lineHeight: '16px',
	color: '#666',
	paddingBottom: '6px',
	marginTop: '8px',
}))

export const TextError = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: '#CD0000',
}))

export const TextDescription = createComponent(() => ({
	marginTop: '-20px',
	marginLeft: '16px',
	marginRight: '16px',
	fontFamily: 'Roboto',
	fontSize: '16px',
	lineHeight: '24px',
	color: '#222222',
}))
