import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, isLoggedIn, isSignedUp, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isLoggedIn || isSignedUp ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	)
}

const mapStateToProps = ({ login }) => ({
	isLoggedIn: login.isLoggedIn,
	isSignedUp: login.isSignedUp,
})

export default connect(mapStateToProps)(withRouter(PrivateRoute))
