import theme from 'bms-le-components/utils/base-ui-theme'

export const __confirmNewShowsWrapper = () => ({
	marginTop: theme.spaces[16],
})

export const __confirmNewShowsVenueName = () => ({
	fontSize: theme.font.size12,
	lineHeight: '16px',
	padding: theme.spaces[4],
	borderTop: '1px solid ' + theme.color.whiteSmoke,
	borderBottom: '1px solid ' + theme.color.whiteSmoke,
	marginBottom: theme.spaces[12],
	textAlign: 'center',
	color: theme.colors.grey5,
})

export const __confirmNewShowsShowtimeWrapper = () => ({
	marginBottom: theme.spaces[24],
})

export const __confirmNewShowsShowtime = () => ({
	fontSize: theme.font.size14,
	lineHeight: '20px',
	color: theme.colors.darkGrey5,
	marginBottom: theme.spaces[8],

	'> span': {
		color: theme.colors.darkGrey7,
		fontWeight: 500,
	},
})

export const __confirmNewShowsButtonWrapper = () => ({
	marginLeft: theme.spaces[16],
	width: '100%',
})

export const __buttonWrapper = () => ({
	display: 'flex',
	margin: '0 ' + theme.spaces[16],
})
