import { createComponent } from 'react-fela'

export const DesktopWrapper = createComponent(() => ({
	paddingBottom: '20px',
}))

export const MobileWrapper = createComponent(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	minHeight: `calc(100vh - 90px)`,
}))

export const ReasonWrapper = createComponent(() => ({
	marginBottom: '20px',
}))

export const TextWrapper = createComponent(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}))

export const TextLabel = createComponent(() => ({
	fontWeight: 500,
	fontSize: '12px',
	lineHeight: '16px',
	color: '#666',
	paddingBottom: '6px',
}))

export const TextError = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: '#CD0000',
}))

export const CharacterLimitText = createComponent(({ status }) => ({
	fontWeight: 500,
	fontSize: '12px',
	lineHeight: '16px',
	color:
		status === 'default'
			? '#666'
			: status === 'success'
			? '#1da83c'
			: '#CD0000',
	paddingBottom: '6px',
}))

export const TextArea = createComponent(
	() => ({
		width: '100%',
		fontFamily: 'Roboto',
		fontSize: '14px',
		lineHeight: '16px',
		padding: '12px',
		borderRadius: '2px',
		resize: 'none',
		border: '1px solid #CCC',
	}),
	'textarea',
	['value', 'onChange', 'placeholder']
)

export const TextReasons = createComponent(() => ({
	marginLeft: '16px',
	marginRight: '16px',
	fontFamily: 'Roboto',
	fontSize: '16px',
	lineHeight: '24px',
	color: '#222222',
}))

export const List = createComponent(
	() => ({
		margin: '0px',
		paddingInlineStart: '20px',
		wordBreak: 'break-word',
	}),
	'ul'
)

export const ListItem = createComponent(
	() => ({
		fontFamily: 'Roboto',
		fontSize: '16px',
		lineHeight: '24px',
		color: '#222222',
	}),
	'li'
)

export const BottomNavBar = createComponent(({ theme, fixed }) => ({
	position: fixed ? 'fixed' : 'relative',
	bottom: '0',
	left: '0',
	right: '0',
	width: '100%',
	backgroundColor: theme.colors.white,
	borderTop: theme.border,
	padding: '15px',
	zIndex: '200',
}))
