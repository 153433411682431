import React from 'react'
import { withRouter } from 'react-router-dom'
import { isValid } from 'date-fns'

import TicketVenueCard from 'modules/EventManagement/components/Tickets/TicketVenueCard'
import TimeScheduler from 'modules/EventManagement/components/VenueTimeScheduler'
import ActionBar from 'common/components/ActionBar'
import Typography from 'bms-le-components/atoms/Typography'

import { formatDateTime } from 'modules/EventManagement/helpers'
import { EVENT_STATUS } from 'modules/EventManagement/helpers/constants'

const onlineVenueMapper = {
	vod: 'Video on Demand',
	zoom: 'Live Streaming',
	liveStreaming: 'Live Streaming',
	externalPaid: 'Live Streaming',
}

const SelectedDateTimeView = (props) => {
	const {
		selectedVenues,
		handleActionClick,
		setCurrentVenue,
		isDesktop,
		onDateChange,
		disableEdit,
		venueSubType,
		disableSessions,
		location,
		eventCurrentStatus,
	} = props
	const initiateTimeScheduler = (venue) => {
		setCurrentVenue(venue)
		handleActionClick()
	}

	const addSession = JSON.parse(
		new URLSearchParams(location?.search).get('addSession') || 'false'
	)

	const actionHeaderText = () => {
		if (addSession && eventCurrentStatus !== EVENT_STATUS.PAST) {
			return isDesktop ? '' : '+ ADD'
		}
		if (!disableEdit) {
			return 'EDIT'
		}
		return ''
	}

	return (
		<>
			{selectedVenues &&
				selectedVenues?.map((venue, index) => (
					<div
						key={venue.venueCode}
						style={{
							width: '100%',
							...(!isDesktop && {
								paddingLeft: '16px',
								paddingRight: '16px',
								marginBottom: '8px',
							}),
						}}
					>
						<TicketVenueCard
							titleText={
								venueSubType
									? `${venue?.venueName} (${onlineVenueMapper?.[venueSubType]})`
									: venue.venueName
							}
							footerText=""
							actionText=""
							headerActionText={actionHeaderText()}
							headerActionClick={() =>
								initiateTimeScheduler(venue)
							}
							subTitleText={
								venue.sessionInfo &&
								venue.sessionInfo
									.filter((sess) =>
										isValid(new Date(sess?.showTime))
									)
									.map((session) => ({
										left: formatDateTime(session?.showTime),
									}))
							}
						/>
						{isDesktop ? (
							<TimeScheduler
								isDesktop={isDesktop}
								venue={venue}
								disabled={disableSessions}
								isFirstVenue={!index}
							/>
						) : (
							<>
								{!venue.sessionInfo?.length && (
									<div
										style={{
											background: '#ECF5FF',
											paddingBottom: '4px',
										}}
									>
										<ActionBar
											placeholder="Enter date and time"
											type="calendar"
											handleClick={() => {
												setCurrentVenue(venue)
												onDateChange(venue)
											}}
											key={venue.venueCode}
										/>
									</div>
								)}
							</>
						)}
						{disableSessions && (
							<Typography
								variant="body2"
								tag="div"
								styles={{ margin: '16px' }}
								color="darkGrey5"
							>
								(To add or edit the showtimes for this event,
								reach out to &nbsp;
								<a
									href="mailto:diy.support@bookmyshow.com"
									target="_blank"
									rel="noopener noreferrer"
								>
									diy.support@bookmyshow.com
								</a>
								)
							</Typography>
						)}
					</div>
				))}
		</>
	)
}

export default withRouter(SelectedDateTimeView)
