import React from 'react'
import { useDropzone } from 'react-dropzone'
import { string, func } from 'prop-types'
import {
	Wrapper,
	Content,
	IconWrapper,
	UploadIcon,
	TextWrapper,
	TextError,
} from './__style'
import Upload from 'common/icons/Upload.svg'

function ImageUpload({ width, height, uploadText, errorText, handleUpload }) {
	const onDrop = React.useCallback((acceptedFiles) => {
		handleUpload(acceptedFiles)
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/*',
	})

	return (
		<Wrapper width={width} height={height} isError={!!errorText}>
			<Content {...getRootProps({ refKey: 'innerRef' })} key={uploadText}>
				<input {...getInputProps()} />
				<IconWrapper>
					<UploadIcon glyph={Upload} />
				</IconWrapper>
				<TextWrapper>{uploadText}</TextWrapper>
			</Content>
			{!!errorText && <TextError>{errorText}</TextError>}
		</Wrapper>
	)
}

export default ImageUpload

ImageUpload.propTypes = {
	uploadText: string.isRequired,
	width: string.isRequired,
	height: string.isRequired,
	errorText: string,
	handleUpload: func.isRequired,
}

ImageUpload.defaultProps = {
	uploadText: 'Upload Image',
	width: '420px',
	height: '210px',
	errorText: '',
	handleUpload: () => {},
}
