import OffersSaga from '../modules/Offers/saga'
import LoginSaga from '../modules/Login/saga'
import ScanningApp from 'modules/ScanningApp/saga'
import EventManagement from 'modules/EventManagement/saga'
import Dashboard from 'modules/Dashboard/saga'
import AppSaga from 'modules/App/saga'
import OrganiserApprovalSaga from 'modules/OrganiserApproval/saga'
import CreateAccountSaga from 'modules/CreateAccount/saga'

import { spawn, all, call } from 'redux-saga/effects'

// Watcher sagas for every module stack here
const sagas = [
	OffersSaga,
	LoginSaga,
	ScanningApp,
	EventManagement,
	Dashboard,
	AppSaga,
	OrganiserApprovalSaga,
	CreateAccountSaga,
]

// spawn -- independent tasks (detached from parent)
export default function* RootSaga() {
	yield all(
		sagas.map((saga) =>
			spawn(function* () {
				while (true) {
					try {
						yield call(saga)
						break
					} catch (e) {
						console.error('Saga failed', e)
					}
				}
			})
		)
	)
}
