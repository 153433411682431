import axios from 'axios'
import { M5 } from './storage'
import { API_ENDPOINT, MOCK_ENDPOINT, TIER } from '../constants'
import { logOut } from 'modules/Login/saga'

const headers = {
	'X-BMS-LE-App-Code': 'DIY',
	'X-Frame-Options': 'sameorigin',
}

// const timeout = TIER === 'SIT' ? 25000 : 15000
const timeout = 0
const { IAM } = window
const baseURL = API_ENDPOINT
const mockUrl = MOCK_ENDPOINT

export const request = axios.create({ headers, timeout, baseURL })

export const mockRequest = axios.create({ headers, timeout, baseURL: mockUrl })

export const privateUCMSRequest = axios.create({
	headers: {
		...headers,
		Authorization: {
			toString() {
				return (
					IAM?.token() ||
					`Bearer ${M5.Storage.get({ name: 'accessToken' })}`
				)
			},
		},
	},
	timeout,
})

export const privateRequest = axios.create({
	headers: {
		...headers,
		Authorization: {
			toString() {
				return (
					IAM?.token() ||
					`Bearer ${M5.Storage.get({ name: 'accessToken' })}`
				)
			},
		},
	},
	timeout,
	baseURL,
})

privateRequest.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			error?.response?.data?.status === 401 ||
			error?.response?.data?.status === 403
		) {
			M5.Storage.del({ name: 'accessToken' })
			logOut()
		}
		return Promise.reject(error)
	}
)

// Axios will corrupt the binary data before handing over to us.
// using fetch instead of axios to prevent that.
export const downloadRequest = (url, body) => {
	return fetch(baseURL + url, {
		method: 'POST',
		headers: {
			...headers,
			Authorization: {
				toString() {
					return `Bearer ${M5.Storage.get({ name: 'accessToken' })}`
				},
			},
		},
		body: JSON.stringify(body),
	})
}

export const privateDraftServiceRequest = axios.create({
	headers: {
		...headers,
		Authorization: {
			toString() {
				return (
					IAM?.token() ||
					`Bearer ${M5.Storage.get({ name: 'accessToken' })}`
				)
			},
		},
		'X-User-Key': {
			toString() {
				return (
					IAM?.token() ||
					`Bearer ${M5.Storage.get({ name: 'accessToken' })}`
				)
			},
		},
	},
	timeout,
	baseURL,
})

export const newUserRequest = axios.create({
	headers: {
		...headers,
		Authorization: {
			toString() {
				return `Bearer ${M5.Storage.get({
					name: 'newUserAccessToken',
				})}`
			},
		},
	},
	timeout,
	baseURL,
})

// Axios will corrupt the binary data before handing over to us.
// using fetch instead of axios to prevent that.
export const downloadFile = (url) => {
	return fetch(baseURL + url, {
		method: 'GET',
		headers: {
			...headers,
			Authorization: {
				toString() {
					return `Bearer ${M5.Storage.get({
						name: 'newUserAccessToken',
					})}`
				},
			},
		},
	})
}

export const getFile = (url, customHeader) => {
	return fetch(baseURL + url, {
		method: 'GET',
		headers: {
			...headers,
			...customHeader,
			Authorization: {
				toString() {
					return `Bearer ${M5.Storage.get({
						name: 'newUserAccessToken',
					})}`
				},
			},
		},
	})
}
