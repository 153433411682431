import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'

// components
import SearchBar from 'bms-le-components/components/SearchBar'
import { useOutsideClick } from './useOutsideClick'
import DesktopWrapper from 'bms-le-components/components/DesktopWrapper'
import ButtonDesignLang from 'bms-le-components/components/ButtonDesignLang'
import Chips from 'bms-le-components/components/Chips'
import theme from 'bms-le-components/utils/base-ui-theme'

// styled components
import {
	VenuePlaceHolder,
	VenueLabel,
	VenueName,
	SelectedChipsHolder,
	SearchWrapper,
	SelectorWrapper,
	ListingWrapper,
	ChipsWrapper,
} from './__styles'
import { BottomNavBar } from 'modules/EventManagement/components/ManageEvent/FirstTimeUser/__style'

const LanguageSelector = (props) => {
	const {
		isDesktop,
		categories,
		getLanguageCategories,
		handleModalToggle,
		updateSynopsis,
		selected,
		handleLanguageSave,
		disableEdit,
		analyticsPush,
		isBottomDrawer,
	} = props

	const [showListing, toggleListing] = useState(!disableEdit)
	const [searchValue, setSearchValue] = useState('')
	const wrapperRef = useRef(null)
	const getLanguages = useCallback(
		debounce((value) => getLanguageCategories(value), 600),
		[]
	)

	useEffect(() => {
		getLanguageCategories()
		listingToggleHandler(false)
	}, [])

	const listingToggleHandler = (val) => {
		if (isDesktop && !disableEdit) {
			toggleListing(val)
		}
	}

	useOutsideClick(wrapperRef, () => listingToggleHandler(false))

	const handleClick = (currentItem) => {
		if (!selected.find((item) => item.code === currentItem.code)) {
			analyticsPush({
				event_name: 'event_language_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'language_selected',
				screen_name: 'event_language_selection',
			})
			setSearchValue('')
			getLanguageCategories()
			updateSynopsis(
				['additionalInfo', 'languages'],
				selected.concat(currentItem)
			)
		}
	}

	const removeCategory = (code) => {
		updateSynopsis(
			['additionalInfo', 'languages'],
			selected.filter((ele) => ele.code !== code)
		)
	}

	const handleProceed = () => {
		updateSynopsis(['additionalInfo', 'languages'], selected)
		handleLanguageSave(selected)
		handleModalToggle()
	}

	const popularCategories = useMemo(
		() =>
			categories.filter(
				({ trending, code }) =>
					trending && !selected.find((item) => item.code === code)
			),
		[categories, selected]
	)

	const categoryList = useMemo(
		() =>
			categories.filter(
				({ trending, code }) =>
					!(trending || selected.find((item) => item.code === code))
			),
		[categories, selected]
	)

	return (
		<>
			{selected.length > 0 && (
				<SelectedChipsHolder
					isDesktop={isDesktop}
					isBottomDrawer={isBottomDrawer}
				>
					{selected.map(({ name, code }) => (
						<ChipsWrapper key={name}>
							<Chips
								handleDeletion={() => removeCategory(code)}
								text={name}
								chipStyles={{
									marginRight: theme.spaces[10],
									padding: `${theme.spaces[16]} ${theme.spaces[8]}`,
									borderRadius: '6px',
								}}
								labelStyles={{
									marginRight: theme.spaces[20],
									padding: '0px',
									fontSize: theme.font.size14,
									fontWeight: theme.font.weight500,
								}}
								iconStyles={{ margin: '0px' }}
							/>
						</ChipsWrapper>
					))}
				</SelectedChipsHolder>
			)}
			<div ref={wrapperRef}>
				<SearchWrapper
					isDesktop={isDesktop}
					onFocus={() => listingToggleHandler(true)}
				>
					<SearchBar
						placeholder="Select Language (Optional)"
						removeCancelButton
						handleChange={(value) => {
							setSearchValue(value)
							getLanguages(value)
						}}
						zIndex="1"
						renderValueViaProp
						value={searchValue}
					/>
				</SearchWrapper>
				<SelectorWrapper
					isDesktop={isDesktop}
					isFocused={showListing}
					tabIndex={1}
				>
					{showListing && (
						<ListingWrapper
							isDesktop={isDesktop}
							style={{ zIndex: '99' }}
						>
							{popularCategories.length > 0 && (
								<div>
									<VenueLabel>Popular Languages</VenueLabel>
									{popularCategories.map((item) => (
										<VenuePlaceHolder
											id={item.code}
											name={item.name}
											onClick={() => handleClick(item)}
											key={item.code}
										>
											<VenueName type="category">
												{item.name}
											</VenueName>
										</VenuePlaceHolder>
									))}
								</div>
							)}
							<div>
								<VenueLabel>All Languages</VenueLabel>
								{categoryList.map((item) => (
									<VenuePlaceHolder
										id={item.code}
										onClick={() => handleClick(item)}
										key={item.code}
									>
										<VenueName type="category">
											{item.name}
										</VenueName>
									</VenuePlaceHolder>
								))}
							</div>
						</ListingWrapper>
					)}
					{!isDesktop && (
						<BottomNavBar fixed>
							<DesktopWrapper customStyles={{ width: '224px' }}>
								<ButtonDesignLang
									center
									largeBtn
									fullWidth={!isDesktop}
									disabled={false}
									onClick={handleProceed}
								>
									Proceed
								</ButtonDesignLang>
							</DesktopWrapper>
						</BottomNavBar>
					)}
				</SelectorWrapper>
			</div>
		</>
	)
}

export default LanguageSelector
