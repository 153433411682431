import { takeLatest, all, call, put } from 'redux-saga/effects'

// duc
import { AppDuc } from 'modules/App/duc'

import { privateRequest } from 'utils/request'

export function* getFeaturesList() {
	try {
		yield put(AppDuc.creators.getFeaturesListInProgress())
		const { data } = yield call(() => privateRequest.get('/user/features'))
		yield put(AppDuc.creators.getFeaturesListSuccess(data?.data?.features))
	} catch (err) {
		yield put(AppDuc.creators.getFeaturesListSuccess([]))
		if (err?.response?.data?.status !== 401) {
			yield call(() =>
				handleError({
					title: err?.response?.data?.errors?.message
						? 'Oops'
						: 'Network connection error',
					content:
						err?.response?.data?.errors?.message ||
						'Cannot connect to the internet. Please check your connection and try again.',
					verticalPosition: 'middle',
					nonCloseable: true,
					buttons: [
						{
							text: 'Retry',
							action: (dispatch) => {
								dispatch(AppDuc.creators.hidePopup())
								dispatch(AppDuc.creators.getFeaturesList())
							},
						},
					],
				})
			)
		}
	}
}

export function* getUserDetails() {
	try {
		yield put(AppDuc.creators.getUserDetailsInProgress())
		const { data } = yield call(() => privateRequest.get('/user/profile'))
		if (data.status === 200) {
			yield put(AppDuc.creators.setUserDetails(data?.data))
		}
	} catch (err) {
		yield call(() =>
			handleError({
				title: 'Error fetching user details',
				content:
					err?.response?.data?.errors?.message ||
					'User list cannot be fetched from server',
				verticalPosition: 'middle',
				nonCloseable: true,
				buttons: [
					{
						text: 'Retry',
						action: (dispatch) => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(AppDuc.creators.getUserDetails())
						},
					},
				],
			})
		)
	}
}

export function* handleError(params) {
	yield put(
		AppDuc.creators.showPopup({
			closeAction: (dispatch) => {
				dispatch(AppDuc.creators.hidePopup())
			},
			verticalPosition: 'middle',
			nonCloseable: false,
			...params,
		})
	)
}

export default function* rootSaga() {
	yield all([takeLatest(AppDuc.types.GET_FEATURES_LIST, getFeaturesList)])
	yield all([takeLatest(AppDuc.types.GET_USER_PROFILE, getUserDetails)])
}
