import React, { useState, useEffect, useMemo, useRef } from 'react'

import SearchBar from 'bms-le-components/components/SearchBar'
import Chips from 'bms-le-components/components/Chips'
import DesktopWrapper from 'bms-le-components/components/DesktopWrapper'
import ButtonDesignLang from 'bms-le-components/components/ButtonDesignLang'
import theme from 'bms-le-components/utils/base-ui-theme'

// custom hooks
import { useOutsideClick } from './useOutsideClick'

// styled components
import {
	VenuePlaceHolder,
	VenueLabel,
	VenueName,
	SelectedChipsHolder,
	SearchWrapper,
	SelectorWrapper,
	ListingWrapper,
	NoContentWrapper,
} from './__styles'
import { BottomNavBar } from 'modules/EventManagement/components/ManageEvent/FirstTimeUser/__style'

const AgeSelector = (props) => {
	const {
		isDesktop,
		categories,
		handleModalToggle,
		updateSynopsis,
		handleAgeLimitSave,
		selected,
		disableEdit,
		analyticsPush,
		isBottomDrawer,
	} = props

	const [showListing, toggleListing] = useState(!disableEdit)
	const [searchValue, setSearchValue] = useState('')
	const wrapperRef = useRef(null)

	useEffect(() => {
		listingToggleHandler(false)
	}, [])

	const listingToggleHandler = (val) => {
		if (isDesktop && !disableEdit) {
			toggleListing(val)
		}
	}

	useOutsideClick(wrapperRef, () => listingToggleHandler(false))

	const handleClick = (code) => {
		if (selected.indexOf(code) === -1) {
			analyticsPush({
				event_name: 'event_age_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'age_selected',
				screen_name: 'event_age_selection',
			})
			setSearchValue('')
			updateSynopsis(['additionalInfo', 'ageLimit'], code)
		}
	}

	const removeCategory = () => {
		updateSynopsis(['additionalInfo', 'ageLimit'], '')
	}

	const handleProceed = () => {
		updateSynopsis(['additionalInfo', 'ageLimit'], selected)
		handleAgeLimitSave(selected)
		handleModalToggle()
	}

	const handleValueChange = (value) => {
		setSearchValue(value)
	}

	const categoryList = useMemo(
		() => categories.filter(({ code }) => selected.indexOf(code) === -1),
		[categories, selected]
	)
	return (
		<>
			{selected && (
				<SelectedChipsHolder
					isDesktop={isDesktop}
					isAgeLimitChips
					isBottomDrawer={isBottomDrawer}
				>
					<Chips
						handleDeletion={removeCategory}
						text={selected}
						key={selected}
						chipStyles={{
							marginRight: theme.spaces[10],
							padding: `${theme.spaces[16]} ${theme.spaces[8]}`,
							borderRadius: '6px',
						}}
						labelStyles={{
							marginRight: theme.spaces[20],
							padding: '0px',
							fontSize: theme.font.size14,
							fontWeight: theme.font.weight500,
						}}
						iconStyles={{ margin: '0px' }}
					/>
				</SelectedChipsHolder>
			)}
			<div ref={wrapperRef}>
				<SearchWrapper
					isDesktop={isDesktop}
					onFocus={() => listingToggleHandler(true)}
				>
					<SearchBar
						placeholder="Set age limit for the audience (Optional)"
						readOnly
						removeCancelButton
						zIndex="1"
						renderValueViaProp
						handleChange={handleValueChange}
						value={searchValue}
					/>
				</SearchWrapper>
				<SelectorWrapper
					isDesktop={isDesktop}
					isFocused={showListing}
					tabIndex={2}
				>
					{showListing && (
						<ListingWrapper
							isDesktop={isDesktop}
							style={{ zIndex: '99' }}
						>
							{categoryList.length > 0 ? (
								<>
									<VenueLabel>All Age Categories</VenueLabel>
									{categoryList.map(({ name, code }) => {
										if (
											searchValue.length > 0 &&
											name.includes(searchValue)
										) {
											return (
												<VenuePlaceHolder
													id={code}
													onClick={() => {
														if (!selected) {
															handleClick(code)
														}
													}}
													key={code}
												>
													<VenueName
														type="category"
														disabled={selected}
													>
														{name}
													</VenueName>
												</VenuePlaceHolder>
											)
										} else if (searchValue.length === 0) {
											return (
												<VenuePlaceHolder
													id={code}
													onClick={() => {
														if (!selected) {
															handleClick(code)
														}
													}}
													key={code}
												>
													<VenueName
														type="category"
														disabled={selected}
													>
														{name}
													</VenueName>
												</VenuePlaceHolder>
											)
										}
									})}
								</>
							) : (
								<NoContentWrapper>
									Nothing to show.
								</NoContentWrapper>
							)}
						</ListingWrapper>
					)}
					{!isDesktop && (
						<BottomNavBar fixed>
							<DesktopWrapper customStyles={{ width: '224px' }}>
								<ButtonDesignLang
									center
									largeBtn
									fullWidth={!isDesktop}
									disabled={false}
									onClick={handleProceed}
								>
									Proceed
								</ButtonDesignLang>
							</DesktopWrapper>
						</BottomNavBar>
					)}
				</SelectorWrapper>
			</div>
		</>
	)
}

export default AgeSelector
