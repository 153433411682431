import { createComponent } from 'react-fela'

const commonModalHeaderStyle = {
	fontSize: 20,
	fontWeight: 500,
	lineHeight: 1.2,
	height: 24,
}

const ModalHeader = createComponent(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	marginBottom: 8,
}))

const ModalTitle = createComponent(({ theme, centerAligned }) => ({
	...commonModalHeaderStyle,
	color: theme.colors.darkGrey7,
	margin: centerAligned ? '0 auto' : '0px',
	textAlign: centerAligned ? 'center' : 'left',
}))

const CloseBtn = createComponent(
	({ theme }) => ({
		...commonModalHeaderStyle,
		color: theme.colors.darkGrey5,
		cursor: 'pointer',
		fontSize: theme.font.size16,
		':hover': {
			fontWeight: theme.font.weight500,
			color: theme.colors.black,
		},
	}),
	'div',
	['onClick']
)

export { ModalHeader, ModalTitle, CloseBtn }
