import * as React from 'react'

// components
import Loader from 'bms-le-components/components/Loader'

const LazyLoad = ({ children }) => {
	return (
		<React.Suspense fallback={<Loader active />}>{children}</React.Suspense>
	)
}

export default LazyLoad
