import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { TIER } from './constants'
import StyleProvider from 'bms-le-components/utils/Provider'
import { Provider, ReactReduxContext } from 'react-redux'
import configureAppStore, { history } from './store'
import App from './modules/App'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'

// global styles
import './index.css'

// Initialize Sentry SDK
if (process.env.NODE_ENV === 'production') {
	const key = 'REACT_APP_SENTRY_DSN_'.concat(TIER)
	Sentry.init({
		dsn: process.env[key],
		environment: TIER,
	})
}

if (window?.IAM && window?.IAM?.tier !== 'ote') {
	const { BASE_STATIC_URL = '' } = window

	// eslint-disable-next-line no-undef
	__webpack_public_path__ = BASE_STATIC_URL
}

const store = configureAppStore()

ReactDOM.render(
	<Provider store={store} context={ReactReduxContext}>
		<ConnectedRouter history={history}>
			<StyleProvider>
				<App history={history} context={ReactReduxContext} />
			</StyleProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
	module.hot.accept()
}
