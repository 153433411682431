import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import EventManagerDuc from 'modules/EventManagement/duc'
import Editor from 'common/components/Editor'
import {
	DesktopWrapper,
	MobileWrapper,
	TextLabel,
	TextError,
	TextDescription,
	ButtonWrapper,
} from './__style'
import Button from 'bms-le-components/atoms/Button'
import ErrorAlert from 'common/components/ErrorAlert'
import theme from 'bms-le-components/utils/base-ui-theme'

//helpers
import { validateInputFields } from 'modules/EventManagement/helpers'

const MAX_LENGTH = 25000

function EventTNC({
	isDesktop,
	draftId,
	eventCode,
	tnc,
	updateSynopsis,
	updateEventData,
	buttonLoading,
	eventNotification,
	rejectionReasons,
	tncRefreshedOn,
	showSaveButton,
}) {
	const { section } = useParams()
	const history = useHistory()

	/** Strip HTML tags to calculate length */
	const tncLength = tnc ? tnc.replace(/(<([^>]+)>)/gi, '').length : 0

	const Wrapper = isDesktop ? DesktopWrapper : MobileWrapper

	const onChange = (value, _, source) => {
		const tnc = value.slice(0, MAX_LENGTH)
		if (source === 'user') updateSynopsis('tnc', tnc)
	}

	const onSave = () => {
		updateEventData(draftId, eventCode, 'tnc', tnc)

		if (!isDesktop && validateInputFields('tnc', tnc)) history.goBack()
	}

	return (
		<Wrapper>
			<div>
				{rejectionReasons.length > 0 && (
					<ErrorAlert
						errorMessages={rejectionReasons}
						isDesktop={isDesktop}
					/>
				)}
				<TextLabel>Add Terms & Conditions for Your Attendees</TextLabel>
				<Editor
					key={tncRefreshedOn}
					value={tnc}
					onChange={onChange}
					showToolbar={true}
					editRegionHeight="80px"
					placeholder="Enter a brief description of the show"
					style={{
						border:
							tnc &&
							(tnc.length === MAX_LENGTH ||
								(tnc.length !== MAX_LENGTH &&
									!validateInputFields('tnc', tnc)))
								? '1px solid ' + theme.colors.errorRed
								: null,
					}}
				/>
				{tnc && tnc.length === MAX_LENGTH ? (
					<TextError>Character limit reached</TextError>
				) : null}
				{tnc &&
				tnc.length !== MAX_LENGTH &&
				!validateInputFields('tnc', tnc) ? (
					<TextError>{`Only these characters A-Za-z0-9,.(){}~[]<>?;:'"!@#$%^&*-=_+ are allowed`}</TextError>
				) : null}
				<TextDescription>
					These are the standard terms and conditions for an event. We
					recommend that you read and edit these to suit your event.
				</TextDescription>
			</div>
			{(!isDesktop || (isDesktop && section && showSaveButton)) && (
				<ButtonWrapper isDesktop={isDesktop}>
					<Button
						style={{ margin: '0 auto' }}
						size="LARGE"
						onClick={onSave}
						isFullWidth={!isDesktop}
						disabled={
							!tncLength || eventNotification?.type === 'review'
						}
						isLoading={buttonLoading}
					>
						Save
					</Button>
				</ButtonWrapper>
			)}
		</Wrapper>
	)
}

const mapStateToProps = ({ device, eventManager }) => ({
	isDesktop: device.detection.isDesktop,
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	tnc: eventManager.activeEvent.tnc || '',
	buttonLoading: eventManager?.buttonLoading,
	eventNotification: eventManager?.eventNotification,
	showSaveButton: eventManager?.showSaveButton,
	tncRefreshedOn: eventManager?.activeEvent?.tncRefreshedOn || '',
	rejectionReasons:
		eventManager.activeEvent.formattedRejectionReasons?.tnc || [],
})

const mapDispatchToProps = (dispatch) => ({
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
})
export default connect(mapStateToProps, mapDispatchToProps)(EventTNC)
