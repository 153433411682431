import Duck from 'extensible-duck'
import { IDLE, IN_PROGRESS } from '../../../src/constants'

const initialState = {
	isLoading: false,
	organiserData: {},
	bdDetails: {},
	confirmationStatus: IDLE,
	rejectionReason: {},
	rejectionStatus: IDLE,
}

export default new Duck({
	namespace: 'diy-organiser-approval',
	store: 'organiserApproval',
	types: [
		'GET_ORGANISER_DATA',
		'GET_ORGANISER_DATA_SUCCESS',
		'GET_BD_NAME',
		'UPDATE_BD_DETAILS',
		'APPROVE_ORGANISER',
		'UPDATE_CONFIRMATION_STATUS',
		'VIEW_ALL_DOCUMENTS',
		'GET_REJECTION_REASON',
		'GET_REJECTION_REASON_SUCCESS',
		'SUBMIT_REJECTION',
		'SUBMIT_REJECTION_STATUS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.GET_ORGANISER_DATA: {
				return {
					...state,
					isLoading: true,
				}
			}
			case duck.types.GET_ORGANISER_DATA_SUCCESS: {
				const { payload } = action
				return {
					...state,
					isLoading: false,
					organiserData: payload,
				}
			}
			case duck.types.UPDATE_BD_DETAILS: {
				const { data } = action
				return {
					...state,
					bdDetails: data,
				}
			}
			case duck.types.APPROVE_ORGANISER: {
				return {
					...state,
					confirmationStatus: IN_PROGRESS,
				}
			}
			case duck.types.UPDATE_CONFIRMATION_STATUS: {
				const { status } = action
				return {
					...state,
					confirmationStatus: status,
				}
			}
			case duck.types.GET_REJECTION_REASON_SUCCESS: {
				const { data } = action
				return {
					...state,
					rejectionReason: data,
				}
			}
			case duck.types.SUBMIT_REJECTION_STATUS: {
				const { status } = action
				return {
					...state,
					rejectionStatus: status,
				}
			}
			default:
				return state
		}
	},
	selectors: {
		isLoading: (state) => state.organiserApproval.isLoading || {},
		organiserData: (state) => state.organiserApproval.organiserData || {},
	},
	creators: (duck) => ({
		getOrganiserData: (params) => ({
			type: duck.types.GET_ORGANISER_DATA,
			params,
		}),
		getOrganiserDataSuccess: (payload) => ({
			type: duck.types.GET_ORGANISER_DATA_SUCCESS,
			payload,
		}),

		getBdName: (state) => ({
			type: duck.types.GET_BD_NAME,
			state,
		}),
		updateBdDetails: (data) => ({
			type: duck.types.UPDATE_BD_DETAILS,
			data,
		}),
		approveOrganiser: (payload) => ({
			type: duck.types.APPROVE_ORGANISER,
			payload,
		}),
		updateConfirmationStatus: (status) => ({
			type: duck.types.UPDATE_CONFIRMATION_STATUS,
			status,
		}),
		viewAllDocuments: (id) => ({
			type: duck.types.VIEW_ALL_DOCUMENTS,
			id,
		}),
		getRejectionReason: (id) => ({
			type: duck.types.GET_REJECTION_REASON,
			id,
		}),
		getRejectionReasonSuccess: (data) => ({
			type: duck.types.GET_REJECTION_REASON_SUCCESS,
			data,
		}),
		submitRejection: (params) => ({
			type: duck.types.SUBMIT_REJECTION,
			params,
		}),
		submitRejectionStatus: (status) => ({
			type: duck.types.SUBMIT_REJECTION_STATUS,
			status,
		}),
	}),
})
