import React, { useState, useMemo } from 'react'
import { object, bool, func } from 'prop-types'

// components
import FilterList from 'bms-le-components/components/FilterList'
import Button from 'bms-le-components/atoms/Button'
import BottomDrawer from 'bms-le-components/components/BottomDrawer'
import Modal from 'common/components/Modal'

import { Wrapper, FilterWrapper } from './__styles'

const RECURRING_SESSIONS_LIST = [
	{
		code: 'DAY',
		value: 'Every Day',
		isActive: false,
	},
	{
		code: 'WEEK',
		value: 'Every Week',
		isActive: false,
	},
	{
		code: 'MONTH',
		value: 'Every Month',
		isActive: false,
	},
	// {
	// 	code: 'YEAR',
	// 	value: 'Every Year',
	// 	isActive: false,
	// },
]

const RecurringSelector = props => {
	const {
		isDesktop,
		handleAddRecurringInfo,
		showModal,
		handleModalToggle,
	} = props
	const [recurringInfo, setRecurringInfo] = useState({})

	const handleFilterClick = ({ name, isActive }) => {
		setRecurringInfo({ name, isActive })
	}

	const handleCreate = () => {
		handleAddRecurringInfo(
			RECURRING_SESSIONS_LIST.find(
				info => info.value === recurringInfo.name
			)
		)
	}

	const filterList = useMemo(
		() =>
			RECURRING_SESSIONS_LIST.map(role => ({
				name: role.value,
				isActive: role.value === recurringInfo.name,
			})),
		[recurringInfo]
	)

	const ModalContent = isDesktop ? Modal : BottomDrawer
	const passDownProps = isDesktop
		? { showModal, toggleModal: handleModalToggle }
		: { isOpen: showModal, toggleHandler: handleModalToggle }

	return (
		<ModalContent title="Select Frequency" {...passDownProps}>
			<Wrapper isDesktop={isDesktop}>
				<FilterWrapper>
					<FilterList
						filterList={filterList}
						handleFilterClick={handleFilterClick}
					/>
				</FilterWrapper>
				<Button
					type="PRIMARY"
					size="LARGE"
					style={{ margin: '10px auto', bottom: '0px' }}
					onClick={handleCreate}
					disabled={!Object.keys(recurringInfo).length}
				>
					Save
				</Button>
			</Wrapper>
		</ModalContent>
	)
}

RecurringSelector.propTypes = {
	isDesktop: bool.isRequired,
	artistInfo: object.isRequired,
	handleAddRecurringInfo: func.isRequired,
}

RecurringSelector.defaultProps = {
	isDesktop: true,
	artistInfo: {},
	handleAddRecurringInfo: () => {},
	handleDelete: () => {},
}

export default RecurringSelector
