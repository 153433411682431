import React, { useState, useEffect, useMemo } from 'react'
import { object, bool, func } from 'prop-types'
import { createComponent } from 'react-fela'
import Artists from 'bms-le-components/components/Artists'
import FilterList from 'bms-le-components/components/FilterList'
import Button from 'bms-le-components/atoms/Button'
import Icon from 'bms-le-components/common/ui/Icon'
import DeleteIcon from 'common/icons/delete-trash.svg'
import { __wrapper, __header, __filterWrapper, __iconWrapper } from './__style'

const Wrapper = createComponent(__wrapper)
const HeadWrapper = createComponent(__header)
const FilterWrapper = createComponent(__filterWrapper)
const IconWrapper = createComponent(__iconWrapper, 'div', ['onClick'])

const ARTIST_ROLES_LIST = [
	'Actor',
	'Director',
	'Writer',
	'Music',
	'Voice',
	'Producer',
	'Organiser',
	'Player',
]

const SetArtistRole = (props) => {
	const {
		isDesktop,
		allowDelete,
		artistInfo,
		handleAddArtist,
		handleDelete,
	} = props
	const [artistRoles, setArtistRoles] = useState([])

	useEffect(() => {
		setArtistRoles((prevState) => prevState.concat(artistInfo.roles || []))
	}, [artistInfo])

	const handleFilterClick = ({ name, isActive }) => {
		setArtistRoles((prevState) =>
			isActive
				? prevState.filter((role) => role !== name)
				: prevState.concat(name)
		)
	}

	const handleCreate = () => {
		handleAddArtist(artistRoles)
	}

	const filterList = useMemo(
		() =>
			ARTIST_ROLES_LIST.map((role) => ({
				name: role,
				isActive: artistRoles.indexOf(role) !== -1,
			})),
		[artistRoles]
	)
	return (
		<Wrapper isDesktop={isDesktop}>
			<HeadWrapper>
				<Artists
					row
					isDesktop
					pullRight="12"
					imageWidth={46}
					marginBottom={12}
					artistName={artistInfo.name + ' as...'}
					artistImage={artistInfo.url}
				/>
				{allowDelete && (
					<IconWrapper onClick={handleDelete}>
						<Icon glyph={DeleteIcon} />
					</IconWrapper>
				)}
			</HeadWrapper>
			<FilterWrapper>
				<FilterList
					filterList={filterList}
					handleFilterClick={handleFilterClick}
				/>
			</FilterWrapper>
			<Button
				style={{ margin: '0 auto' }}
				size="LARGE"
				isFullWidth={!isDesktop}
				onClick={handleCreate}
				disabled={!artistRoles.length}
			>
				Create
			</Button>
		</Wrapper>
	)
}

SetArtistRole.propTypes = {
	isDesktop: bool.isRequired,
	allowDelete: bool.isRequired,
	artistInfo: object.isRequired,
	handleAddArtist: func.isRequired,
	handleDelete: func.isRequired,
}

SetArtistRole.defaultProps = {
	isDesktop: true,
	allowDelete: false,
	artistInfo: {},
	handleAddArtist: () => {},
	handleDelete: () => {},
}

export default SetArtistRole
