import { createComponent } from 'react-fela'
import theme from 'bms-le-components/utils/base-ui-theme'

export const TextArea = createComponent(
	({ isError }) => ({
		width: '100%',
		fontFamily: 'Roboto',
		fontSize: '14px',
		lineHeight: '16px',
		padding: '12px',
		borderRadius: '2px',
		resize: 'none',
		border: '1px solid ' + (isError ? theme.colors.errorRed : '#CCC'),
	}),
	'textarea',
	['value', 'onChange', 'placeholder', 'disabled', 'onBlur']
)
