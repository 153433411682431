export const PENDING = 'pending'
export const APPROVED = 'approved'
export const REJECTED = 'rejected'

export const REJECT_ORGANISER_TITLE =
	'Are you sure you want to reject organiser:'
export const ADDITIONAL_NOTE_PLACEHOLDER =
	'Specify things to improve in the organiser’s submission'
export const ADDITIONAL_NOTE_LABEL = 'Additional note for the organiser'
export const VERIFICATION_TIPS = [
	{
		label: 'Check for validity of PAN here:',
		link: 'https://services.gst.gov.in/services/searchtpbypan',
	},
	{
		label: 'Check for validity of GST here:',
		link: 'https://services.gst.gov.in/services/searchtp',
	},
]

export const APPROVE_FLOW_CONTENT = {
	label: 'Are you sure you want to approve organiser:',
	description:
		'Make sure you have verified all details including supporting documents and BD name for this organiser.',
}

export const VERIFY_DOCUMENTS_TEXT = '**How to verify supporting documents?'
