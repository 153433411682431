import React, { useState } from 'react'
import { object, string, bool, shape, func } from 'prop-types'
import Icon from 'bms-le-components/common/ui/Icon'
import {
	MainWrapper,
	IconWrapper,
	ContentWrapper,
	DetailWrapper,
	Title,
	CloseButton,
	InfoWrapper,
	LeftContentWrapper,
	ChildrenWrapper,
} from './__style'

const InfoAlertBar = ({
	isDesktop,
	svgIcon,
	title,
	details,
	spaceBottom,
	type,
	children,
	showClose,
	onCloseClick,
	customIconStyles,
	customWrapperStyles,
	titleStyles,
	infoStyles,
}) => {
	const [show, setShow] = useState(true)

	const handleCloseClick = () => {
		setShow(false)
		onCloseClick && onCloseClick()
	}

	return (
		<MainWrapper
			show={show}
			type={type}
			isDesktop={isDesktop}
			spaceBottom={spaceBottom}
			style={customWrapperStyles}
		>
			<ContentWrapper>
				<LeftContentWrapper>
					{svgIcon && (
						<IconWrapper
							isDesktop={isDesktop}
							style={customIconStyles}
						>
							<Icon glyph={svgIcon} />
						</IconWrapper>
					)}

					<DetailWrapper isDesktop={isDesktop}>
						{title && <Title style={titleStyles}>{title}</Title>}
						{details && (
							<InfoWrapper style={infoStyles}>
								{details}
							</InfoWrapper>
						)}
					</DetailWrapper>
				</LeftContentWrapper>
				{showClose && (
					<CloseButton onClick={handleCloseClick}>
						&#10005;
					</CloseButton>
				)}
			</ContentWrapper>

			{children && (
				<ChildrenWrapper isDesktop={isDesktop}>
					{children}
				</ChildrenWrapper>
			)}
		</MainWrapper>
	)
}

InfoAlertBar.propTypes = {
	isDesktop: bool.isRequired,
	svgIcon: object,
	title: string.isRequired,
	details: string.isRequired,
	marginBottom: string,
	type: string.isRequired,
	showClose: bool,
	onCloseClick: func,
	customWrapperStyles: shape({}),
}

InfoAlertBar.defaultProps = {
	isDesktop: true,
	svgIcon: null,
	title: '',
	details: '',
	marginBottom: '',
	type: 'info',
	showClose: true,
	onCloseClick: () => {},
	customWrapperStyles: null,
}

export default InfoAlertBar
