import { createComponent } from 'react-fela'

export const InputBar = createComponent(
	() => ({
		border: '1px solid #D0D0D0',
		borderRadius: '2px',
		height: '36px',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px',
		alignItems: 'center',
		backgroundColor: '#fff',
	}),
	'div',
	['onClick', 'data-attr']
)

export const TextSpace = createComponent(() => ({
	whiteSpace: 'noWrap',
	minWidth: '279px',
	fontSize: '16px',
	lineHeight: '16px',
	color: '#B0B0B0',
}))

export const ErrorText = createComponent(() => ({
	color: '#CD0000',
	lineHeight: '16px',
	fontSize: '12px',
}))

export const Wrapper = createComponent(({ isDesktop }) => ({
	marginBottom: '16px',
	marginLeft: isDesktop ? '20px' : '10px',
	marginRight: isDesktop ? '20px' : '10px',
}))
