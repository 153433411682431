import React, { useEffect } from 'react'
import { Route, Redirect, withRouter, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'

// components
import Popup from 'bms-le-components/components/Popup'
import Toast from 'bms-le-components/components/Toast'
import LazyLoad from 'common/components/LazyLoad'
import ProtectedRoutes from 'modules/App/ProtectedRoutes'

// lazy loaded components
const Login = React.lazy(() => import('modules/Login'))
const { IAM } = window
// duc
import { AppDuc } from 'modules/App/duc'
import OffersDuc from 'modules/Offers/duc'
import LoginDuc from 'modules/Login/duc'

// helpers
import { getCookie } from 'utils/helpers'
import { GENERAL_INFO_PATH } from 'modules/CreateAccount/constants'

// themes
import theme from 'bms-le-components/utils/base-ui-theme'
import Loader from 'common/components/Loader'
import { M5 } from 'utils/storage'

const App = ({
	displayPopup,
	popupConfig,
	showToastDerived,
	hideToast,
	isDesktop,
	history,
	context,
	analyticsPush,
	dispatch,
	getUserDetails,
	getFeaturesList,
	loading,
	isLoggedIn,
	logOutSuccess,
	isSignedUp,
	queryParams,
	validateUser,
}) => {
	const accessToken = IAM ? IAM?.token() : getCookie('accessToken')
	const path = !IAM && isSignedUp ? GENERAL_INFO_PATH : '/apps'
	const routesToRestrictOverflow = [
		'/scanningApp/scanner',
		'/scanningApp/tickets',
	]

	useEffect(() => {
		const id = new URLSearchParams(queryParams).get('id') || ''
		const existingUserAuthToken = M5.Storage.get({ name: 'accessToken' })
		if (id) {
			if (existingUserAuthToken) {
				M5.Storage.del({ name: 'accessToken' })
				history.push('/login')
			} else {
				const newUserAuthToken = M5.Storage.get({
					name: 'newUserAccessToken',
				})

				if (newUserAuthToken) {
					validateUser(id)
				}
			}
		}
	}, [])

	useEffect(() => {
		if (!accessToken) {
			logOutSuccess()
		}
	}, [accessToken])

	useEffect(() => {
		if (isLoggedIn) {
			getUserDetails()
			getFeaturesList()
		}
	}, [isLoggedIn])

	useEffect(() => {
		if (!IAM && isSignedUp) {
			history.replace(GENERAL_INFO_PATH)
		}
	}, [isSignedUp])

	return (
		<div
			style={{
				background: '#edf0f4',
				height: '100%',
				overflow: routesToRestrictOverflow.includes(
					history.location.pathname
				)
					? 'hidden'
					: 'scroll',
			}}
		>
			<ConnectedRouter history={history} context={context}>
				<Popup
					show={displayPopup}
					config={popupConfig}
					dispatch={dispatch}
				/>
				{showToastDerived.show && (
					<Toast
						fixed
						style={{
							width: isDesktop ? '350px' : 'calc(100vw-32px)',
							transform: isDesktop && 'translate(-50%, 0)',
							left: isDesktop && '50%',
							overflow: isDesktop && 'hidden',
						}}
						isDesktop={false}
						{...showToastDerived}
						onClose={() => hideToast()}
					/>
				)}
				{loading ? (
					<Loader active={loading} centerAt="fixed" />
				) : (
					<Switch>
						<Redirect
							exact
							from="/"
							to={IAM ? '/apps/diy/home' : path}
						/>
						<Route
							path="/login"
							render={() => (
								<LazyLoad>
									<Login analyticsPush={analyticsPush} />
								</LazyLoad>
							)}
						/>
						<Route
							path="/signup"
							render={() => (
								<LazyLoad>
									<Login analyticsPush={analyticsPush} />
								</LazyLoad>
							)}
						/>
						<ProtectedRoutes />
					</Switch>
				)}
			</ConnectedRouter>
		</div>
	)
}

const mapStateToProps = ({ app, device, login, router }) => {
	return {
		displayPopup: app.popup.show,
		popupConfig: app.popup.config,
		showToastDerived: app.toast,
		isDesktop: device.detection.isDesktop,
		loading: app.lodingFeaturesList || app.lodingUserProfile,
		isLoggedIn: login.isLoggedIn,
		isSignedUp: login.isSignedUp,
		queryParams: router?.location?.search,
	}
}

const mapDispatchToProps = (dispatch) => ({
	initiateLogOut: (isAnalyticsEnabled) => {
		if (isAnalyticsEnabled) {
			dispatch(
				AppDuc.creators.wrapAnalytics(
					AppDuc.creators.NoActionAnalytics(),
					[
						{
							type: 'GA',
							app_code: 'DIY',
							event_name: 'profile_icon_button_clicked',
							screen_name: 'offer_config_landing_page',
							event_type: 'click',
							event: 'gtm_std_event',
						},
					]
				)
			)
		}

		dispatch(
			AppDuc.creators.showPopup({
				title: 'Are you sure?',
				content: 'You will be logged out of your account',
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: () => {
					dispatch(AppDuc.creators.hidePopup())
				},
				buttons: [
					{
						text: 'Yes',
						action: () => {
							dispatch(AppDuc.creators.hidePopup())
							dispatch(LoginDuc.creators.handleLogOut())
							if (isAnalyticsEnabled) {
								dispatch(
									AppDuc.creators.wrapAnalytics(
										AppDuc.creators.NoActionAnalytics(),
										[
											{
												type: 'GA',
												app_code: 'DIY',
												event_name:
													'logout_button_clicked',
												screen_name:
													'offer_config_landing_page',
												event_type: 'click',
												event: 'gtm_std_event',
											},
										]
									)
								)
							}
						},
					},
					{
						text: 'No',
						action: () => {
							dispatch(AppDuc.creators.hidePopup())
						},
						inverted: true,
						textColor: theme.buttonVariants.primary,
					},
				],
				contentstart: true,
			})
		)
	},
	hideToast: () => dispatch(AppDuc.creators.showToast()),
	getFeaturesList: () => dispatch(AppDuc.creators.getFeaturesList()),
	getUserDetails: () => dispatch(AppDuc.creators.getUserDetails()),
	logOutSuccess: () => dispatch(LoginDuc.creators.logOutSuccess()),
	validateUser: (id) => dispatch(LoginDuc.creators.validateUser(id)),
	deactivateOffer: (params) =>
		dispatch(OffersDuc.creators.deactivateOffer(params)),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData.GA,
				},
			])
		),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
