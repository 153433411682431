import { createComponent } from 'react-fela'

const __wrapper = ({ isDesktop, disabled, theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	padding: 7.5,
	'>div:first-child': {
		marginTop: 5,
		height: 65,
		marginLeft: 10,
		width: 223,
		extend: [{ condition: !isDesktop, style: { width: '100%' } }],
	},
	extend: [
		{
			condition: disabled,
			style: {
				pointerEvents: 'none',
				opacity: 0.5,
				background: theme.colors.smokeBlue,
			},
		},
	],
})

const __timeSectionWrapper = ({ isDesktop }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	'>div:nth-child(-n + 1)': {
		// width: isDesktop ? 125 : 145,
		marginRight: isDesktop ? 25 : 0,
		marginTop: 0,
		height: 65,
		marginLeft: 10,
	},
	':nth-child(2n)': {
		width: isDesktop ? 344 : 'auto',
	},
})

const __mainWrapper = ({ isDesktop, disabled }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
	backgroundColor: '#fff',
	padding: '10px 10px 15px',
	margin: '5px 0px',
	'>div:last-child': { marginLeft: 10, marginBottom: 5 },
	width: '100%',
	extend: [
		{
			condition: isDesktop,
			style: { margin: '1px 0px' },
		},
		{
			condition: disabled,
			style: {
				opacity: 0.7,
				pointerEvents: 'none',
				backgroundColor: '#F2F5F9',
			},
		},
	],
})

const _customBtn = () => ({
	height: 26,
	width: 26,
	borderRadius: 20,
	color: '#0378FF',
	border: '1px solid #0378FF',
	textAlign: 'center',
	backgroundColor: 'transparent',
	outline: 'none',
	cursor: 'pointer',
	':hover': { fontWeight: 'bold' },
})

const Wrapper = createComponent(__wrapper)
const MainWrapper = createComponent(__mainWrapper)
const CustomBtn = createComponent(_customBtn, 'button', ['onClick'])
const TimeSectionWrapper = createComponent(__timeSectionWrapper)

export { Wrapper, MainWrapper, CustomBtn, TimeSectionWrapper }
