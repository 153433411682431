export const __mainWrapper = () => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	alignContent: 'center',
	background: '#fff',
	padding: '2.5px 10px',
	border: '1px solid #DDDDDD',
	'> div:nth-child(1)': { flexGrow: 1, maxWidth: 220, overflow: 'hidden' },
	'> button:last-child': { fontWeight: 'normal', outline: 'none' },
})
