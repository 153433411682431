// components
import React, { PureComponent } from 'react'
import { func, array } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

// duc
import { AppDuc } from 'modules/App/duc'
import EventManagerDuc from 'modules/EventManagement/duc'

// components
import Modal from 'common/components/Modal'
import BottomDrawer from 'bms-le-components/components/BottomDrawer'
import Button from 'bms-le-components/atoms/Button'
import Icon from 'bms-le-components/common/ui/Icon'
import Loader from 'common/components/Loader'
import ArtistCard from './ArtistCard'
import SelectArtistRole from './ArtistRole'
import SelectedArtists from './SelectedArtists'
import InfoAlertBar from 'common/components/InfoAlertBar'
import SearchBar from 'bms-le-components/components/SearchBar'

// styled components
import {
	ListingWrapper,
	ButtonWrapper,
	NoEventsBlock,
	NoEventsMessage,
	EmailInfoWrapper,
} from './__style'

// icons
import Information from 'common/icons/information.svg'
import NoEvents from 'common/icons/NoEvents.svg'

class Artists extends PureComponent {
	constructor(props) {
		super(props)
		this.wrapperRef = React.createRef()
		this.state = {
			currentArtist: null,
			selectedArtistList: props.artistInfo,
			allowDelete: false,
			showArtistList: false,
			searchValue: '',
		}
		this.getArtists = debounce(this.getArtistsMasterList, 600)
	}

	componentDidMount() {
		if (!this.props.isDesktop) {
			this.getArtistsMasterList()
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.artistInfo !== this.props.artistInfo) {
			this.setState({
				selectedArtistList: this.props.artistInfo,
			})
		}
		document.addEventListener('mousedown', this.closeArtistListing)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.closeArtistListing)
	}

	closeArtistListing = (event) => {
		if (
			this.props.isDesktop &&
			this.wrapperRef &&
			!this.wrapperRef.current.contains(event.target)
		) {
			this.setState({
				showArtistList: false,
			})
		}
	}

	getArtistsMasterList = (searchValue = '') => {
		this.props.getMastersData({ component: 'artists', searchValue })
	}

	handleSearch = (keyword) => {
		this.setState({
			showArtistList: true,
			searchValue: keyword,
		})
		if (this.props.masterList.length > 0) {
			this.getArtists(keyword)
		} else {
			this.getArtistsMasterList(keyword)
		}
	}

	handleEditArtistRole = (currentArtist) => {
		this.setState({ currentArtist, allowDelete: true })
		this.props.handleModalToggle()
	}

	handleSetArtistRole = (currentArtist) => {
		this.props.analyticsPush({
			event_name: 'event_artist_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'cast_selected',
			screen_name: 'event_artist_selection',
		})
		this.setState({ currentArtist, allowDelete: false })
		this.props.handleModalToggle()
	}

	handleAddArtist = (roles) => {
		const { updateSynopsis } = this.props
		const { currentArtist } = this.state

		this.setState(
			(prevState) => ({
				selectedArtistList: prevState.selectedArtistList.find(
					({ id }) => id === currentArtist.id
				)
					? prevState.selectedArtistList.map((artist) =>
							artist.id === prevState.currentArtist.id
								? { ...artist, roles }
								: artist
					  )
					: prevState.selectedArtistList.concat({
							...currentArtist,
							roles,
					  }),
				currentArtist: null,
				showArtistList: false,
				searchValue: '',
			}),
			() => {
				updateSynopsis('artistInfo', this.state.selectedArtistList)
			}
		)
		this.props.analyticsPush({
			event_name: 'event_artist_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'crew_selected',
			screen_name: 'event_artist_selection',
		})

		this.props.handleModalToggle()
	}

	handleDelete = () => {
		const { updateSynopsis } = this.props
		this.setState(
			(prevState) => ({
				selectedArtistList: prevState.selectedArtistList.filter(
					(artist) => artist.id !== prevState.currentArtist.id
				),
			}),
			() => {
				updateSynopsis('artistInfo', this.state.selectedArtistList)
			}
		)
		this.props.handleModalToggle()
	}

	handleContinue = () => {
		const { goBack, isDesktop, draftId, eventCode, updateEventData } =
			this.props
		updateEventData(
			draftId,
			eventCode,
			'artistInfo',
			this.state.selectedArtistList
		)
		this.props.analyticsPush({
			event_name: 'event_artist_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'continue_clicked',
			screen_name: 'event_artist_selection',
		})
		if (!isDesktop) goBack()
	}

	render() {
		const {
			isDesktop,
			showModal,
			handleModalToggle,
			masterList,
			loading,
			buttonLoading,
			match: { params },
			eventNotification,
			showSaveButton,
			eventCode,
		} = this.props
		const { section } = params
		const {
			currentArtist,
			selectedArtistList,
			allowDelete,
			showArtistList,
			searchValue,
		} = this.state

		const ModalContent = isDesktop ? Modal : BottomDrawer
		const passDownProps = isDesktop
			? { showModal, toggleModal: handleModalToggle }
			: { isOpen: showModal, toggleHandler: handleModalToggle }

		const artistMasterList = masterList.filter(
			({ id }) => !selectedArtistList.find((item) => item.id === id)
		)

		return (
			<div ref={this.wrapperRef}>
				{currentArtist && (
					<ModalContent
						key={currentArtist.id}
						title="Select Role"
						{...passDownProps}
					>
						<SelectArtistRole
							allowDelete={allowDelete}
							isDesktop={isDesktop}
							artistInfo={currentArtist}
							handleAddArtist={this.handleAddArtist}
							handleDelete={this.handleDelete}
						/>
					</ModalContent>
				)}
				{!eventCode ? (
					<InfoAlertBar
						isDesktop={false}
						svgIcon={Information}
						details="Currently you can only pick artists & crew from our directory of 1.2 lac professionals"
						spaceBottom
					/>
				) : null}
				<SelectedArtists
					artistList={selectedArtistList}
					onClick={this.handleEditArtistRole}
				/>
				<SearchBar
					handleChange={this.handleSearch}
					placeholder="Search for artists & crew (Optional)"
					removeCancelButton
					renderValueViaProp
					value={searchValue}
				/>
				{showArtistList && (
					<ListingWrapper isDesktop={isDesktop}>
						{loading ? (
							<Loader />
						) : artistMasterList.filter((a) => !a.exclude)
								.length === 0 ? (
							<NoEventsBlock>
								<Icon
									width="126px"
									height="96px"
									glyph={NoEvents}
								/>
								<NoEventsMessage>{`No artist found for your search`}</NoEventsMessage>
								<EmailInfoWrapper isDesktop={isDesktop}>
									To get your artist registered, please write
									to
									<a
										href="mailto:diy.support@bookmyshow.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										{` diy.support@bookmyshow.com`}
									</a>
								</EmailInfoWrapper>
							</NoEventsBlock>
						) : (
							artistMasterList
								.filter((a) => !a.exclude)
								.map((item) => (
									<ArtistCard
										key={item.id}
										artistInfo={item}
										info="Add as..."
										handleClick={this.handleSetArtistRole}
									/>
								))
						)}
					</ListingWrapper>
				)}
				{((!isDesktop && eventNotification?.type !== 'review') ||
					(isDesktop &&
						section &&
						showSaveButton &&
						eventNotification?.type !== 'review')) && (
					<ButtonWrapper isDesktop={isDesktop}>
						<Button
							style={{ margin: '0 auto' }}
							size="LARGE"
							onClick={this.handleContinue}
							isFullWidth={!isDesktop}
							disabled={!selectedArtistList.length}
							isLoading={buttonLoading}
						>
							Save
						</Button>
					</ButtonWrapper>
				)}
			</div>
		)
	}
}

Artists.propTypes = {
	searchForArtists: func.isRequired,
	artistInfo: array.isRequired,
}

Artists.defaultProps = {
	searchForArtists: () => {},
	artistInfo: [],
}

const mapStateToProps = ({ app, device, eventManager }) => ({
	showModal: app.showModal,
	isDesktop: device.detection.isDesktop,
	masterList: eventManager.masters.artists || [],
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	artistInfo: eventManager.activeEvent.artistInfo,
	loading: eventManager.loading,
	buttonLoading: eventManager.buttonLoading,
	eventNotification: eventManager?.eventNotification,
	showSaveButton: eventManager?.showSaveButton,
})

const mapDispatchToProps = (dispatch) => ({
	handleModalToggle: () => dispatch(AppDuc.creators.toggleModal()),
	getMastersData: (query) =>
		dispatch(EventManagerDuc.creators.getMastersData(query)),
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Artists))
