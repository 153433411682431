import React, { useEffect } from 'react'
import { connect } from 'react-redux'

// components
import VenueSelector from 'modules/EventManagement/components/Selectors/VenueSelector'
import BottomDrawer from 'bms-le-components/components/BottomDrawer'
import Modal from 'common/components/Modal'
import { AppDuc } from 'modules/App/duc'

// duc
import EventManagerDuc from 'modules/EventManagement/duc'

const VenueScheduler = ({
	isDesktop,
	showModal,
	handleModalToggle,
	getMastersData,
	venues,
	setSelectedVenues,
	selectedVenues,
	loading,
	updateEventData,
	draftId,
	eventCode,
	onDeleteOfSelectedVenue,
	onDateChange,
	showVenueChange,
	recurringInfo,
	updateRecurringInfo,
	analyticsPush,
	updateSynopsis,
	updateAllEventSections,
}) => {
	const ModalContent = isDesktop ? Modal : BottomDrawer
	const passDownProps = isDesktop
		? {
				showModal,
				toggleModal: handleModalToggle,
				title: 'Venue Selection',
				customStyle: {
					minWidth: '726px',
					minHeight: '420px',
					maxHeight: '600px',
					padding: '25px',
					overflow: 'hidden',
					borderRadius: 8,
					display: 'flex',
					flexFlow: 'column',
				},
		  }
		: {
				isOpen: showModal,
				toggleHandler: handleModalToggle,
				minHeight: showVenueChange ? 200 : 450,
		  }

	useEffect(() => {
		getMastersData({
			component: 'venues',
			venueType: 'onGround',
		})
		analyticsPush({
			event_name: 'event_venue_selection_viewed',
			event_type: 'screen_view',
			event: 'gtm_allpage',
			screen_name: 'event_venue_selection',
		})
	}, [])
	return (
		<ModalContent {...passDownProps}>
			<VenueSelector
				venues={venues}
				isDesktop={isDesktop}
				setSelectedVenues={setSelectedVenues}
				selectedVenues={selectedVenues}
				handleModalToggle={handleModalToggle}
				getMastersData={getMastersData}
				loading={loading}
				updateAllEventSections={updateAllEventSections}
				updateEventData={updateEventData}
				updateSynopsis={updateSynopsis}
				draftId={draftId}
				eventCode={eventCode}
				onDeleteOfSelectedVenue={onDeleteOfSelectedVenue}
				onDateChange={onDateChange}
				showVenueChange={showVenueChange}
				recurringInfo={recurringInfo}
				updateRecurringInfo={updateRecurringInfo}
				analyticsPush={analyticsPush}
			/>
		</ModalContent>
	)
}

const mapStateToProps = ({ eventManager }) => ({
	venues: eventManager.masters.venues || [],
	selectedVenues: eventManager.activeEvent.venueInfo || [],
	loading: eventManager.loading || false,
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	recurringInfo: eventManager?.activeEvent?.recurringInfo || {},
})

const mapDispatchToProps = (dispatch) => ({
	getMastersData: (query) =>
		dispatch(EventManagerDuc.creators.getMastersData(query)),
	setSelectedVenues: (venues) =>
		dispatch(EventManagerDuc.creators.setSelectedVenues(venues)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	updateRecurringInfo: (data) =>
		dispatch(EventManagerDuc.creators.updateRecurringInfo(data)),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateAllEventSections: (params) =>
		dispatch(EventManagerDuc.creators.updateAllEventSections(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VenueScheduler)
