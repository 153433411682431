import React from 'react'
import {
	DesktopWrapper,
	TitleWrapper,
	TextAlignWrapper,
	TitleText,
	SubTitleText,
	UploadWrapper,
	ImageWrapper,
	IconWrapper,
} from './__style'
import ImageUpload from 'common/components/ImageUpload'
import DeleteIcon from 'common/icons/trash.svg'
import InfoIcon from 'common/icons/information.svg'
import Icon from 'bms-le-components/common/ui/Icon'
import 'react-image-lightbox/style.css'

function EventImage({
	isDesktop,
	titleText,
	subTitleText,
	onGuideClick,
	uploadText,
	handleUpload,
	imageType,
	imageWidth,
	imageHeight,
	imageUrl,
	imageAlt,
	imageError,
	onImageDelete,
	disableEdit,
}) {
	return (
		<DesktopWrapper>
			<TitleWrapper>
				<TitleText>{titleText}</TitleText>
				<SubTitleText>
					<TextAlignWrapper onClick={onGuideClick}>
						<span style={{ marginRight: '4px' }}>GUIDE</span>
						<Icon glyph={InfoIcon} width="12px" height="12px" />
					</TextAlignWrapper>
				</SubTitleText>
			</TitleWrapper>
			<SubTitleText>{subTitleText}</SubTitleText>
			<UploadWrapper>
				{imageUrl ? (
					<ImageWrapper>
						<img
							alt={imageAlt}
							src={imageUrl}
							style={{
								maxWidth: imageWidth,
								maxHeight: imageHeight,
							}}
						/>
						<div
							className={isDesktop ? 'overlay' : 'mobile-overlay'}
						>
							<IconWrapper isDesktop={isDesktop}>
								<Icon
									glyph={DeleteIcon}
									onClick={() => {
										if (!disableEdit) {
											onImageDelete()
										}
									}}
								/>
							</IconWrapper>
						</div>
					</ImageWrapper>
				) : (
					<ImageUpload
						width={imageWidth}
						height={imageHeight}
						uploadText={uploadText}
						handleUpload={(files) => handleUpload(files, imageType)}
						errorText={imageError}
					/>
				)}
			</UploadWrapper>
		</DesktopWrapper>
	)
}

export default EventImage
