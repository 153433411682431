import { createComponent } from 'react-fela'

export const InputBar = createComponent(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '0 10px',
}))

export const TextSpace = createComponent(() => ({
	maxWidth: 240,
	textAlign: 'left',
	fontSize: 15,
	lineHeight: '16px',
	marginLeft: 15,
}))

export const EditBtn = createComponent(
	({ status }) => ({
		whiteSpace: 'noWrap',
		fontSize: 12,
		lineHeight: '16px',
		color: status === 'error' ? '#CD0000' : '#0378FF',
		fontWeight: 500,
	}),
	'div',
	['onClick', 'data-attr']
)

export const InfoWrapper = createComponent(() => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'center',
}))
