const { IAM, DIY_BASE_URL } = window
const url = window.location.hostname || ''

let TIER
let API_ENDPOINT
if (url === 'diy.hostmyshow.com') {
	TIER = 'PROD'
	API_ENDPOINT = 'https://in.bookmyshow.com/api/le-diy'
} else if (url === 'diy-in-preprod.bms.bz' || url === 'diy.bms.bz') {
	TIER = 'PREPROD'
	API_ENDPOINT = 'https://in-preprod.bms.bz/api/le-diy'
} else {
	TIER = 'SIT'
	API_ENDPOINT = 'https://in-sit.bms.bz/api/le-diy'
}

API_ENDPOINT = IAM ? DIY_BASE_URL : API_ENDPOINT

const MOCK_ENDPOINT = `http://localhost:8000`
const DIY_PRODUCT_GUIDE_URL =
	'https://in.bmscdn.com/webin/showcase/lys_product_doc.pdf'

export { API_ENDPOINT, TIER, MOCK_ENDPOINT, DIY_PRODUCT_GUIDE_URL }

export const SERVER_ISSUE = 'SERVER_ISSUE'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const IDLE = 'IDLE'
export const IN_PROGRESS = 'IN_PROGRESS'

export const regexes = {
	panRegex: /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/,
	addressRegex: /^[\s\S]{8,150}$/,
	gstRegex: /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
	fullNameRegex: /^[A-Za-z\s]+$/,
	emailRegex:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	bankNameRegex: /^[a-zA-Z &]+$/,
	ifscRegex: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
	bankAccountNoRegex: /^\d{9,18}$/,
}
