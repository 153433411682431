import React from 'react'

import DesktopWrapper from 'bms-le-components/components/DesktopWrapper'
import ButtonDesignLang from 'bms-le-components/components/ButtonDesignLang'
import TextField from 'bms-le-components/atoms/TextField'
import { BottomNavBar } from 'modules/EventManagement/components/ManageEvent/FirstTimeUser/__style'

const DurationSelector = props => {
	const { isDesktop, onDurationchange, duration, handleProceed } = props

	return (
		<>
			<TextField
				style={{ margin: '8px auto', width: '80%' }}
				inputStyles={{
					border: '1px solid #CCCCCC',
					borderRadius: '4px',
					fontSize: '16px',
					padding: '8px',
				}}
				hideClearIcon
				isRequired
				name="duration"
				type="number"
				placeholder="Enter duration in minutes *"
				value={duration}
				onChange={onDurationchange}
				tabIndex={0}
				isPhoneNumber
			/>
			{!isDesktop && (
				<BottomNavBar fixed>
					<DesktopWrapper customStyles={{ width: '224px' }}>
						<ButtonDesignLang
							center
							largeBtn
							fullWidth={!isDesktop}
							disabled={!duration > 0}
							onClick={handleProceed}
						>
							Proceed
						</ButtonDesignLang>
					</DesktopWrapper>
				</BottomNavBar>
			)}
		</>
	)
}

export default DurationSelector
