import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'

// components
import SearchBar from 'design-framework/components/SearchBar'
import Chips from 'design-framework/components/Chips'
import DesktopWrapper from 'bms-le-components/components/DesktopWrapper'
import Button from 'bms-le-components/atoms/Button'
import Loader from 'common/components/Loader'
import InfoAlertBar from 'common/components/InfoAlertBar'

// styled components
import {
	VenuePlaceHolder,
	VenueLabel,
	VenueName,
	Address,
	SelectedChipsHolder,
	ScrollSection,
	SearchBarWrapper,
	NoEventsBlock,
	NoEventsMessage,
	EmailInfoWrapper,
	RightArrowIconWrapper,
} from './__styles'
import { BottomNavBar } from 'modules/EventManagement/components/ManageEvent/FirstTimeUser/__style'
import Information from 'common/icons/information.svg'
import Icon from 'bms-le-components/common/ui/Icon'
import NoEvents from 'common/icons/NoEvents.svg'
import RightArrow from 'bms-le-components/common/icons/chevron.svg'
import { useLocation } from 'react-router-dom'

const VenueSelector = (props) => {
	const {
		venues,
		isDesktop,
		setSelectedVenues,
		selectedVenues = [],
		handleModalToggle,
		getMastersData,
		loading,
		updateEventData,
		updateSynopsis,
		updateAllEventSections,
		draftId,
		eventCode,
		onDeleteOfSelectedVenue,
		onDateChange,
		showVenueChange = false,
		recurringInfo,
		updateRecurringInfo,
		analyticsPush,
	} = props

	const modifySelctedVenues = selectedVenues.map((venue) => ({
		code: venue.venueCode,
		name: venue.venueName,
		sessionInfo: venue.sessionInfo || [],
	}))

	const [selected, setSelectedVenue] = useState(modifySelctedVenues)
	const recentVenues = venues.filter(
		({ trending, code }) =>
			trending === true && !selected.find((venue) => venue.code === code)
	)

	const [searchValue, setSearchValue] = useState('')

	const location = useLocation()
	const addSession = JSON.parse(
		new URLSearchParams(location.search).get('addSession') || 'false'
	)

	const getVenues = useCallback(
		debounce(
			(value) =>
				getMastersData({ component: 'venues', searchValue: value }),
			600
		),
		[]
	)

	const modifySelectedVenue = (code, operation) => {
		const venueCode = code
		if (operation === 'add') {
			analyticsPush({
				event_name: 'event_venue_selection_actions',
				event_type: 'click',
				event: 'gtm_std_event',
				event_action: 'venue_selected',
				screen_name: 'event_venue_selection',
			})
			const venueToBeAdded = venues.find(({ code }) => code === venueCode)
			if (selected.includes(venueToBeAdded)) {
				alert('Already there son')
			} else {
				if (isDesktop) {
					setSelectedVenue([
						...selected,
						{
							...venueToBeAdded,
						},
					])
				} else {
					setSelectedVenue([
						...selected,
						{
							...venueToBeAdded,
						},
					])
				}
			}
			setSearchValue('')
			getMastersData({ component: 'venues', searchValue: '' })
		} else if (operation === 'remove') {
			setSelectedVenue((previous) =>
				previous.filter(({ code }) => code !== venueCode)
			)
		}
	}
	const handleSearch = (value) => {
		analyticsPush({
			event_name: 'event_venue_selection_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'venue_searched',
			screen_name: 'event_venue_selection',
		})
		getVenues(value)
		setSearchValue(value)
	}

	const handleProceed = () => {
		// Keeping code and name for defense now, will get rid of this later
		const updatedVenues = selected.map((venue) => ({
			venueCode: venue.code,
			venueName: venue.name,
			...venue,
		}))
		setSelectedVenues(updatedVenues)
		// Venue update specific payload made since patch call needs section specific params only
		const venueUpdatePayload = updatedVenues.map((ven) => {
			if (ven?.sessionInfo?.length) {
				const sessionInfo = ven?.sessionInfo?.map((session) => {
					if (session?.ticketInfo?.length) {
						return {
							sessionId:
								typeof session?.sessionId === 'string'
									? session?.sessionId
									: '0',
							showTime: new Date(session.showTime).toISOString(),
							status: session.status,
							ticketInfo: session?.ticketInfo,
						}
					}
					return {
						sessionId: '0',
						showTime: new Date(session.showTime).toISOString(),
						status: session.status,
					}
				})
				return {
					...ven,
					venueCode: ven.venueCode,
					venueName: ven.venueName,
					sessionInfo: sessionInfo,
				}
			}
			return {
				...ven,
				venueCode: ven.venueCode,
				venueName: ven.venueName,
			}
		})

		// remove old stored recurring info data
		let updatedRecurringInfo = {}
		Object.keys(recurringInfo).map((data) => {
			if (venueUpdatePayload.find((venue) => venue.venueCode === data))
				updatedRecurringInfo = {
					...updatedRecurringInfo,
					[data]: recurringInfo?.[data],
				}
		})

		updateRecurringInfo(updatedRecurringInfo)
		// save all data for desktop because, after saving, we refresh the whole page
		// in desktop, user may not have saved other data, and data will be lost after refresh
		// in mobile, all sections are saved separately, so there is no issue
		if (isDesktop) {
			updateSynopsis('venueInfo', venueUpdatePayload)
			updateAllEventSections('event-schedule')
		} else {
			updateEventData(draftId, eventCode, 'venueInfo', venueUpdatePayload)
		}
		handleModalToggle()
	}

	return showVenueChange && !isDesktop ? (
		<div style={{ height: '200px' }}>
			<VenuePlaceHolder>
				<VenueName style={{ fontWeight: '500', fontSize: '18px' }}>
					Change of Schedule?
				</VenueName>
			</VenuePlaceHolder>
			<VenuePlaceHolder
				onClick={onDeleteOfSelectedVenue}
				disabled={addSession}
				style={{ display: 'flex' }}
			>
				<div>
					<VenueName style={{ fontWeight: '500' }}>
						Venue Change
					</VenueName>
					<Address style={{ color: '#666666' }}>
						You’ll lose all date & time settings if you change the
						venue.
						<br />
						{addSession && (
							<span style={{ color: '#E7364D' }}>
								This is undoable
							</span>
						)}
					</Address>
				</div>

				<RightArrowIconWrapper>
					<Icon glyph={RightArrow} />
				</RightArrowIconWrapper>
			</VenuePlaceHolder>
			<VenuePlaceHolder
				onClick={onDateChange}
				style={{ display: 'flex' }}
			>
				<VenueName style={{ fontWeight: '500' }}>
					Date Changes
				</VenueName>
				<RightArrowIconWrapper>
					<Icon glyph={RightArrow} />
				</RightArrowIconWrapper>
			</VenuePlaceHolder>
		</div>
	) : (
		<div
			style={{
				overflow: 'auto',
				display: 'flex',
				flex: '1 1 auto',
				position: 'relative',
				boxSizing: 'content-box',
			}}
		>
			<div
				style={{
					width: '100%',
					paddingBottom: isDesktop ? 250 : 0,
				}}
			>
				<div
					style={{
						position: isDesktop ? 'initial' : 'sticky',
						top: 0,
						background: 'white',
						width: '100%',
					}}
				>
					<InfoAlertBar
						isDesktop={true}
						svgIcon={Information}
						details="Select from the venues available in our directory"
					/>
					<SelectedChipsHolder isBottomDrawer>
						{selected.length > 0 &&
							selected.map(
								({ name, code, venueCode, venueName }) => (
									<Chips
										handleDeletion={() =>
											modifySelectedVenue(code, 'remove')
										}
										text={venueName || name}
										big
										key={venueCode || code}
									/>
								)
							)}
					</SelectedChipsHolder>
					<SearchBarWrapper isDesktop={isDesktop} isBottomDrawer>
						<SearchBar
							placeholder="Select Venue"
							removeCancelButton
							handleChange={(value) => {
								handleSearch(value)
							}}
							renderValueViaProp
							value={searchValue}
						/>
					</SearchBarWrapper>
				</div>

				{loading ? (
					<Loader />
				) : (
					<ScrollSection
						isDesktop={isDesktop}
						minHeight="150px"
						maxHeight="200px"
						hideBorder={!isDesktop}
						style={{ paddingBottom: isDesktop ? 0 : 60 }}
					>
						{searchValue && venues.length === 0 ? (
							<NoEventsBlock>
								<Icon
									width="126px"
									height="96px"
									glyph={NoEvents}
								/>
								<NoEventsMessage>{`Oops! No venues found for your search.`}</NoEventsMessage>
								<EmailInfoWrapper isDesktop={isDesktop}>
									To get your venue registered, please write
									to
									<a
										href="mailto:diy.support@bookmyshow.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										{` diy.support@bookmyshow.com`}
									</a>
								</EmailInfoWrapper>
							</NoEventsBlock>
						) : (
							<>
								{recentVenues.length > 0 && (
									<div>
										<VenueLabel>Recent Venues</VenueLabel>
										{recentVenues
											.filter((v) => !v.exclude)
											.map(({ name, address, code }) => (
												<VenuePlaceHolder
													code={code}
													key={code}
													onClick={() =>
														modifySelectedVenue(
															code,
															'add'
														)
													}
												>
													<VenueName>
														{name}
													</VenueName>
													<Address>{address}</Address>
												</VenuePlaceHolder>
											))}
									</div>
								)}

								<div>
									<VenueLabel>All Venues</VenueLabel>
									{venues
										.filter((v) => !v.exclude)
										.filter(
											(venue) =>
												!selected.find(
													({ code }) =>
														code === venue.code
												)
										)
										.map(({ name, address, code }) => (
											<VenuePlaceHolder
												code={code}
												key={code}
												onClick={() =>
													modifySelectedVenue(
														code,
														'add'
													)
												}
											>
												<VenueName>{name}</VenueName>
												<Address>{address}</Address>
											</VenuePlaceHolder>
										))}
								</div>
							</>
						)}
					</ScrollSection>
				)}
			</div>

			<BottomNavBar fixed>
				<DesktopWrapper customStyles={{ width: '224px' }}>
					<Button
						type="PRIMARY"
						size="LARGE"
						style={{
							margin: '0 auto',
						}}
						disabled={selected.length === 0}
						onClick={handleProceed}
					>
						Proceed
					</Button>
				</DesktopWrapper>
			</BottomNavBar>
		</div>
	)
}

export default VenueSelector
