import React from 'react'
import { connect } from 'react-redux'
import { CategoriesHolder, ActionText, EventName, Wrapper } from './__style'
import Badge from 'bms-le-components/atoms/Badge'
import ErrorAlert from 'common/components/ErrorAlert'

const EventInfo = (props) => {
	const {
		eventInfo,
		history,
		isDesktop,
		eventNotification,
		rejectionReasons,
	} = props
	const { eventTitle = '', category = [] } = eventInfo
	const handleClick = () => {
		history.push('/events/create-event')
	}

	const rejections = [
		...(rejectionReasons?.eventInfo || []),
		...(rejectionReasons?.category || []),
	]

	return (
		<Wrapper isDesktop={isDesktop}>
			{rejections.length > 0 && (
				<ErrorAlert errorMessages={rejections} isDesktop={isDesktop} />
			)}
			<CategoriesHolder>
				<div style={{ display: 'flex' }}>
					{category.length > 0 &&
						category.map((cat) => (
							<div style={{ marginRight: '10px' }} key={cat.code}>
								<Badge variant="date" height="auto">
									{cat.name}
								</Badge>
							</div>
						))}
				</div>
				{eventNotification?.type !== 'review' && (
					<ActionText onClick={handleClick}>EDIT</ActionText>
				)}
			</CategoriesHolder>
			<EventName>{eventTitle}</EventName>
		</Wrapper>
	)
}

const mapStateToProps = ({ eventManager }) => ({
	eventInfo: eventManager.activeEvent.eventInfo || {},
	eventNotification: eventManager?.eventNotification,
	rejectionReasons: eventManager.activeEvent.formattedRejectionReasons || {},
})

export default connect(mapStateToProps)(EventInfo)
