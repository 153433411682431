import React from 'react'
import { TextArea } from './__style'

/** Under development. Add props as desired */

function TextAreaInput({
	value,
	onChange,
	placeholder,
	disabled,
	onBlur,
	isError,
}) {
	return (
		<TextArea
			value={value}
			onChange={e => onChange(e.target.value)}
			placeholder={placeholder}
			disabled={disabled}
			onBlur={onBlur}
			isError={isError}
		/>
	)
}

export default TextAreaInput
