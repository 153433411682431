import { createComponent } from 'react-fela'
import theme from 'bms-le-components/utils/base-ui-theme'

const SelectedVenuePlaceHolder = createComponent(() => ({
	display: 'flex',
	flexDirection: 'column',
	background: '#ECF5FF',
	borderRadius: '4px',
	paddingBottom: '16px',
	paddingTop: '16px',
	margin: '16px',
}))

const VenueName = createComponent(() => ({
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '20px',
	color: '#222222',
	paddingLeft: '16px',
	paddingBottom: '10px',
	width: '80%',
}))

const ButtonWrapper = createComponent(() => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	width: '100%',
	backgroundColor: '#fff',
	padding: '7.5px 15px',
	boxShadow: '0px 0 5px rgba(0, 0, 0, 0.2)',
}))

const VenueSectionWrapper = createComponent(({ isDesktop }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	paddingTop: isDesktop ? '20px' : 0,
	paddingBottom: isDesktop ? '10px' : 0,
}))

const MainWrapper = createComponent(() => ({
	display: 'flex',
	flexWrap: 'wrap',
}))

export const Spacer = createComponent(({ mb }) => ({
	marginBottom: `${mb}px`,
}))

export const EventTypeWrapper = createComponent(
	({ isSelected }) => ({
		display: 'flex',
		border: isSelected ? '1px solid #3E6EEA' : theme.border,
		backgroundColor: isSelected
			? theme.colors.smokeBlue
			: theme.colors.transparent,
		borderRadius: '2px',
		width: '100%',
		padding: '12px 12px 12px 14px',
		margin: '8px 5px',
	}),
	'label'
)

export const EventTypeSubtitle = createComponent(() => ({
	fontSize: theme.font.size12,
	lineHeight: '16px',
	color: theme.colors.grey5,
}))

export {
	ButtonWrapper,
	VenueName,
	SelectedVenuePlaceHolder,
	VenueSectionWrapper,
	MainWrapper,
}
