export const IMAGE_TYPE = {
	HEADER_IMAGE: 'headerImage',
	VERTICAL_POSTER: 'verticalPoster',
}

// Max image upload size is 1MB (1024*1024).
export const MAX_HEADER_IMAGE_SIZE = 1048576
export const MAX_VERTICAL_POSTER_SIZE = 1048576

export const headerImageGuide = [
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_1.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_2.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_3.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_4.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_5.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_6.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_7.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_8.jpg',
	'https://in.bmscdn.com/webin/showcase/horizontalbanners_9.jpg',
]

export const verticalPosterGuide = [
	'https://in.bmscdn.com/webin/showcase/verticalbanners_1.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_2.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_3.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_4.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_5.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_6.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_7.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_8.jpg',
	'https://in.bmscdn.com/webin/showcase/verticalbanners_9.jpg',
]

export const generatePopUp = dimensions => ({
	LOW_DIMENSIONS: {
		title: 'Incorrect dimensions',
		content: `Please upload an image that is atleast ${
			dimensions.minimum
		} in dimensions. The image you uploaded is only ${dimensions.actual}`,
	},
})
