import { createComponent } from 'react-fela'

export const TextLabel = createComponent(({ theme }) => ({
	fontWeight: 500,
	fontSize: theme.font.size16,
	lineHeight: '20px',
	color: theme.colors.black,
	marginTop: theme.spaces[8],
}))

export const TextWrapper = createComponent(() => ({
	paddingLeft: '15px',
	paddingRight: '15px',
}))

export const Wrapper = createComponent(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	background: theme.colors.white,
	width: '100%',
}))

export const BottomNavBar = createComponent(({ theme, fixed }) => ({
	position: fixed ? 'fixed' : 'relative',
	bottom: '0',
	left: '0',
	right: '0',
	width: '100%',
	backgroundColor: theme.colors.white,
	padding: '15px',
	zIndex: '200',
}))

export const CategoryHelpText = createComponent(
	({ isMaxCategories, theme }) => ({
		fontSize: theme.font.size12,
		lineHeight: '16px',
		color: isMaxCategories ? '#DC354B' : theme.colors.doveGray,
		paddingTop: '15px',
	})
)
