import { createComponent } from 'react-fela'

export const ListingWrapper = createComponent(({ isDesktop }) => ({
	maxHeight: `calc(100vh - ${isDesktop ? 250 : 200}px)`,
	overflow: 'scroll',
}))

export const ButtonWrapper = createComponent(() => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	width: '100%',
	display: 'flex',
	backgroundColor: '#fff',
	padding: '7.5px 15px',
	justifyContent: 'center',
	boxShadow: '0px 0 5px rgba(0, 0, 0, 0.2)',
}))

export const NoEventsBlock = createComponent(() => ({
	textAlign: 'center',
}))

export const NoEventsMessage = createComponent(() => ({
	color: '#666666',
	fontWeight: '500',
	marginTop: '16px',
	fontSize: '14px',
}))

export const EmailInfoWrapper = createComponent(() => ({
	textAlign: 'center',
	fontSize: '12px',
	margin: '7.5px auto',
}))
