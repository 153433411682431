import React from 'react'
import { connect } from 'react-redux'
import { bool, string, func, arrayOf, shape } from 'prop-types'
import {
	DesktopWrapper,
	MobileWrapper,
	TitleWrapper,
	TitleText,
	SubTitleWrapper,
	SubTitleText,
	ButtonWrapper,
	FooterText,
	HeadWrapper,
	HeaderActionTxt,
} from './__style'
import Button from 'bms-le-components/atoms/Button'
import CheckBox from 'bms-le-components/atoms/CheckBox'
function TicketVenueCard({
	isDesktop,
	titleText,
	subTitleText,
	actionText,
	actionHandler,
	footerText,
	headerActionText,
	headerActionClick,
	showCopyTicketDetailsCheckbox,
	copyTicketDetailsChecked,
	toggleCopyTicketDetails,
	isSingleTicketType,
	addSession,
}) {
	const Wrapper = isDesktop ? DesktopWrapper : MobileWrapper
	return (
		<Wrapper>
			<HeadWrapper>
				<TitleWrapper>
					{titleText && <TitleText>{titleText}</TitleText>}
					{isDesktop &&
						showCopyTicketDetailsCheckbox &&
						!isSingleTicketType &&
						!addSession && (
							<CheckBox
								name="copyTicket"
								title="Apply to all shows"
								showTitle
								noMargin
								isChecked={copyTicketDetailsChecked}
								onToggle={toggleCopyTicketDetails}
							/>
						)}
				</TitleWrapper>
				{headerActionText && (
					<HeaderActionTxt onClick={headerActionClick}>
						{headerActionText}
					</HeaderActionTxt>
				)}
			</HeadWrapper>
			{subTitleText &&
				subTitleText.map((subTitle, index) => (
					<SubTitleWrapper key={index}>
						<SubTitleText>{subTitle?.left}</SubTitleText>
						<SubTitleText>{subTitle?.right}</SubTitleText>
					</SubTitleWrapper>
				))}
			{!isDesktop &&
				showCopyTicketDetailsCheckbox &&
				!isSingleTicketType &&
				!addSession && (
					<CheckBox
						name="copyTicket"
						title="Apply to all shows"
						showTitle
						noMargin
						isChecked={copyTicketDetailsChecked}
						onToggle={toggleCopyTicketDetails}
						style={{ marginTop: '12px' }}
					/>
				)}
			{Boolean(actionText) && Boolean(actionHandler) && (
				<ButtonWrapper>
					<Button onClick={actionHandler}>{actionText}</Button>
				</ButtonWrapper>
			)}
			{Boolean(footerText) && <FooterText>{footerText}</FooterText>}
		</Wrapper>
	)
}

const mapStateToProps = ({ device }) => ({
	isDesktop: device.detection.isDesktop,
})

export default connect(mapStateToProps)(TicketVenueCard)

TicketVenueCard.propTypes = {
	isDesktop: bool.isRequired,
	titleText: string,
	subTitleText: arrayOf(
		shape({
			left: string,
			right: string,
		})
	),
	actionText: string,
	actionHandler: func,
	footerText: string,
	headerActionText: string,
	headerActionClick: func.isRequired,
}

/** Remove the string values before wiring up the component */
TicketVenueCard.defaultProps = {
	isDesktop: true,
	actionHandler: () => {},
	headerActionClick: () => {},
	isSingleTicketType: false,
}
