import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import ScanningAppDuc from 'modules/ScanningApp/duc'
import { AppDuc } from 'modules/App/duc'
import { API } from 'modules/ScanningApp/api'
import { ERROR_TYPES } from 'modules/ScanningApp/constants'
import { privateRequest } from 'utils/request'
import { push } from 'connected-react-router'

export function* getAllEventData({ search = '', onPaginationChange }) {
	let params = yield select(ScanningAppDuc.selectors.paramsForPagination)
	if (onPaginationChange) {
		params = {
			...params,
			q: search || '',
			title: 'eventtitle',
		}
	} else {
		params = {
			q: search || '',
			title: 'eventtitle',
			start: 0,
			limit: 25,
		}
	}
	try {
		const response = yield call(() =>
			privateRequest.get(API.EVENT_LIST, { params })
		)
		if (response && response.status === 200) {
			const { results, total } = response.data.data
			if (onPaginationChange) {
				yield put(
					ScanningAppDuc.creators.getAllEventsSuccessAction(
						results,
						total
					)
				)
			} else {
				yield put(
					ScanningAppDuc.creators.getAllEventsSearchSuccess(
						results,
						total
					)
				)
			}
		}
	} catch (error) {
		yield put(ScanningAppDuc.creators.handleError(error, '/apps'))
	}
}
export function* getAllSessionsData({ params }) {
	try {
		if (!params.eventCode) {
			throw ERROR_TYPES.MISSED_PARAM
		}
		const response = yield call(() =>
			privateRequest.get(API.SESSION_LIST, { params })
		)
		if (response && response.status === 200) {
			const { venues } = response.data.data
			const sessionData = venues
				.map(item =>
					item.sessions.map(session =>
						Object.assign(session, {
							venue: item.venueName,
							venueCode: item.venueCode,
						})
					)
				)
				.flat()
			yield put(
				ScanningAppDuc.creators.getAllSessionsSuccessAction(sessionData)
			)
		}
	} catch (error) {
		yield put(
			ScanningAppDuc.creators.handleError(error, '/scanningApp/eventList')
		)
	}
}

export function* consumeBarcode() {
	const payload = yield select(ScanningAppDuc.selectors.payload)
	const { latitude, longitude, accuracy } = yield select(
		ScanningAppDuc.selectors.geoLocationData
	)
	const userAgent = navigator.userAgent
	const headers = {
		Latitude: latitude,
		Longitude: longitude,
		GpsAccuracy: accuracy,
		UserAgent: userAgent,
	}
	const { sessionId } = payload

	try {
		if (!sessionId) {
			throw ERROR_TYPES.MISSED_PARAM
		}
		privateRequest.defaults.headers = Object.assign(
			privateRequest.defaults.headers,
			headers
		)
		const response = yield call(() =>
			privateRequest.put(API.CONSUME, payload)
		)
		const {
			data: {
				data: { results: consumedTickets },
			},
		} = response
		yield put(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					event: 'gtm_allpage',
					app_code: 'DIY',
					event_name: 'scanning_success_viewed',
					screen_name: 'qr_scanner_screen',
					event_type: 'screen_view',
					event_code: payload.eventCode,
					show_session_id: payload.sessionId,
				},
			])
		)
		yield put(
			ScanningAppDuc.creators.toggleModalStateAction(
				{
					success: true,
					consumedTickets,
				},
				true
			)
		)
	} catch (err) {
		if (err.type === 'MISSED_PARAM') {
			yield put(
				ScanningAppDuc.creators.handleError(
					err,
					'/scanningApp/eventList'
				)
			)
			return
		}
		yield put(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					event: 'gtm_allpage',
					app_code: 'DIY',
					event_name: 'scanning_failure_viewed',
					screen_name: 'qr_scanner_screen',
					event_type: 'screen_view',
					event_code: payload.eventCode,
					show_session_id: payload.sessionId,
				},
			])
		)
		yield put(
			ScanningAppDuc.creators.toggleModalStateAction(
				{
					success: false,
					message: err.response.data.errors.message,
					code: err.response.data.errors.code.replace(/\./g, '_'),
				},
				true
			)
		)
	}
}
// fetches all the tickets associated with a booking ID
export function* searchTickets() {
	const params = yield select(
		ScanningAppDuc.selectors.paramsForSearchingTickets
	)
	try {
		const { sessionId } = params
		if (!sessionId) {
			throw ERROR_TYPES.MISSED_PARAM
		}

		const response = yield call(() =>
			privateRequest.get(API.SEARCH, { params })
		)
		const {
			data: {
				data: { results: tickets },
			},
		} = response

		yield put(ScanningAppDuc.creators.getTicketsSuccessAction(tickets))
		yield put(push('/scanningApp/tickets'))
	} catch (error) {
		if (error.type === 'MISSED_PARAM') {
			yield put(
				ScanningAppDuc.creators.handleError(
					error,
					'/scanningApp/eventList'
				)
			)
			return
		}

		yield put(ScanningAppDuc.creators.getTicketsErrAction())
		yield put(ScanningAppDuc.creators.handleError(error))
	}
}

export function handleError(response = {}) {
	const responseData = response.data || {
		errors: { message: 'Something went wrong. Please try again.' },
	}
	const { errors } = responseData
	console.log(errors)
}

function* openPopUp({ err: error, url }) {
	const message = getErrorMessage(error)

	yield put(
		AppDuc.creators.showPopup({
			title: 'Oops',
			content: message,
			verticalPosition: 'middle',
			nonCloseable: false,
			closeAction: dispatch => {
				if (url) {
					dispatch(ScanningAppDuc.creators.redirectToUrl(url))
				}

				dispatch(AppDuc.creators.hidePopup())
			},
		})
	)
}

function* redirectTo({ url }) {
	yield put(push(url))
}
const getErrorMessage = error => {
	if (error.type) {
		return error.message
	}
	if (error.message.includes('timeout')) {
		return 'Timeout Error'
	}

	const {
		response: {
			data: {
				errors: { message },
			},
		},
	} = error
	return message
}

export default function* rootSaga() {
	yield all([
		takeLatest(ScanningAppDuc.types.GET_ALL_EVENTS, getAllEventData),
		takeLatest(ScanningAppDuc.types.GET_ALL_SESSIONS, getAllSessionsData),
		takeLatest(ScanningAppDuc.types.CONSUME, consumeBarcode),
		takeLatest(ScanningAppDuc.types.GET_TICKETS, searchTickets),
		takeLatest(ScanningAppDuc.types.REDIRECT, redirectTo),
		takeLatest(ScanningAppDuc.types.HANDLE_ERROR, openPopUp),
	])
}
