import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import EventManagerDuc from 'modules/EventManagement/duc'
import Editor from 'common/components/Editor'
import { DesktopWrapper, MobileWrapper, TextLabel, TextError } from './__style'
import Button from 'bms-le-components/atoms/Button'
import InfoAlertBar from 'common/components/InfoAlertBar'
import Information from 'common/icons/information.svg'
import ErrorAlert from 'common/components/ErrorAlert'
import { AppDuc } from 'modules/App/duc'
import { validateInputFields } from 'modules/EventManagement/helpers'
import theme from 'bms-le-components/utils/base-ui-theme'

const MAX_LENGTH = 25000

function EventDescription({
	isDesktop,
	draftId,
	eventCode,
	synopsis,
	updateSynopsis,
	updateEventData,
	buttonLoading,
	rejectionReasons,
	analyticsPush,
}) {
	const history = useHistory()

	/** Strip HTML tags to calculate length */
	const synopsisWithoutTags = synopsis.replace(/(<([^>]+)™£¢∞§¶•ªªº>)/gi, '')
	const synopsisLength = synopsis ? synopsisWithoutTags.length : 0

	const onChange = (value, delta, source) => {
		// only if the changes are user initiated call onChange function
		if (source === 'user') {
			const synopsis = value.slice(0, MAX_LENGTH)
			updateSynopsis('synopsis', synopsis)
		}
	}

	const onSave = () => {
		analyticsPush({
			event_name: 'event_info_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'continue_clicked',
			screen_name: 'event_info',
		})
		updateEventData(draftId, eventCode, 'synopsis', synopsis)
		if (validateInputFields('synopsis', synopsisWithoutTags)) {
			history.goBack()
		}
	}

	const onBlur = () => {
		analyticsPush({
			event_name: 'event_info_actions',
			event_type: 'click',
			event: 'gtm_std_event',
			event_action: 'name_inputted',
			screen_name: 'event_info',
		})
	}

	const Wrapper = isDesktop ? DesktopWrapper : MobileWrapper

	return (
		<Wrapper>
			<div>
				{!eventCode ? (
					<InfoAlertBar
						isDesktop={isDesktop}
						svgIcon={Information}
						details="Describe your event. A good explanation will set the expectations right to your potential audience"
						spaceBottom
					/>
				) : null}
				{rejectionReasons.length > 0 && (
					<ErrorAlert
						errorMessages={rejectionReasons}
						isDesktop={isDesktop}
					/>
				)}
				<TextLabel>Event description</TextLabel>
				<Editor
					value={synopsis}
					onChange={onChange}
					showToolbar={true}
					editRegionHeight="80px"
					placeholder="Enter a brief description of the show"
					onBlur={onBlur}
					style={{
						border:
							synopsis &&
							(synopsis.length === MAX_LENGTH ||
								(synopsis.length !== MAX_LENGTH &&
									!validateInputFields(
										'synopsis',
										synopsisWithoutTags
									)))
								? '1px solid ' + theme.colors.errorRed
								: null,
					}}
				/>
				{synopsis && synopsis.length === MAX_LENGTH ? (
					<TextError>Character limit reached</TextError>
				) : null}
				{synopsis &&
				synopsis.length !== MAX_LENGTH &&
				!validateInputFields('synopsis', synopsisWithoutTags) ? (
					<TextError>{`Only these characters A-Za-z0-9,.(){}~[]<>?;:'"!@#$%^&*-=_+are allowed`}</TextError>
				) : null}
			</div>
			{!isDesktop && (
				<Button
					style={{ margin: '0 auto' }}
					size="LARGE"
					isFullWidth
					disabled={!synopsisLength}
					onClick={onSave}
					isLoading={buttonLoading}
				>
					Continue
				</Button>
			)}
		</Wrapper>
	)
}

const mapStateToProps = ({ eventManager, device }) => ({
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	synopsis: eventManager.activeEvent.synopsis || '',
	isDesktop: device.detection.isDesktop,
	buttonLoading: eventManager.buttonLoading,
	rejectionReasons:
		eventManager.activeEvent.formattedRejectionReasons?.synopsis || [],
})

const mapDispatchToProps = (dispatch) => ({
	updateSynopsis: (key, data) =>
		dispatch(EventManagerDuc.creators.updateSynopsis(key, data)),
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
	analyticsPush: (analyticsData) =>
		dispatch(
			AppDuc.creators.wrapAnalytics(AppDuc.creators.NoActionAnalytics(), [
				{
					type: 'GA',
					app_code: 'DIY',
					...analyticsData,
				},
			])
		),
})
export default connect(mapStateToProps, mapDispatchToProps)(EventDescription)
