import LoginDuc from '../modules/Login/duc'
import OffersDuc from '../modules/Offers/duc'
import ScanningAppDuc from '../modules/ScanningApp/duc'
import EventManagerDuc from 'modules/EventManagement/duc'
import DashboardDuc from 'modules/Dashboard/duc'
import OrganiserApprovalDuc from 'modules/OrganiserApproval/duc'
import CreateAccountDuc from 'modules/CreateAccount/duc'
import { AppDuc } from '../modules/App/duc'
import { DeviceDetectionDuc } from './deviceDetectionDuc'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		login: LoginDuc.reducer,
		offers: OffersDuc.reducer,
		device: DeviceDetectionDuc.reducer,
		app: AppDuc.reducer,
		ScanningApp: ScanningAppDuc.reducer,
		eventManager: EventManagerDuc.reducer,
		dashboard: DashboardDuc.reducer,
		organiserApproval: OrganiserApprovalDuc.reducer,
		createAccount: CreateAccountDuc.reducer,
	})

export default rootReducer
