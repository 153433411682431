import { createComponent } from 'react-fela'

export const EventName = createComponent(() => ({
	fontSize: '28px',
	lineHeight: '28px',
	fontWeight: 700,
	wordBreak: 'break-word',
}))

export const CategoriesHolder = createComponent(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '8px',
}))

export const ActionText = createComponent(
	() => ({
		fontFamily: 'Roboto',
		fontSize: '12px',
		lineHeight: '16px',
		color: '#0378FF',
		marginRight: '10px',
	}),
	'span',
	['onClick']
)

export const Wrapper = createComponent(({ isDesktop }) => ({
	extend: [
		{
			condition: !isDesktop,
			style: {
				paddingLeft: '16px',
				paddingTop: '30px',
				paddingBottom: '25px',
			},
		},
	],
}))
