import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LoaderWrapper } from './__style'

const Loader = () => {
	return (
		<LoaderWrapper>
			<CircularProgress size={30} />
		</LoaderWrapper>
	)
}

export default Loader
