import { createComponent } from 'react-fela'

export const Alert = createComponent(
	({ isDesktop }) => ({
		background: '#FFFFFF',
		boxShadow: !isDesktop ? '0px 1px 8px rgba(0, 0, 0, 0.16)' : '0px',
		borderRadius: '4px',
		width: '90%',
		padding: !isDesktop ? '18px' : '0px 0px 0px 18px',
		color: '#E7364D',
		// listStyleImage: `url(
		// 	'../../icons/Alert.svg')`,
		marginBottom: '10px',
	}),
	'ul'
)

export const ListItem = createComponent(
	() => ({
		fontFamily: 'Roboto',
		fontSize: '12px',
		lineHeight: '16px',
		color: '#CD0000',
	}),
	'li'
)
