import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import EventDescription from 'modules/EventManagement/components/EventDescription'
import EventReasons from 'modules/EventManagement/components/EventReasons'
import Button from 'bms-le-components/atoms/Button'
import EventManagerDuc from 'modules/EventManagement/duc'
import { ButtonWrapper } from './__style'

function AboutEventDesktop({
	isDesktop,
	draftId,
	eventCode,
	synopsis,
	reasonsToAttend,
	updateEventData,
	eventNotification,
	showSaveButton,
}) {
	const { section } = useParams()

	const handleSaveInfo = () => {
		updateEventData(draftId, eventCode, 'synopsis', synopsis)
		updateEventData(draftId, eventCode, 'reasonsToAttend', reasonsToAttend)
	}

	return (
		<>
			<EventDescription />
			<EventReasons />
			{section &&
			showSaveButton &&
			eventNotification?.type !== 'review' ? (
				<ButtonWrapper>
					<Button
						style={{ margin: '0 auto' }}
						size="LARGE"
						onClick={handleSaveInfo}
						isFullWidth={!isDesktop}
					>
						Save
					</Button>
				</ButtonWrapper>
			) : null}
		</>
	)
}

const mapStateToProps = ({ device, eventManager }) => ({
	isDesktop: device.detection.isDesktop,
	draftId: eventManager.activeEvent.draftId || '',
	eventCode: eventManager.activeEvent.eventCode || '',
	synopsis: eventManager.activeEvent.synopsis || '',
	reasonsToAttend: eventManager.activeEvent.reasonsToAttend || ['', '', ''],
	eventNotification: eventManager?.eventNotification,
	showSaveButton: eventManager?.showSaveButton,
})

const mapDispatchToProps = dispatch => ({
	updateEventData: (draftId, eventCode, section, sectionInfo) =>
		dispatch(
			EventManagerDuc.creators.updateEventData(
				draftId,
				eventCode,
				section,
				sectionInfo
			)
		),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AboutEventDesktop)
