import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import { addDays } from 'date-fns'
import { request, privateRequest, newUserRequest } from '../../utils/request'
import { push } from 'connected-react-router'
import LoginDuc from './duc'
import { M5 } from './../../utils/storage'
import { AppDuc } from './../App/duc'
import { genericErrorMesssage } from './constants'
import { GENERAL_INFO_PATH } from 'modules/CreateAccount/constants'
// import OffersDuc from '../Offers/duc'
// Api call for authentication , returns mfaToken in step 1 and access token post the mfa success using OTP
const authenticate = (params) => {
	return request.post(`auth/token`, { ...params })
}

// Api call made to add another level of authentication - invokes OTP currently
const challengeAuthentication = (params) => {
	return request.post(`auth/challenge`, { ...params })
}

export function* getOTP(tokenData) {
	try {
		const { params } = tokenData
		const challengeResponse = yield call(() =>
			challengeAuthentication(params)
		)
		const { data } = challengeResponse
		if (data.status === 200) {
			yield put(
				LoginDuc.creators.challengeAuthenticationSuccess(data.data)
			)
			yield put(push('/login/otp'))
		}
	} catch (error) {
		const { response } = error
		yield put(LoginDuc.creators.loginError(response.data))
		const { errors } = response.data
		yield put(
			AppDuc.creators.showPopup({
				title: 'Oops',
				content: errors.message,
				verticalPosition: 'middle',
				nonCloseable: false,
				closeAction: (dispatch) => {
					dispatch(AppDuc.creators.hidePopup())
				},
			})
		)
	}
}
// Api call made to initiate forgot password
export function* sendOtp(info) {
	const {
		params: {
			mobileNo = '',
			isSignUpFLow = false,
			isResendOtp = false,
			isPasswordResetFLow = false,
		},
	} = info

	try {
		const root = yield select(LoginDuc.selectors.root)
		const {
			login: { otpResponse = {} },
		} = root

		const headers = {
			'x-mobile-no': mobileNo,
			...(otpResponse?.id && { 'x-otp-id': otpResponse?.id }),
		}

		request.defaults.headers = Object.assign(
			request.defaults.headers,
			headers
		)

		let response = {}

		if (isSignUpFLow) {
			const otpType =
				isResendOtp && otpResponse?.id ? 'resend-otp' : 'send-otp'
			response = yield call(() => request.post(`v1/signup/${otpType}`))
		} else {
			response = yield call(() =>
				request.post(`auth/password/forgot`, { username: mobileNo })
			)
		}

		const {
			status,
			data: { data = {} },
		} = response || {}

		if (status === 200) {
			yield put(LoginDuc.creators.sendOtpSuccess(data))

			if (!isResendOtp) {
				if (isSignUpFLow && !data?.userExist) {
					yield put(push('/signup/otp'))
				} else if (isPasswordResetFLow) {
					yield put(push('/login/reset/otp'))
				} else {
					yield put(push('/login/otp'))
				}
			}
		}
	} catch (error) {
		const {
			response: { data = {} },
		} = error || {}
		const errorMessage = isSignUpFLow
			? data?.message?.shortDesc
			: data?.errors?.message

		if (isPasswordResetFLow && data?.errors?.code === 'ERR.NEW.USER') {
			yield call(sendOtp, { params: mobileNo, isSignUpFLow: true })
		}

		yield put(LoginDuc.creators.sendOtpError({ message: errorMessage }))
	}
}
// Api call made to initiate reset password
export function* resetPasswordInit(data) {
	try {
		const { params } = data
		const response = yield call(() =>
			request.post(`auth/password/reset`, { ...params })
		)
		if (response && response.status === 200) {
			M5.Storage.del({ name: 'mfaToken' })
			yield put(LoginDuc.creators.resetPasswordSuccess())
			yield put(push('/login'))
		} else {
			throw response
		}
	} catch (errorResponse) {
		const { data } = errorResponse.response && errorResponse.response
		const { errors, status } = data
		yield put(LoginDuc.creators.loginError(data))
		if (status === 400) {
			yield put(
				AppDuc.creators.showPopup({
					title: 'Please try again with a valid password',
					content: errors.message,
					verticalPosition: 'middle',
					nonCloseable: false,
					closeAction: (dispatch) => {
						dispatch(AppDuc.creators.hidePopup())
					},
					buttons: [
						{
							text: 'Okay',
							action: (dispatch) => {
								dispatch(AppDuc.creators.hidePopup())
							},
						},
					],
					contentstart: true,
				})
			)
		} else {
			yield put(
				AppDuc.creators.showPopup({
					title: 'Oops, things went south! But not for long!',
					content: errors.message,
					verticalPosition: 'middle',
					nonCloseable: false,
					closeAction: (dispatch) => {
						dispatch(AppDuc.creators.hidePopup())
						dispatch(push('/login'))
					},
					buttons: [
						{
							text: 'Okay',
							action: (dispatch) => {
								dispatch(AppDuc.creators.hidePopup())
								dispatch(push('/login'))
							},
						},
					],
					contentstart: true,
				})
			)
		}
	}
}
// Api call made to validate OTP for reset
export function* verifyOtp(info) {
	const {
		params: {
			otp = '',
			mobileNo = '',
			isSignUpFLow = false,
			isPasswordResetFLow = false,
		},
	} = info

	try {
		const root = yield select(LoginDuc.selectors.root)
		const {
			login: { otpResponse = {} },
		} = root
		let response = {}

		if (isSignUpFLow) {
			const headers = {
				...(otpResponse?.id && { 'x-otp-id': otpResponse?.id }),
			}

			request.defaults.headers = Object.assign(
				request.defaults.headers,
				headers
			)

			response = yield call(() =>
				request.post(`v1/signup/verify-otp`, { otp })
			)
		} else if (isPasswordResetFLow) {
			response = yield call(() =>
				request.post(`auth/verifyotp`, { otp, username: mobileNo })
			)
		}

		if (response && response.status === 200) {
			const { data } = response.data

			if (isSignUpFLow) {
				const { data } = response
				const { token } = data.data
				if (!token) {
					throw new Error()
				}
				const date = addDays(new Date(), 1)

				const expiry = date.toUTCString()

				M5.Storage.set({
					name: 'newUserAccessToken',
					value: token,
					expires: expiry,
					stringify: false,
					secure: true,
					storage: 'C',
					httpOnly: true,
				})

				yield put(LoginDuc.creators.signUpSuccess(mobileNo))
				yield put(push(GENERAL_INFO_PATH))
			} else {
				yield put(LoginDuc.creators.otpToResetSuccess(data.sessionID))
				yield put(push('/login/reset'))
			}
		}
	} catch (error) {
		let errorMessage = genericErrorMesssage

		if (error?.response?.data) {
			const {
				response: { data = {} },
			} = error || {}
			errorMessage = isSignUpFLow
				? data?.message?.shortDesc
				: data?.errors?.message
		}

		yield put(LoginDuc.creators.verifyOtpError(errorMessage))
	}
}
// Api call made to Log Out
export function* logOut() {
	try {
		M5.Storage.del({ name: 'accessToken' })
		yield put(LoginDuc.creators.logOutSuccess())
		yield put(AppDuc.creators.hidePopup())
		yield put(push('/login'))
		yield call(() => privateRequest.post(`auth/logout`))
	} catch (error) {
		return
	}
}

export function* loginVerification(data) {
	const { params } = data

	try {
		const response = yield call(() => authenticate(params))
		// If successful, user is authenticated, dispatch success and allow him to access the dashboard
		// Store access token in cookie
		if (response && response.status === 200) {
			const { data } = response
			const { accessToken } = data.data
			const date = new Date()
			date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
			const expiry = date.toUTCString()
			M5.Storage.set({
				name: 'accessToken',
				value: `${accessToken}`,
				expires: expiry,
				stringify: false,
				secure: true,
				storage: 'C',
				httpOnly: true,
			})
			//dispatch login success
			if (accessToken !== '') {
				yield put(push('/apps'))
				M5.Storage.del({ name: 'mfaToken' })
				yield put(
					LoginDuc.creators.logInSuccess(params.username, accessToken)
				)
			}
		} else if (response && response.status === 202) {
			const { data } = response
			M5.Storage.del({ name: 'accessToken' })
			yield put(
				LoginDuc.creators.challengeAuthenticationSuccess(
					data.data.challenge
				)
			)
			yield put(push('/login/otp'))
		}
	} catch (error) {
		const { response = {} } = error
		const {
			data: {
				errors: {
					code: errorCode = '',
					message: errorMessage = '',
				} = {},
			} = {},
		} = response

		if (errorCode === 'ERR.NEW.USER') {
			if (params?.mobileNumber) {
				yield call(sendOtp, {
					params: {
						mobileNo: params.mobileNumber,
						isSignUpFLow: true,
					},
				})
			}

			yield put(
				AppDuc.creators.showToast(
					'error',
					true,
					errorMessage,
					false,
					6000
				)
			)

			return
		}

		try {
			const { isLoginWithOtp = false } = params

			if (response && response.status === 401) {
				const { errors, mfaToken } = response.data
				if (errors === 'mfa_required' && mfaToken) {
					const date = new Date()
					date.setTime(date.getTime() + 15 * 60 * 1000)
					M5.Storage.set({
						name: 'mfaToken',
						value: `${mfaToken}`,
						stringify: false,
					})
					const params = { mfaToken }
					const challengeResponse = yield call(() =>
						challengeAuthentication(params)
					)
					const { data } = challengeResponse
					if (data.status === 200) {
						yield put(
							LoginDuc.creators.challengeAuthenticationSuccess(
								data.data
							)
						)
						yield put(push('/login/otp'))
					}
				}
			} else {
				yield call(loginFlowError, response, isLoginWithOtp)
			}
		} catch (error) {
			const { response } = error
			yield call(loginFlowError, response)
		}
	}
}

export function* loginFlowError(response = {}, isLoginWithOtp = false) {
	const responseData = response.data || {
		errors: { message: genericErrorMesssage },
	}
	const {
		errors: { message },
	} = responseData

	if (isLoginWithOtp) {
		yield put(LoginDuc.creators.verifyOtpError(message))
	} else {
		yield put(LoginDuc.creators.loginError({ message: '' }))
		yield put(
			AppDuc.creators.showToast(
				'error',
				true,
				message || genericErrorMesssage,
				false,
				6000
			)
		)
	}
}

export function* validateUser(params) {
	try {
		const { id } = params

		newUserRequest.defaults.headers = Object.assign(
			newUserRequest.defaults.headers,
			{
				'x-login-Id': id,
			}
		)

		const {
			data: {
				data: { success: isValidatationSuccess = false },
			},
		} = yield call(() => newUserRequest.get('v1/validate/login-cta'))

		if (!isValidatationSuccess) {
			M5.Storage.del({ name: 'newUserAccessToken' })
			yield put(push('/login'))
		}
	} catch (error) {
		const {
			response: {
				data: { shortDesc = '' },
			},
		} = error

		M5.Storage.del({ name: 'newUserAccessToken' })
		yield put(push('/login'))

		yield put(
			AppDuc.creators.showToast('error', true, shortDesc, false, 6000)
		)
	}
}

export default function* LoginSaga() {
	yield all([
		takeLatest(LoginDuc.types.LOGIN_VERIFICATION, loginVerification),
		takeLatest(LoginDuc.types.SEND_OTP, sendOtp),
		takeLatest(LoginDuc.types.RESET_PASSWORD_INIT, resetPasswordInit),
		takeLatest(LoginDuc.types.OTP_TO_RESET, verifyOtp),
		takeLatest(LoginDuc.types.LOG_OUT_INIT, logOut),
		takeLatest(LoginDuc.types.GET_OTP, getOTP),
		takeLatest(LoginDuc.types.VALIDATE_USER, validateUser),
	])
}
