import theme from 'bms-le-components/utils/base-ui-theme'
import { DeviceDetectionDuc } from './deviceDetectionDuc'
import debounce from 'lodash/debounce'

const baseOrientationQueries = theme.orientationQueries

const baseStateAliases = {
	mobile: 'isMobile',
	desktop: 'isDesktop',
	tablet: 'isTablet',
	landscape: 'isLandscape',
	portrait: 'isPortrait',
}
const basePlatforms = ['mobile', 'tablet', 'desktop']
const baseMediaQueries = basePlatforms.reduce((agg, platform) => {
	const aggregator = agg
	const query = theme.mediaQueries[platform]
	if (query) {
		aggregator[platform] = query
	}

	return aggregator
}, {})

const syncStateHelper = store => currentState => {
	// server state initialize to the what was detected from server
	store.dispatch(
		DeviceDetectionDuc.creators.syncDeviceDetection(currentState)
	)
}
const fetchOrientationMatches = () =>
	Object.keys(baseOrientationQueries).reduce((agg, key) => {
		const aggregator = agg
		aggregator[baseStateAliases[key]] = window.matchMedia(
			baseOrientationQueries[key]
		)

		return aggregator
	}, {})
const fetchMediaMatches = () =>
	Object.keys(baseMediaQueries).reduce((agg, key) => {
		const aggregator = agg
		aggregator[baseStateAliases[key]] = window.matchMedia(
			baseMediaQueries[key]
		)

		return aggregator
	}, {})
const initiateClientSync = (stateSyncer, matchersMap = {}) => {
	const listenerReferences = {}
	Object.keys(matchersMap).forEach(stateKey => {
		const mqList = matchersMap[stateKey]
		if (!mqList || !mqList.addListener) return

		// initial sync
		stateSyncer({ [stateKey]: mqList.matches })

		if (!listenerReferences[stateKey]) {
			listenerReferences[stateKey] = debounce(
				e => stateSyncer({ [stateKey]: e.matches }),
				300
			)
		}
		// attach a listener to retrigger on change
		mqList.removeListener(listenerReferences[stateKey])
		mqList.addListener(listenerReferences[stateKey])
	})
}
// eslint-disable-next-line no-unused-vars
const handleStateChange = (store, initialState) => {
	const stateSyncer = syncStateHelper(store)
	const deviceMatchMap = fetchMediaMatches()
	document.addEventListener('DOMContentLoaded', () => {
		initiateClientSync(stateSyncer, deviceMatchMap)
	})
	const orientationMatchMap = fetchOrientationMatches()

	window.addEventListener('orientationchange', () => [
		initiateClientSync(stateSyncer, orientationMatchMap),
	])
}

const deviceDetectionEnhancer = createStore => (...args) => {
	const store = createStore(...args)
	handleStateChange(store, {})
	return store
}

export default deviceDetectionEnhancer
