import { applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMidleware from 'redux-saga'
import RootSaga from './sagaRunner'
import rootReducer from './reducers'
import deviceDetectionEnhancer from './deviceDetection'
import analyticsMiddleware from './analyticsMiddleWare'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

export default function configureAppStore() {
	const sagaMiddleware = createSagaMidleware()
	const middlewares = applyMiddleware(
		routerMiddleware(history),
		sagaMiddleware,
		analyticsMiddleware()
	)

	const enhancers = [deviceDetectionEnhancer, middlewares]

	const store = configureStore({
		reducer: rootReducer(history),
		enhancers: enhancers,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					// Ignore these action types
					ignoredActions: [
						'app/global/SHOW_POPUP',
						'diy-reporting/createAccount/UPLOAD_FILE',
						'diy-reporting/createAccount/UPLOAD_AGREEMENT',
					],
					// Ignore these field paths in all actions
					ignoredActionPaths: [
						'app.popup.config.closeAction',
						'app.popup.config.closeAction',
					],
					// Ignore these paths in the state
					ignoredPaths: [
						'app.popup.config.closeAction',
						'app.popup.config.buttons.0.action',
						'app.popup.config.buttons.1.action',
					],
				},
			}),
	})
	sagaMiddleware.run(RootSaga)

	return store
}
