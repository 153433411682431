import Duck from 'extensible-duck'
import { getIn, setIn } from 'timm'
const { IAM } = window

// helpers
import { getCookie } from 'utils/helpers'

const initalAccessToken = IAM ? IAM?.token() : getCookie('accessToken')
const newUserAccessToken = getCookie('newUserAccessToken')

const initialState = {
	isLoggedIn: !!initalAccessToken,
	isSignedUp: !!newUserAccessToken,
	userDetails: {},
	active: 'login',
	prev: '',
	next: '',
	showOtpInterface: false,
	showResendInterface: false,
	showResetPassword: false,
	accessToken: initalAccessToken || '',
	otpResponse: {},
	errorMessage: '',
	sendOtpError: '',
	verifyOtpError: '',
}

export default new Duck({
	namespace: 'diy',
	store: 'login',
	types: [
		'SHOW_OTP_INTERFACE',
		'CHALLENGE_AUTH_SUCCESS',
		'LOGIN_VERIFICATION',
		'SHOW_RESEND_INTERFACE',
		'LOG_IN_SUCCESS',
		'SEND_OTP',
		'SHOW_RESET_PASSWORD',
		'RESET_PASSWORD_INIT',
		'RESET_PASSWORD_SUCCESS',
		'SEND_OTP_SUCCESS',
		'SEND_OTP_ERROR',
		'OTP_TO_RESET',
		'OTP_TO_RESET_SUCCESS',
		'LOG_OUT_INIT',
		'LOG_OUT_SUCCESS',
		'FORGOT_PASSWORD_ERROR',
		'LOGIN_ERROR',
		'CHALLENGE_AUTH_ERROR',
		'LOG_OUT_ERROR',
		'OTP_TO_RESET_ERROR',
		'FLUSH_LOGIN_STATE',
		'GET_OTP',
		'SIGN_UP_SUCCESS',
		'VERIFY_OTP_ERROR',
		'VALIDATE_USER',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SHOW_OTP_INTERFACE: {
				return setIn(
					setIn(state, ['loading'], false),
					['showOtpInterface'],
					true
				)
			}
			case duck.types.SEND_OTP_SUCCESS: {
				return {
					...state,
					loading: false,
					showOtpInterface: true,
					showResetPassword: false,
					showResendInterface: true,
					otpResponse: action.data || {},
					sendOtpError: '',
				}
			}
			case duck.types.SEND_OTP_ERROR: {
				const { message = '' } = action.error
				return {
					...state,
					loading: false,
					sendOtpError: message,
				}
			}
			case duck.types.FORGOT_PASSWORD_ERROR: {
				//TODO: Add error message from backend
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: 'Username is incorrect',
				}
			}
			case duck.types.LOGIN_VERIFICATION: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.LOG_IN_SUCCESS: {
				const { userName, accessToken } = action
				return {
					...state,
					isLoggedIn: true,
					userDetails: { userName },
					loading: false,
					error: false,
					showOtpInterface: false,
					accessToken,
				}
			}
			case duck.types.SIGN_UP_SUCCESS: {
				const { userName } = action
				return {
					...state,
					isSignedUp: true,
					userDetails: { userName },
					error: false,
					errorMessage: '',
				}
			}
			case duck.types.CHALLENGE_AUTH_SUCCESS: {
				const mobile =
					getIn(action.data, ['additionalInfo', 'channel', 'sms']) ||
					''
				return {
					...state,
					loading: false,
					error: false,
					otpConfig: {
						expiry: action.data.expiresIn,
						interval: action.data.interval,
						mobile: mobile,
					},
					showOtpInterface: true,
				}
			}
			case duck.types.CHALLENGE_AUTH_ERROR: {
				//TODO: Add error message from backend
				return {
					...state,
					loading: false,
					error: true,
				}
			}
			case duck.types.RESET_PASSWORD_SUCCESS: {
				return {
					...state,
					loading: false,
					error: false,
					showOtpInterface: false,
					showResetPassword: false,
					showResendInterface: false,
				}
			}

			case duck.types.LOGIN_ERROR: {
				const { message } = action.error
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: message,
				}
			}
			case duck.types.SHOW_RESEND_INTERFACE: {
				return setIn(state, ['showResendInterface'], true)
			}
			case duck.types.OTP_TO_RESET: {
				return {
					...state,
					loading: true,
				}
			}
			case duck.types.OTP_TO_RESET_SUCCESS: {
				const { sessionID } = action
				return {
					...state,
					loading: false,
					error: false,
					showOtpInterface: false,
					showResetPassword: true,
					otpSessionId: sessionID,
				}
			}
			case duck.types.OTP_TO_RESET_ERROR: {
				//TODO: Add error message from backend
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: 'Username is incorrect',
				}
			}
			case duck.types.SEND_OTP: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.RESET_PASSWORD_INIT: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.LOG_OUT_INIT: {
				return setIn(state, ['loading'], true)
			}
			case duck.types.LOG_OUT_SUCCESS: {
				return {
					...state,
					isLoggedIn: false,
					accessToken: '',
					loading: false,
				}
			}
			case duck.types.LOG_OUT_ERROR: {
				//TODO: Add error message from backend
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: 'Unable to login',
				}
			}
			case duck.types.FLUSH_LOGIN_STATE: {
				return initialState
			}
			case duck.types.VERIFY_OTP_ERROR: {
				const { message = '' } = action
				return {
					...state,
					loading: false,
					verifyOtpError: message,
				}
			}
			default:
				return state
		}
	},
	selectors: {
		root: (state) => state,
	},
	creators: (duck) => ({
		showOtpInterface: () => ({
			type: duck.types.SHOW_OTP_INTERFACE,
		}),
		logInSuccess: (userName, accessToken) => ({
			type: duck.types.LOG_IN_SUCCESS,
			userName,
			accessToken,
		}),
		signUpSuccess: (userName) => ({
			type: duck.types.SIGN_UP_SUCCESS,
			userName,
		}),
		loginVerification: (params) => ({
			type: duck.types.LOGIN_VERIFICATION,
			params,
		}),
		challengeAuthenticationSuccess: (data) => ({
			type: duck.types.CHALLENGE_AUTH_SUCCESS,
			data,
		}),
		challengeAuthenticationError: (error) => ({
			type: duck.types.CHALLENGE_AUTH_ERROR,
			error,
		}),
		sendOtp: (params) => ({
			type: duck.types.SEND_OTP,
			params,
		}),
		sendOtpSuccess: (data) => ({
			type: duck.types.SEND_OTP_SUCCESS,
			data,
		}),
		sendOtpError: (error) => ({
			type: duck.types.SEND_OTP_ERROR,
			error,
		}),
		forgotPasswordError: (error) => ({
			type: duck.types.FORGOT_PASSWORD_ERROR,
			error,
		}),
		forgotPasswordClick: () => ({
			type: duck.types.SHOW_RESEND_INTERFACE,
		}),
		verifyOtp: (params) => ({
			type: duck.types.OTP_TO_RESET,
			params,
		}),
		otpToResetSuccess: (sessionID) => ({
			type: duck.types.OTP_TO_RESET_SUCCESS,
			sessionID,
		}),
		otpToResetError: (error) => ({
			type: duck.types.OTP_TO_RESET_ERROR,
			error,
		}),
		resetPasswordInit: (params) => ({
			type: duck.types.RESET_PASSWORD_INIT,
			params,
		}),
		resetPasswordSuccess: () => ({
			type: duck.types.RESET_PASSWORD_SUCCESS,
		}),
		loginError: (error) => ({
			type: duck.types.LOGIN_ERROR,
			error,
		}),
		handleLogOut: (params) => ({
			type: duck.types.LOG_OUT_INIT,
			params,
		}),
		logOutSuccess: () => ({
			type: duck.types.LOG_OUT_SUCCESS,
		}),
		logOutError: (error) => ({
			type: duck.types.LOG_OUT_ERROR,
			error,
		}),
		flushLoginState: () => ({
			type: duck.types.FLUSH_LOGIN_STATE,
		}),
		getOTP: (params) => ({
			type: duck.types.GET_OTP,
			params,
		}),
		verifyOtpError: (message) => ({
			type: duck.types.VERIFY_OTP_ERROR,
			message,
		}),
		validateUser: (id) => ({
			type: duck.types.VALIDATE_USER,
			id,
		}),
	}),
})
