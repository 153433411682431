import { createComponent } from 'react-fela'

const __wrapper = ({ isDesktop }) => ({
	textAlign: 'center',
	margin: isDesktop ? 40 : 10,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
})

const __buttonWrapper = ({ isDesktop }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-end',
	margin: '30px 0',
	extend: [
		{
			condition: isDesktop,
			style: {
				'>div': {
					marginLeft: 20,
				},
			},
		},
	],
})

const Wrapper = createComponent(__wrapper)
const ButtonWrapper = createComponent(__buttonWrapper)

export { Wrapper, ButtonWrapper }
