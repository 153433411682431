import React from 'react'
import { string, bool, func } from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

/** Toolbar config for Quill editor. */
const config = showToolbar => {
	let toolbar = null
	let formats = []
	if (showToolbar) {
		toolbar = [['bold', 'italic', 'underline']]
		formats = ['bold', 'italic', 'underline']
	}
	return {
		modules: { toolbar },
		formats: formats,
	}
}

const Editor = ({
	value,
	onChange,
	showToolbar,
	editRegionHeight,
	...rest
}) => {
	const [content, setContent] = React.useState(value)

	return (
		<ReactQuill
			value={content}
			onChange={(value, _, source) => {
				setContent(value)
				onChange(value, _, source)
			}}
			{...config(showToolbar)}
			{...rest}
		>
			<div style={{ minHeight: editRegionHeight }} />
		</ReactQuill>
	)
}

Editor.propTypes = {
	value: string,
	onChange: func,
	showToolbar: bool,
	editRegionHeight: string,
}

Editor.defaultProps = {
	value: '',
	onChange: () => {},
	onBlur: () => {},
	showToolbar: false,
	editRegionHeight: '50px',
}

export default Editor
