import { createComponent } from 'react-fela'

export const DesktopWrapper = createComponent(() => ({
	paddingBottom: '20px',
}))

export const MobileWrapper = createComponent(() => ({
	display: 'flex',
	flexDirection: 'column',
	minHeight: `calc(100vh - 90px)`,
}))

export const TextLabel = createComponent(() => ({
	fontWeight: 500,
	fontSize: '12px',
	lineHeight: '16px',
	color: '#666',
	paddingBottom: '6px',
}))

export const TextError = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: '#CD0000',
}))

export const TextDescription = createComponent(mb => ({
	fontFamily: 'Roboto',
	fontSize: '12px',
	color: '#666666',
	marginTop: '10px',
	marginBottom: mb ? '10px' : 0,
}))

export const ContentWrapper = createComponent(() => ({
	marginLeft: '16px',
	marginRight: '16px',
}))

export const ButtonWrapper = createComponent(() => ({
	position: 'absolute',
	bottom: 0,
	right: 0,
	width: '100%',
	display: 'flex',
	backgroundColor: '#fff',
	padding: '7.5px 15px',
	justifyContent: 'center',
	boxShadow: '0px 0 5px rgba(0, 0, 0, 0.2)',
}))
