import { createComponent } from 'react-fela'

export const VenuePlaceHolder = createComponent(
	({ theme, disabled }) => ({
		margin: theme.spaces[16],
		paddingBottom: theme.spaces[12],
		borderBottom: '1px solid #DDDDDD',
		extend: [
			{
				condition: disabled,
				style: { pointerEvents: 'none', opacity: '50%' },
			},
		],
	}),
	'div',
	['onClick', 'onPointerDown']
)

export const VenueLabel = createComponent(() => ({
	height: '28px',
	background: '#EEEEEE',
	fontSize: '10px',
	lineHeight: '16px',
	textTransform: 'uppercase',
	paddingLeft: '20px',
	paddingTop: '6px',
}))

export const VenueName = createComponent(({ theme, disabled, isDesktop }) => ({
	fontSize: theme.font.size14,
	fontWeight: isDesktop ? theme.font.weight400 : theme.font.weight500,
	lineHeight: '24px',
	color: disabled ? '#999999' : '#222222',
	cursor: 'pointer',
}))

export const Address = createComponent(() => ({
	fontSize: '12px',
	lineHeight: '16px',
	color: '#999999',
}))

export const SelectedChipsHolder = createComponent(
	({ isDesktop, isAgeLimitChips, isBottomDrawer }) => ({
		display: 'flex',
		overflowX: 'scroll',
		flexFlow: 'wrap',
		gap: 4,
		padding: `${isAgeLimitChips ? '20px' : '8px'} 12px 0 ${
			isDesktop ? '0px' : '16px'
		}`,
		extend: [
			{
				condition: isDesktop || !isBottomDrawer,
				style: {
					paddingLeft: '0px !important',
					paddingRight: '0px !important',
				},
			},
		],
	})
)

export const SearchWrapper = createComponent(
	({ isDesktop }) => ({
		padding: !isDesktop ? '7.5px 16px' : '0',
	}),
	'div',
	['onFocus']
)

export const ListingWrapper = createComponent(({ isDesktop }) => ({
	position: isDesktop ? 'absolute' : 'static',
	width: '100%',
	backgroundColor: '#ffffff',
	overflow: 'scroll',
	maxHeight: isDesktop ? '240px' : '360px',
	minHeight: '150px',
	boxShadow: '0 0 1.5px rgba(0,0,0,0.9)',
	zIndex: 1,
	extend: [{ condition: !isDesktop, style: { paddingBottom: 70 } }],
}))

export const SelectorWrapper = createComponent(
	({ isDesktop }) => ({
		width: '100%',
		outline: 'none',
		position: isDesktop ? 'relative' : 'static',
	}),
	'div',
	['tabIndex']
)
export const ScrollSection = createComponent(
	({ minHeight, isDesktop, hideBorder }) => ({
		overflow: 'scroll',
		maxHeight: isDesktop ? '158px' : '350px',
		minHeight: minHeight,
		position: isDesktop ? 'absolute' : 'static',
		paddingBottom: '0px',
		zIndex: '201',
		width: '100%',
		background: 'white',
		borderRadius: '0 0 8px 8px',
		backgroundColor: '#FFFFFF',
		border: hideBorder ? 'none' : '1px solid #cccccc',
		boxShadow: hideBorder ? 'none' : '0px 1px 2px rgb(0 0 0 / 20%)',
	})
)

export const SearchBarWrapper = createComponent(
	({ isDesktop, isBottomDrawer }) => ({
		paddingLeft: '16px',
		paddingRight: '16px',
		extend: [
			{
				condition: isDesktop || !isBottomDrawer,
				style: { paddingLeft: 0, paddingRight: 0 },
			},
		],
	}),
	'div',
	['onFocus', 'onBlur']
)

export const NoContentWrapper = createComponent(() => ({ textAlign: 'center' }))

export const Wrapper = createComponent(({ isDesktop }) => ({
	minWidth: 240,
	marginTop: '12px',
	extend: [
		{
			condition: isDesktop,
			style: {
				width: '36vw',
			},
		},
		{
			condition: !isDesktop,
			style: {
				padding: '0 20px',
			},
		},
	],
}))

export const Header = createComponent(() => () => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '5px 0',
	'> div:nth-child(1)': { flexGrow: 1 },
}))

export const FilterWrapper = createComponent(() => () => ({
	borderTop: '1px solid #CCCCCC',
	margin: '10px 0 20px',
}))

export const CategoryTextWrapper = createComponent(({ isBottomDrawer }) => ({
	padding: '0 16px',
	extend: [
		{
			condition: !isBottomDrawer,
			style: {
				padding: 0,
			},
		},
	],
}))

export const CategoryTextLabel = createComponent(() => ({
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '20px',
	color: 'black',
	height: '25px',
}))

export const CategoryHelpText = createComponent(({ isMaxCategories }) => {
	return {
		fontSize: '12px',
		lineHeight: '16px',
		color: isMaxCategories ? '#DC354D' : '#666666',
	}
})

export const NoEventsBlock = createComponent(() => ({
	textAlign: 'center',
	marginTop: '8px',
}))

export const NoEventsMessage = createComponent(({ theme }) => ({
	color: theme.colors.darkGrey5,
	fontWeight: theme.font.weight500,
	margin: `${theme.spaces[16]} 0px ${theme.spaces[8]}`,
	fontSize: theme.font.size14,
}))

export const EmailInfoWrapper = createComponent(() => ({
	textAlign: 'center',
	fontSize: '12px',
	margin: '7.5px auto',
}))

export const RightArrowIconWrapper = createComponent(() => ({
	width: '12px',
	marginRight: '8px',
	marginLeft: 'auto',
	marginTop: 'auto',
	marginBottom: 'auto',
}))

export const OnlineEventsContainer = createComponent(
	({ isDesktop, theme }) => ({
		width: '100%',
		// height: '100%',
		backgroundColor: theme.colors.bmsInfo,
		borderTop: `1px solid ${theme.colors.lightGrey2}`,
		padding: isDesktop ? '25px' : '16px',
		height: '450px',
	})
)

export const OnlineVenueHeading = createComponent(({ theme }) => ({
	backgroundColor: theme.colors.white,
	width: '100%',
}))

export const ButtonWrapper = createComponent(() => ({
	width: '100%',
	display: 'flex',
}))

export const ButtonContainer = createComponent(() => ({
	width: '50%',
	margin: '0 auto',
}))

export const ChipsWrapper = createComponent(() => ({
	marginBottom: '4px',
}))

export const TextLabel = createComponent(() => ({
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	color: 'black',
	marginTop: 8,
}))

export const SelectedCategory = createComponent(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	color: theme.colors.white,
	padding: theme.spaces[8],
	background: theme.colors.bmsBlue,
	fontSize: theme.font.size14,
	fontWeight: theme.font.weight500,
	borderRadius: '6px',
	margin: `${theme.spaces[10]}  0px`,
}))
