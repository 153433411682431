/* eslint-disable no-undef */
const M5 = {}

M5.Storage = {
	/**
	 * initialize - Method to initialize req and res when this is to be used on server side
	 *
	 * @param  {objec} req Server side request used for getting
	 * @param  {object} res Server side response used for setting
	 */
	initialize(ctx) {
		this.ctx = ctx
	},

	/**
	 * Default values for Storage methods
	 */
	_defaults: {
		name: '',
		value: '',
		storage: 'C',
		sess: false,
		path: '/',
		defVal: '',
		expires: new Date(2030, 11, 31),
		secure: false,
		httpOnly: false,
		stringify: true,
	},

	_parsedResult(str) {
		try {
			// Return JSON object if string is json or array
			const obj = JSON.parse(str)

			return obj
		} catch (e) {
			// Return string if it is neither json or array
			return str
		}
	},

	/**
	 * get - Gets the cookie value based on name
	 *
	 * @param  {object} params Options to be passed to the function
	 * 					@property name Name of the key
	 * @return string		 Returns the cookie value based on the key passed
	 */
	get(params) {
		try {
			/**
			 *  Logic to handle both server side and client side cookie getting value
			 * For Server side - It will be only cookies and will be using the cookieParser object
			 * which will give the cookie object using req.cookies
			 * For Client side - It will have LocalStorage, Cookies and SessionStorage
			 */

			let result

			if (this.ctx) {
				// Server Side Logic
				result = this.ctx.cookies.get(params.name)
				result = result || ''
			} else {
				// Client Side Logic
				const paramName = params.name
				const reqCookie = `${params.name}=`
				const cookies =
					typeof document !== 'undefined' ? document.cookie : ''

				/* Look Up in LS, else in Cookies and then sessionStorage */
				if (
					typeof localStorage !== 'undefined' &&
					localStorage.getItem(paramName) !== null
				) {
					// Check for name in LocalStorage
					result = localStorage.getItem(paramName)
				} else if (
					cookies.length > 0 &&
					cookies.indexOf(reqCookie) !== -1
				) {
					// Check for name in Cookies
					let i = cookies.indexOf(reqCookie)
					i += reqCookie.length
					let j = cookies.indexOf(';', i)
					j = j === -1 ? cookies.length : j
					result = cookies.substring(i, j)
				} else if (
					typeof sessionStorage !== 'undefined' &&
					sessionStorage.getItem(paramName) !== null
				) {
					// Check for name in SessionStorage
					result = sessionStorage.getItem(paramName)
				} else {
					// Default value
					result = JSON.stringify('')
				}
			}

			return this._parsedResult(decodeURIComponent(result))
		} catch (e) {
			logger.log(e)
		}
	},

	/**
	 * getAllCookies - This method will return raw cookies in key value pair (value will always be
	 * string) It wont be parsed in JSON object or array similar to the get method.
	 *
	 * @return {object}  All cookies in key value pair, value being in raw string format
	 */
	getAllCookies() {
		if (this.ctx) {
			// Return ALl server side cookies
			return this.ctx.cookies
		}
		// Return all client side cookies

		const cookie = typeof document !== 'undefined' ? document.cookie : ''
		const arrCookies = cookie.split(';')
		const cookies = {}

		for (const pairs of arrCookies) {
			const pair = pairs ? pairs.split('=') : []
			if (pair.length === 2) {
				cookies[pair[0]] = decodeURIComponent(pair[1])
			}
		}

		return cookies
	},

	/**
			@function set
				@param {object} params
					@property {string} params.name
					@property {string/Array/Object} params.value
					@property {string} params.storage (C,S,L)
										C - Cookie Storage (default)
										S - Session Storage
										L - Local Storage
				@returns {string} CookieValue
				@description Sets the cookie value based on key
								String is set as it is
								Array is converted to string and saved as comma separated Eg.(1,2,3)
								Object is stringified
	
		*/
	set({
		name = M5.Storage._defaults.name,
		sess = M5.Storage._defaults.sess,
		expires = M5.Storage._defaults.expires,
		path = M5.Storage._defaults.path,
		secure = M5.Storage._defaults.secure,
		value = M5.Storage._defaults.value,
		storage = M5.Storage._defaults.storage,
		httpOnly = M5.Storage._defaults.httpOnly,
		stringify = M5.Storage._defaults.stringify,
	}) {
		try {
			/**
			 * Logic to handle both server side and client side cookie setting value
			 * For Server side - It will be only cookies and will be using the cookieParser object
			 * which will give the cookie object using res.cookie
			 * For Client side - It will have LocalStorage, Cookies and SessionStorage
			 */

			/* Converting storage value to UpperCase for Consistency */
			storage = storage.toUpperCase() // eslint-disable-line no-param-reassign
			value = // eslint-disable-line no-param-reassign
				stringify && typeof value !== 'string'
					? encodeURIComponent(JSON.stringify(value))
					: encodeURIComponent(value)

			/* Store value in Cookie / sessionStorage / localStorage */
			if (
				(storage === '' || storage === 'L') &&
				typeof localStorage !== 'undefined'
			) {
				localStorage.setItem(name, value)
			} else if (
				storage === 'S' &&
				typeof sessionStorage !== 'undefined'
			) {
				sessionStorage.setItem(name, value)
			} else {
				this._setValueInCookie({
					name,
					value,
					sess,
					expires,
					path,
					secure,
					httpOnly,
				})
			}
		} catch (e) {
			if (this.isQuotaExceeded(e)) {
				this._setValueInCookie({
					name,
					value,
					sess,
					expires,
					path,
					secure,
					httpOnly,
				})
			}
		}
	},

	del({
		name = M5.Storage._defaults.name,
		path = M5.Storage._defaults.path,
		domain = null,
	}) {
		try {
			if (this.ctx) {
				// Server side logic to delete the cookie
				this.ctx.cookies.set(
					name,
					undefined,
					domain ? { domain } : null
				)
			} else {
				// Client Side logic to delete the cookie

				/* Delete from LS if not found, Cookies and then SS */
				// eslint-disable-next-line no-lonely-if
				if (
					typeof localStorage !== 'undefined' &&
					localStorage.getItem(name)
				) {
					localStorage.removeItem(name)
				}

				if (document.cookie.indexOf(`${escape(name)}=`) !== -1) {
					document.cookie = `${escape(
						name
					)}=; expires=Thu, 01 Jan 2016 00:00:01 GMT; path=${path}`
				}

				if (
					typeof sessionStorage !== 'undefined' &&
					sessionStorage.getItem(name)
				) {
					sessionStorage.removeItem(name)
				}
			}
		} catch (e) {
			logger.log(e)
		}
	},

	deleteAllCookies({ exceptions = [] }) {
		try {
			// Client Side logic to delete all the cookies except exceptions
			const cookie =
				typeof document !== 'undefined' ? document.cookie : ''
			const arrCookies = cookie.split(';')
			const { path } = M5.Storage._defaults

			for (const pairs of arrCookies) {
				const pair = pairs ? pairs.split('=') : []
				const escapedName = decodeURIComponent(pair[0]).trim()

				if (exceptions.indexOf(escapedName) === -1) {
					document.cookie = `${escapedName}=; expires=Thu, 01 Jan 2016 00:00:01 GMT; path=${path}`
				}
			}
		} catch (e) {
			logger.log(e)
		}
	},

	_setValueInCookie(flagObject) {
		const { name, value, sess, expires, path, secure, httpOnly } =
			flagObject
		console.log(flagObject)
		try {
			if (this.ctx) {
				/**	Server Side Logic
				 * Refer res.cookie section at http://expressjs.com/en/api.html
				 * Parameters used are - expires, path, secure
				 */

				// Set path and secure parameters of cookie
				const cookieParams = {
					path,
					secure,
					// If it is not a session cookie, set the expiry of the cookie or set 0 (Default Value)
					expires: !sess && expires ? expires : 0,
					httpOnly,
				}

				// Set the cookie value in response
				this.ctx.cookies.set(escape(name), value, cookieParams)
			} else if (typeof document !== 'undefined') {
				// Client side logic
				document.cookie = `${escape(name)}=${value}${
					!sess ? `; expires=${expires}` : ''
				};path=${path}${secure ? ';secure;' : ''}`
			}
		} catch (e) {
			logger.log(e)
		}
	},
	/**
			@function isset
				@param {object} params
					@property {string} params.name
				@returns {boolean} true/false
				@description Checks if a cookie is already set against a name
	
		*/
	isset({ name = M5.Storage._defaults.name }) {
		try {
			const blnExists = !!M5.Storage.get({ name })

			return blnExists
		} catch (e) {
			logger.log(e)
		}
	},

	isQuotaExceeded(e) {
		let quotaExceeded = false
		if (e) {
			if (e.code) {
				switch (e.code) {
					case 22: {
						quotaExceeded = true
						break
					}
					case 1014: {
						// Firefox
						if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
							quotaExceeded = true
						}
						break
					}
					default:
						quotaExceeded = true
				}
			} else if (e.number === -2147024882) {
				// Internet Explorer 8
				quotaExceeded = true
			}
		}

		return quotaExceeded
	},
}

export { M5 }
