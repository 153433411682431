import { takeLatest, all, put, call } from 'redux-saga/effects'
import OrganiserApprovalDuc from 'modules/OrganiserApproval/duc'
import { AppDuc } from 'modules/App/duc'
import { privateUCMSRequest } from 'utils/request'
import { ERROR, SUCCESS, IN_PROGRESS } from '../../../src/constants'
import { PENDING } from './constants'

export function* getOrganiserData({ params }) {
	try {
		const {
			status = PENDING,
			offset = 0,
			limit = 10,
			searchKey = '',
		} = params
		const {
			data: { data },
		} = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/diy/v1/vendor-onboard/organiser-list/${status}?limit=${limit}&offset=${offset}&search=${searchKey}`
			)
		)
		yield put(OrganiserApprovalDuc.creators.getOrganiserDataSuccess(data))
	} catch (err) {
		const {
			response: {
				data: { errors: { message = 'Error occured' } = {} } = {},
			} = {},
		} = err || {}
		yield put(AppDuc.creators.showToast('error', true, message))
	}
}

export function* getBdName({ state }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			privateUCMSRequest.get(`/proxy/diy/v1/bd/state-wise/${state}`)
		)
		yield put(OrganiserApprovalDuc.creators.updateBdDetails(data))
	} catch (err) {
		yield put(OrganiserApprovalDuc.creators.updateBdDetails({}))
		yield put(AppDuc.creators.showToast('error', true, 'Error occured'))
	}
}

export function* approveOrganiser({ payload }) {
	try {
		const { bdCode, organiserId, toggleModal, urlParams: params } = payload
		const body = { bdCode }
		const {
			data: {
				data: { message = '' },
			},
		} = yield call(() =>
			privateUCMSRequest.put(
				`/proxy/diy/v1/vendor-onboard/organiser/${organiserId}/status/approve`,
				body
			)
		)
		yield put(
			OrganiserApprovalDuc.creators.updateConfirmationStatus(SUCCESS)
		)
		if (message) {
			toggleModal()
			yield put(AppDuc.creators.showToast('success', true, message))
			yield call(getOrganiserData, { params })
		}
	} catch (err) {
		yield put(OrganiserApprovalDuc.creators.updateConfirmationStatus(ERROR))
		const {
			response: {
				data: {
					message: { longDesc = 'Error occured' },
				},
			},
		} = err
		yield put(AppDuc.creators.showToast('error', true, longDesc))
	}
}

export function* viewAllDocuments({ id }) {
	try {
		const headers = {
			'x-mobile-no': id,
		}

		privateUCMSRequest.defaults.headers = Object.assign(
			privateUCMSRequest.defaults.headers,
			headers
		)
		const {
			data: { data },
		} = yield call(() =>
			privateUCMSRequest.get('/proxy/diy/v1/signup/all-files')
		)
		window.open(data.url, '__blank')
	} catch (err) {
		yield put(AppDuc.creators.showToast('error', true, 'Error occured'))
	}
}

export function* getRejectionReason({ id }) {
	try {
		const {
			data: { data },
		} = yield call(() =>
			privateUCMSRequest.get(
				`/proxy/diy/v1/vendor-onboard/rejection-reasons/${id}`
			)
		)

		yield put(OrganiserApprovalDuc.creators.getRejectionReasonSuccess(data))
	} catch (err) {
		yield put(AppDuc.creators.showToast('error', true, 'Error occured'))
	}
}

export function* submitRejection({ params }) {
	try {
		const { id = '', payload, toggleModal, urlParams } = params || {}
		yield put(
			OrganiserApprovalDuc.creators.submitRejectionStatus(IN_PROGRESS)
		)

		const {
			data: {
				data: { message = '' },
			},
		} = yield call(() =>
			privateUCMSRequest.put(
				`/proxy/diy/v1/vendor-onboard/organiser/${id}/status/reject`,
				payload
			)
		)

		yield put(OrganiserApprovalDuc.creators.submitRejectionStatus(SUCCESS))
		if (message) {
			toggleModal()
			yield put(AppDuc.creators.showToast('success', true, message))
			yield call(getOrganiserData, { params: urlParams })
		}
	} catch (err) {
		yield put(OrganiserApprovalDuc.creators.submitRejectionStatus(ERROR))
		const {
			response: {
				data: {
					message: { shortDesc },
				},
			},
		} = err
		yield put(AppDuc.creators.showToast('error', true, shortDesc))
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(
			OrganiserApprovalDuc.types.GET_ORGANISER_DATA,
			getOrganiserData
		),
		takeLatest(OrganiserApprovalDuc.types.GET_BD_NAME, getBdName),
		takeLatest(
			OrganiserApprovalDuc.types.APPROVE_ORGANISER,
			approveOrganiser
		),
		takeLatest(
			OrganiserApprovalDuc.types.VIEW_ALL_DOCUMENTS,
			viewAllDocuments
		),
		takeLatest(
			OrganiserApprovalDuc.types.GET_REJECTION_REASON,
			getRejectionReason
		),
		takeLatest(
			OrganiserApprovalDuc.types.SUBMIT_REJECTION,
			submitRejection
		),
	])
}
