import Duck from 'extensible-duck'

export default new Duck({
	namespace: 'diy-dashboard',
	store: 'dashboard',
	types: [
		'GET_DASHBOARD',
		'GET_DASHBOARD_IN_PROGRESS',
		'GET_DASHBOARD_SUCCESS',
		'GET_DASHBOARD_ERROR',
		'GET_ACCESS',
		'SET_ACCESS',
	],
	initialState: {
		error: {},
		uiState: '',
		dashboardDetails: {},
	},
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.GET_DASHBOARD_IN_PROGRESS:
				return {
					...state,
					uiState: 'IN_PROGRESS',
				}
			case duck.types.GET_ACCESS:
				return {
					...state,
				}
			case duck.types.SET_ACCESS:
				return {
					...state,
				}
			case duck.types.GET_DASHBOARD_SUCCESS:
				return {
					...state,
					uiState: 'SUCCESS',
					dashboardDetails: action?.payload?.data,
				}
			case duck.types.GET_DASHBOARD_ERROR:
				return {
					...state,
					uiState: 'ERROR',
					error: action?.payload?.error,
				}
			default:
				return state
		}
	},
	selectors: {
		root: state => state,
		dashboardDetails: state => state?.dashboard?.dashboardDetails,
		userGroups: state => state?.dashboard?.userGroups,
	},
	creators: duck => ({
		getDashboardDetailsInProgress: () => ({
			type: duck.types.GET_DASHBOARD_IN_PROGRESS,
		}),
		getDashboardDetails: params => ({
			type: duck.types.GET_DASHBOARD,
			payload: {
				params,
			},
		}),
		getAccess: username => ({
			type: duck.types.GET_ACCESS,
			payload: {
				username,
			},
		}),
		setAccess: payload => ({
			type: duck.types.SET_ACCESS,
			payload,
		}),
		getDashboardDetailsSuccess: data => ({
			type: duck.types.GET_DASHBOARD_SUCCESS,
			payload: {
				data,
			},
		}),
	}),
})
