import React, { useEffect, useState } from 'react'
import { bool, string, func, object } from 'prop-types'
import { isValid } from 'date-fns'
import { withRouter } from 'react-router-dom'

// components
import CheckBox from 'bms-le-components/atoms/CheckBox'
import DatePickerMaterial from 'bms-le-components/components/DatePickerMaterial'
import TimePickerMaterial from 'bms-le-components/components/TimePickerMaterial'
import StatusBar from 'common/components/StatusBar'
import TextField from 'bms-le-components/atoms/TextField'
import RecurringSelector from 'modules/EventManagement/components/Selectors/RecurringSelector'
import Icon from 'bms-le-components/common/ui/Icon'
import Button from 'bms-le-components/atoms/Button'
import theme from 'bms-le-components/utils/base-ui-theme'

// icons
import Timer from 'bms-le-components/common/icons/timer.svg'

// styled components
import { MainWrapper, Wrapper, CustomBtn, TimeSectionWrapper } from './__style'

// helpers
import { calculateDatesBasedOnInterval } from 'utils/helpers'
import { formatDateTime, showsOnSameDay } from 'modules/EventManagement/helpers'
import { EVENT_STATUS } from 'modules/EventManagement/helpers/constants'

const TimeEditor = (props) => {
	const {
		isDesktop,
		status,
		showStatus,
		showTime,
		handleToggle,
		handleDelete,
		handleShowTimeChange,
		handleAddRemoveShowTime,
		sessionInfo,
		handleShowDateChange,
		handleAddRecurringInfo,
		handleDeleteRecurringInfo,
		recurringInfo,
		isOnlineVenue,
		disabled,
		disabledDates,
		location,
		eventCurrentStatus,
		sessionId,
		isNewSession,
		isFirstSession,
	} = props
	const dateTimeValue = showTime ? new Date(showTime) : null
	const isDatePickerDisabled =
		(isValid(dateTimeValue) &&
			dateTimeValue.toISOString().split('T')[0] <
				new Date().toISOString().split('T')[0]) ||
		disabled
	const errorMessage = isDatePickerDisabled ? (
		<p style={{ fontSize: 12, margin: 0 }}>Session Expired</p>
	) : (
		''
	)

	const addSession =
		new URLSearchParams(location?.search).get('addSession') || false

	const isPastSessionsDisabled =
		new URLSearchParams(location?.search).get('disablePastSessions') ||
		false

	const disableSession =
		addSession ||
		(isPastSessionsDisabled &&
			isValid(dateTimeValue) &&
			dateTimeValue < new Date())

	const [showRecurring, toggleRecurringState] = useState(!!recurringInfo)
	const [showModal, toggleModal] = useState(false)
	const [endDate, setEndDate] = useState(null)
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(
		!dateTimeValue && sessionInfo[0].showTime !== showTime
	)
	const [timePickerOpen, setTimePickerOpen] = useState({})
	const [isSessionDateError, updateSessionDateError] = useState(false)
	const [newSession, setNewSession] = useState(false)

	useEffect(() => {
		calculateEndDate()
	}, [])

	const statusLabel = `${status ? '' : 'IN'}ACTIVE`

	const showsOnTheSameDay = showsOnSameDay(sessionInfo, dateTimeValue)

	const addRecurringInfo = (data) => {
		handleAddRecurringInfo(data)
		toggleModal(!showModal)
		calculateEndDate()
	}

	const handleToggleRecurring = () => {
		if (isValid(new Date(showTime))) {
			toggleRecurringState(!showRecurring)
		}
		// if recurring checkbox is unchecked, then remove recurring info
		if (showRecurring) {
			handleDeleteRecurringInfo()
			setEndDate(null)
		}
	}

	const handleModalToggle = () => {
		toggleModal(!showModal)
	}

	const handleNoOfTimes = (value) => {
		handleAddRecurringInfo({
			...recurringInfo,
			noOfTimes: value,
		})
	}

	const onSessionDateChange = (date) => {
		if (date) {
			updateSessionDateError(isNaN(date.getTime()))
		}
	}

	const calculateEndDate = () => {
		const dates = calculateDatesBasedOnInterval(
			recurringInfo?.code,
			showTime,
			parseInt(recurringInfo?.noOfTimes || 0)
		)
		setEndDate(dates[dates.length - 1])
	}

	const onDatePickerCancel = () => {
		setIsDatePickerOpen(false)
		if (!dateTimeValue) {
			handleDelete()
		}
	}

	return (
		<>
			<RecurringSelector
				isDesktop={isDesktop}
				showModal={showModal}
				handleModalToggle={handleModalToggle}
				handleAddRecurringInfo={addRecurringInfo}
			/>
			<MainWrapper isDesktop={isDesktop} disabled={isDatePickerDisabled}>
				{showStatus &&
					(!addSession ||
						(addSession &&
							showsOnTheSameDay.some(
								({ sessionId }) =>
									sessionId === '0' ||
									Number.isInteger(sessionId)
							)) ||
						eventCurrentStatus === EVENT_STATUS.PAST) && (
						<StatusBar
							isOn={status}
							statusLabel={statusLabel}
							handleToggle={handleToggle}
							handleDelete={handleDelete}
							showDeleteIcon={
								!!dateTimeValue &&
								!isOnlineVenue &&
								(!addSession ||
									showsOnTheSameDay.every(
										({ sessionId }) =>
											sessionId === '0' ||
											Number.isInteger(sessionId)
									))
							}
							disabled={isDatePickerDisabled}
							isFirstSession={isFirstSession}
						/>
					)}
				<Wrapper isDesktop={isDesktop} disabled={!status}>
					<DatePickerMaterial
						name="selectedDate"
						label="dd/mm/yyyy"
						renderValueByProp
						renderKeyboardDatePicker
						initialValue={dateTimeValue || null}
						onDateChange={(val) => {
							onSessionDateChange(val)
							setEndDate(null)
							handleShowDateChange(val)
							isNewSession &&
								setTimePickerOpen({
									...timePickerOpen,
									[sessionId]: true,
								})
						}}
						shouldParseDate={false}
						minDate={
							new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
						}
						error={
							isSessionDateError ||
							(dateTimeValue && isNaN(dateTimeValue.getTime()))
						}
						minDateMessage={errorMessage}
						format="dd/MM/yyyy"
						open={isDatePickerOpen}
						onOpen={() => setIsDatePickerOpen(true)}
						onClose={() => setIsDatePickerOpen(false)}
						disabledDates={disabledDates}
						onDismiss={onDatePickerCancel}
						disabled={
							disableSession &&
							showsOnTheSameDay.some(
								({ sessionId }) =>
									sessionId !== '0' &&
									!Number.isInteger(sessionId)
							)
						}
					/>
					{showsOnTheSameDay &&
						showsOnTheSameDay.map(
							({ sessionId, showTime, errorMessage }, index) => (
								<TimeSectionWrapper
									isDesktop={isDesktop}
									key={sessionId}
								>
									<TimePickerMaterial
										name="startTime"
										label="Start Time"
										initialValue={showTime || null}
										renderValueByProp
										renderKeyboardTimePicker
										onTimeChange={(val) => {
											toggleRecurringState(false)
											setEndDate(null)
											handleShowTimeChange(val, sessionId)
										}}
										keyboardIcon={
											<div
												style={{
													width: '24px',
													overflow: 'hidden',
												}}
											>
												<Icon
													glyph={Timer}
													width="24"
													height="24"
												/>
											</div>
										}
										mask="__:__ _M"
										open={
											timePickerOpen[sessionId] || false
										}
										onOpen={() => {
											setTimePickerOpen({
												...timePickerOpen,
												[sessionId]: true,
											})
										}}
										onClose={() => {
											setTimePickerOpen({
												...timePickerOpen,
												[sessionId]: false,
											})
										}}
										error={
											!!errorMessage &&
											!timePickerOpen[sessionId]
										}
										errorMessage={errorMessage}
										disabled={
											addSession &&
											sessionId !== '0' &&
											!Number.isInteger(sessionId)
										}
									/>

									{!isOnlineVenue &&
										(index === 0 ? (
											<Button
												onClick={() => {
													setNewSession(true)
													setTimePickerOpen({
														...timePickerOpen,
														newSession: true,
													})
												}}
												style={{
													width: '86px',
													fontSize: theme.font.size12,
												}}
												type="SECONDARY"
												size="MEDIUM"
											>
												+ Add time
											</Button>
										) : (
											(!addSession ||
												sessionId === '0' ||
												Number.isInteger(
													sessionId
												)) && (
												<CustomBtn
													onClick={() =>
														handleAddRemoveShowTime(
															sessionId,
															index
														)
													}
												>
													{index === 0 ? '+' : '-'}
												</CustomBtn>
											)
										))}
								</TimeSectionWrapper>
							)
						)}
					{newSession && (
						<TimeSectionWrapper
							isDesktop={isDesktop}
							key="newSession"
						>
							<TimePickerMaterial
								name="startTime"
								label="Start Time"
								initialValue={new Date(
									showsOnTheSameDay[0]?.showTime
								)?.toUTCString()}
								renderValueByProp
								renderKeyboardTimePicker
								onTimeChange={() => {
									toggleRecurringState(false)
									setEndDate(null)
								}}
								open={timePickerOpen['newSession'] || false}
								onAccept={(val) => {
									handleAddRemoveShowTime(
										'',
										0,
										val.toISOString()
									)
								}}
								onClose={() => {
									setNewSession(false)
									setTimePickerOpen({
										...timePickerOpen,
										newSession: false,
									})
								}}
							/>
						</TimeSectionWrapper>
					)}
				</Wrapper>
				{!isOnlineVenue && isValid(new Date(showTime)) && (
					<CheckBox
						name="isRecurring"
						title="Recurring event"
						showTitle
						noMargin
						isEnabled={showTime}
						isChecked={showRecurring}
						onToggle={handleToggleRecurring}
						style={{ marginTop: '12px' }}
					/>
				)}
				{showRecurring && !isOnlineVenue && (
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						<div style={{ margin: '8px' }}>
							<TextField
								name="frequency"
								label="Frequency"
								type="text"
								fullWidth
								handleChange={() => {}}
								value={recurringInfo?.value || ''}
								onFocus={handleModalToggle}
								renderValueViaProp
								hideClearIcon
							/>
						</div>

						<div style={{ margin: '8px' }}>
							<TextField
								name="noOfTimes"
								label="Number of Times"
								type="number"
								fullWidth
								onChange={(e) =>
									handleNoOfTimes(e?.target?.value)
								}
								onBlur={calculateEndDate}
								value={recurringInfo?.noOfTimes || ''}
								hideClearIcon
								readOnly={!recurringInfo?.value}
								isPhoneNumber
							/>
						</div>
						<div
							style={{
								margin: '8px',
								marginTop: '44px',
								color: !endDate && '#CCCCCC',
							}}
						>
							<div>End Date</div>
							<div style={{ marginTop: '17px' }}>
								{endDate
									? formatDateTime(
											endDate,
											'eee, dd MMM yyyy'
									  )
									: '---'}
							</div>
						</div>
					</div>
				)}
			</MainWrapper>
		</>
	)
}

TimeEditor.propTypes = {
	showTime: string.isRequired,
	isDesktop: bool.isRequired,
	status: bool.isRequired,
	showStatus: bool.isRequired,
	handleShowTimeChange: func.isRequired,
	handleToggle: func.isRequired,
	handleDelete: func.isRequired,
	handleToggleRecurring: func.isRequired,
	handleAddRemoveShowTime: func.isRequired,
	disabled: bool,
	disabledDates: object,
	isNewSession: bool,
}
TimeEditor.defaultProps = {
	showTime: '2020-09-01',
	isDesktop: true,
	status: false,
	showStatus: true,
	isOnlineVenue: false,
	handleShowTimeChange: () => {},
	handleToggle: () => {},
	handleDelete: () => {},
	handleToggleRecurring: () => {},
	handleAddRemoveShowTime: () => {},
	disabled: false,
	disabledDates: {},
	isNewSession: false,
}

export default withRouter(TimeEditor)
