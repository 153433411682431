const __wrapper = ({ hasData }) => ({
	backgroundColor: '#fff',
	display: 'flex',
	overflow: 'scroll',
	extend: [
		{
			condition: hasData,
			style: { paddingLeft: '10px', margin: '10px 0' },
		},
	],
})

const __cardWrapper = () => ({
	'> div:nth-child(1)': { maxWidth: 220, overflow: 'hidden' },
})

export { __wrapper, __cardWrapper }
